import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { CustomInspirationsGalleryImage } from 'src/services';
import { ParseJoiValidateResponse } from 'src/utils';
import { getQueryStringParams, queryKey } from '../common/utils';

export enum ExpandableSection {
  AltText = 'alt_text',
  Caption = 'caption',
  ImageUrl = 'image_url',
}

interface UseExpandableOptionsProps {
  errors: ParseJoiValidateResponse<
    Pick<CustomInspirationsGalleryImage, 'altText' | 'caption' | 'imageUrl'>
  >;
  onChange: (partialImage: Partial<Omit<CustomInspirationsGalleryImage, 'id'>>) => void;
}

const allExpandedOptions = Object.values(ExpandableSection);

export function useExpandableOptions({ errors, onChange }: UseExpandableOptionsProps) {
  const { push } = useHistory();
  const { pathname, search: queryString } = useLocation();
  const queryObject = useMemo(() => new URLSearchParams(queryString), [queryString]);
  const initialExpandedSections = useMemo(() => {
    const queryValuesOnLoad = queryObject.getAll(queryKey) as ExpandableSection[];

    if (queryValuesOnLoad.every(param => allExpandedOptions.includes(param))) {
      return queryValuesOnLoad;
    }
    return null;
  }, [queryObject]);

  const [expandedSections, setExpandedSections] = useState<ExpandableSection[] | null>(
    initialExpandedSections
  );

  const updateExpandedOptionsQueryString = useCallback(
    (sections: ExpandableSection[] | null) => {
      let targetQueryString: string;

      if (sections === null) {
        queryObject.delete(queryKey);
        targetQueryString = queryObject.toString();
      } else {
        targetQueryString = getQueryStringParams(sections);
      }

      if (targetQueryString === queryString) return;

      const path = targetQueryString ? `${pathname}?${targetQueryString}` : pathname;
      push(path);
    },
    [pathname, queryString, push, queryObject]
  );

  const onExpandSelectionChange = useCallback(
    (sections: string[]): void => {
      setExpandedSections(sections as ExpandableSection[]);
    },
    [setExpandedSections]
  );

  const onTextChange = useCallback(
    (textType: keyof Pick<CustomInspirationsGalleryImage, 'altText' | 'caption' | 'imageUrl'>) =>
      (updatedText: string) => {
        onChange({ [textType]: updatedText || undefined });
      },
    [onChange]
  );

  useEffect(() => {
    updateExpandedOptionsQueryString(expandedSections);
  }, [expandedSections, updateExpandedOptionsQueryString]);

  const isSectionExpanded = useCallback(
    (section: ExpandableSection) => expandedSections?.includes(section) || false,
    [expandedSections]
  );

  return {
    expandedSections,
    setExpandedSections,
    onExpandSelectionChange,
    isSectionExpanded,
    onTextChange,
    errors,
  };
}
