import React from 'react';
import { Button } from 'src/components-dummy/Button';
import { ConfirmationDialog } from 'src/components-dummy/ConfirmationDialog';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';

interface DeleteCampaignConfirmationDialogProps {
  onCloseDeleteDialog: () => void;
  onDeleteConfirm: () => void;
  campaignName: string;
}

export function DeleteCampaignConfirmationDialog({
  onCloseDeleteDialog,
  onDeleteConfirm,
  campaignName,
}: DeleteCampaignConfirmationDialogProps): JSX.Element {
  return (
    <ConfirmationDialog onCancel={onCloseDeleteDialog}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Delete Campaign</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        {`Are you sure you want to delete campaign ${campaignName}?`}
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonsContainer>
          <Button variant='destructive' onClick={onDeleteConfirm}>
            Delete
          </Button>
          <Button variant='tertiary' onClick={onCloseDeleteDialog}>
            Cancel
          </Button>
        </ButtonsContainer>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
}
