import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { emailMarketingCampaignsService } from 'src/services/src/service/email-marketing-campaigns';
import { GetCampaignArguments } from 'src/services/src/service/email-marketing-campaigns/types';

export const campaignModalPageActions = {
  navigateTo: createAction<{ navigateTo: string }>('CampaignModalPage/NavigateTo'),
  notifyIsDirty: createAction<{ isDirty: boolean }>('CampaignModalPage/NotifyIsDirty'),
  getCampaign: createAsyncThunk(
    'CampaignModalPage/GetCampaign',
    async (params: GetCampaignArguments, { rejectWithValue }) => {
      try {
        const response = await emailMarketingCampaignsService.getCampaign(params);
        return response;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  createCampaign: createAsyncThunk(
    'CampaignModalPage/CreateCampaign',
    async (
      {
        shopId,
        campaignName,
        strategy,
        collectionId,
        widgetMaker,
      }: Parameters<typeof emailMarketingCampaignsService.createCampaign>[0],
      { rejectWithValue }
    ) => {
      try {
        const createdCampaign = await emailMarketingCampaignsService.createCampaign({
          shopId,
          campaignName,
          strategy,
          collectionId,
          widgetMaker,
        });
        return createdCampaign;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  updateCampaign: createAsyncThunk(
    'CampaignModalPage/EditCampaign',
    async (
      {
        shopId,
        campaignId,
        payload,
      }: Parameters<typeof emailMarketingCampaignsService.updateCampaign>[0],
      { rejectWithValue }
    ) => {
      try {
        return await emailMarketingCampaignsService.updateCampaign({ shopId, campaignId, payload });
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  resetCurrentCampaign: createAction('CampaignModalPage/resetCurrentCampaign'),
};
