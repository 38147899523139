import { useCallback, useEffect, useState } from 'react';
import { Dispatch } from 'src/components-bl/types';
import { editGalleryPageActions } from './Actions';
import { isEqual } from 'lodash';
import { ShoppableBannerDraft } from './ShoppableBanner/EditShoppableBannerPage/types';
import { CustomInspirationGalleryDraft } from './CustomInspirations/EditCustomInspirationsGalleryPage/types';
import { RoutedComponentProps } from 'src/app-routes';
import { generatePath } from 'react-router';
import { useConfirmationDialog } from './CustomInspirations/components/DirtyFormConfirmationDialog/useConfirmationDialog';

interface UseEditGalleryPageProps extends RoutedComponentProps {
  shopId: number;
  dispatch: Dispatch;
  galleryId: string;
  mappedInitialGallery: CustomInspirationGalleryDraft | ShoppableBannerDraft | undefined;
  onSaveGallery: (gallery: CustomInspirationGalleryDraft | ShoppableBannerDraft) => Promise<any>;
  draftGallery: CustomInspirationGalleryDraft | ShoppableBannerDraft | undefined;
  setDraftGallery: React.Dispatch<
    React.SetStateAction<CustomInspirationGalleryDraft | ShoppableBannerDraft | undefined>
  >;
}

export function useEditGalleryPage({
  shopId,
  dispatch,
  galleryId,
  mappedInitialGallery,
  onSaveGallery,
  permittedRouteMap,
  draftGallery,
  setDraftGallery,
}: UseEditGalleryPageProps) {
  const [isDirty, setIsDirty] = useState(false);
  const { showDirtyModal, closeModal, triggerTheModalIfDirtyOrCallback, currentActionConfig } =
    useConfirmationDialog({ isDirty });

  useEffect(() => {
    const isDraftEqualToSource = isEqual(mappedInitialGallery, draftGallery);
    setIsDirty(!isDraftEqualToSource);
  }, [draftGallery, mappedInitialGallery]);

  useEffect(() => {
    dispatch(editGalleryPageActions.notifyIsDirty({ isDirty }));
  }, [isDirty, dispatch]);

  const navigateToList = useCallback(() => {
    if (!permittedRouteMap.galleriesList) return;

    dispatch(
      editGalleryPageActions.navigateTo({
        navigateTo: generatePath(permittedRouteMap.galleriesList.path, { shopId }),
      })
    );
  }, [shopId, permittedRouteMap.galleriesList, dispatch]);

  const onSaveDraftGallery = useCallback(async () => {
    if (draftGallery) {
      return onSaveGallery(draftGallery);
    }
  }, [onSaveGallery, draftGallery]);

  const startTagsDetection = useCallback(async () => {
    try {
      await (
        dispatch(editGalleryPageActions.startTagsDetection({ shopId, galleryId })) as any
      ).unwrap();
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, shopId, galleryId]);

  const saveDraftAndCallback = useCallback(async () => {
    const updatedGallery = await onSaveDraftGallery();
    closeModal();

    currentActionConfig?.cb(updatedGallery);
  }, [onSaveDraftGallery]);

  const resetDraftAndCallback = useCallback(async () => {
    closeModal();

    currentActionConfig?.cb();
    setDraftGallery(mappedInitialGallery);
  }, [mappedInitialGallery, closeModal]);

  const applyAllSuggestedTags = useCallback(
    (updatedGallery?: ShoppableBannerDraft) => {
      const updatedImages = (updatedGallery || mappedInitialGallery).images.map(image => ({
        ...image,
        tags: [
          ...image.tags,
          ...image.aiSuggestedTags.map(tag => ({ ...tag, isAIDetected: true })),
        ],
        aiSuggestedTags: [],
      }));

      onSaveGallery({
        ...mappedInitialGallery,
        images: updatedImages,
      });
    },
    [onSaveGallery, mappedInitialGallery]
  );

  const removeAllSuggestedTags = useCallback(
    (updatedGallery?: ShoppableBannerDraft) => {
      const updatedImages = (updatedGallery || mappedInitialGallery).images.map(image => ({
        ...image,
        aiSuggestedTags: [],
      }));

      onSaveGallery({ ...mappedInitialGallery, images: updatedImages });
    },
    [onSaveGallery, mappedInitialGallery]
  );

  return {
    resetDraftAndCallback,
    navigateToList,
    onSaveDraftGallery,
    startTagsDetection,
    saveDraftAndCallback,
    applyAllSuggestedTags,
    removeAllSuggestedTags,
    triggerTheModalIfDirtyOrCallback,
    showDirtyModal,
    currentActionConfig,
    closeModal,
    isDirty,
    draftGallery,
    setDraftGallery,
  };
}
