import { orderBy } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { generatePath } from 'react-router';
import { Dispatch } from 'src/components-bl/types';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { Skeleton } from 'src/components-dummy/Skeleton';
import { LexiconTagType } from 'src/services/src/service/lexicon/types';
import { LexiconMetadataView } from '../../Lexicon.types';
import {
  LanguageCard,
  cardColors,
  LanguageListLayoutStyled,
} from 'src/components-dummy/LanguageCard';
import { lexiconListActions } from './Actions/LexiconListActions';

export interface LexiconListProps {
  lexiconMetadataList: LexiconMetadataView[] | undefined;
  categoriesRoute: string | undefined;
  dispatch: Dispatch;
  shopId: number | undefined;
}

const getCardColor = (index: number) => {
  const color = cardColors[index % cardColors.length];
  return color;
};

export const LexiconList = ({
  shopId,
  lexiconMetadataList,
  categoriesRoute,
  dispatch,
}: LexiconListProps): JSX.Element => {
  const onCardClick = useCallback(
    ({ locale }: LexiconMetadataView) => {
      if (categoriesRoute && locale && shopId) {
        const lexiconCategoriesPath = generatePath(categoriesRoute, {
          locale,
          shopId,
          tagType: LexiconTagType.LexiconTags,
        });
        dispatch(lexiconListActions.navigateTo({ navigateTo: lexiconCategoriesPath }));
      }
    },
    [categoriesRoute, dispatch]
  );

  const lexiconMetadataListSorted = useMemo(() => {
    return lexiconMetadataList ? orderBy(lexiconMetadataList, 'displayName') : undefined;
  }, [lexiconMetadataList]);

  const lexicons = lexiconMetadataListSorted?.map((lexicon, index) => {
    return (
      <LanguageCard
        key={lexicon.locale}
        displayName={lexicon.displayName}
        iconName={lexicon.iconName}
        color={getCardColor(index)}
        onClick={() => onCardClick(lexicon)}
      />
    );
  });

  const emptyListMessage = lexicons && lexicons.length === 0 && (
    <Typography variant={TypographyVariant.MediumBold} type={TypographyType.Heading}>
      This shop has no lexicons.
      <br />
      <br />
      Please contact your Success Manager
    </Typography>
  );

  return (
    <LanguageListLayoutStyled>
      {emptyListMessage || lexicons || <Skeleton width={271} height={166} repeat={4} />}
    </LanguageListLayoutStyled>
  );
};
