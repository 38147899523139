import React, { useCallback, useMemo } from 'react';
import { AvailableIcons, Button } from 'src/components-dummy';
import { Dispatch } from 'src/components-bl/types';
import { IApiKey } from 'src/services/src/service/types/shops/api-keys';
import {
  GalleryPreviewStyled,
  HeaderButtonsContainerStyled,
  SkeletonStyled,
} from '../../../../CustomInspirations/EditCustomInspirationsGalleryPage/components/GalleryPreview/GalleryPreview.styles';
import { ShoppableBannerDraft } from '../../types';
import { ExpandableSection } from 'src/components-bl/Galleries/CustomInspirations/hooks/useExpandableOptions';
import { AISuggestedTagsActionButtons } from 'src/components-bl/Galleries/CustomInspirations/EditCustomInspirationsGalleryPage/components/GalleryPreview/components/AISuggestedTagsActionButtons/AISuggestedTagsActionButtons';
import { BannerDisplay } from './components/BannerDisplay';
import { SkeletonBanner, ContentHeaderRowStyled } from './BannerPreview.styles';
import { DEFAULT_BANNER_IMAGE } from './components/constants';

const BASE_PREVIEW_URL = 'https://search.syte.ai/previewGalleries.html';

function LoadingSkeleton() {
  return (
    <SkeletonBanner>
      <SkeletonStyled height={300} width={970} variant='rounded' />
      <SkeletonStyled height={300} width={970} variant='rounded' />
    </SkeletonBanner>
  );
}

interface BannerPreviewProps {
  gallery: ShoppableBannerDraft | undefined;
  galleryId: string;
  startTagsDetection: () => void;
  applyAllSuggestedTags: () => void;
  removeAllSuggestedTags: () => void;
  navigateToImageSettings: (imageId: string, sections: ExpandableSection[]) => void;
  onChange: (partialDraft: Partial<ShoppableBannerDraft>) => void;
  dispatch: Dispatch;
  shopId: number;
  shopApiKey?: IApiKey;
}

export const BannerPreview = ({
  gallery,
  galleryId,
  onChange,
  navigateToImageSettings,
  dispatch,
  shopId,
  shopApiKey,
  startTagsDetection,
  applyAllSuggestedTags,
  removeAllSuggestedTags,
}: BannerPreviewProps): JSX.Element => {
  const isLoading = useMemo(() => !gallery, [gallery]);

  const imagesToRender = useMemo(() => {
    return (gallery?.images || []).map(image => {
      if (!image.imageUrl) {
        return {
          ...image,
          imageUrl: DEFAULT_BANNER_IMAGE,
        };
      }
      return image;
    });
  }, [gallery]);

  const navigateToEditTags = useCallback(
    (imageId: string) => {
      navigateToImageSettings(imageId, []);
    },
    [navigateToImageSettings]
  );

  const navigateToEditImageTexts = useCallback(
    (imageId: string) => {
      navigateToImageSettings(imageId, [ExpandableSection.AltText, ExpandableSection.ImageUrl]);
    },
    [navigateToImageSettings]
  );

  const onPreviewGalleryClick = useCallback(() => {
    const url = `${BASE_PREVIEW_URL}?galleryId=${galleryId}&shopId=${shopId}&sig=${shopApiKey?.key}`;
    window.open(url, '_blank');
  }, [galleryId, shopId, shopApiKey]);

  const hasAiSuggestedTags = useMemo(
    () => Boolean(gallery?.images?.some(image => image.aiSuggestedTags.length > 0)),
    [gallery]
  );

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  const isDefaultImages = imagesToRender.every(image => image.imageUrl === DEFAULT_BANNER_IMAGE);

  return (
    <GalleryPreviewStyled>
      <ContentHeaderRowStyled>
        <HeaderButtonsContainerStyled>
          <AISuggestedTagsActionButtons
            removeAllSuggestedTags={removeAllSuggestedTags}
            applyAllSuggestedTags={applyAllSuggestedTags}
            startTagsDetection={startTagsDetection}
            isCurrentlyDetectionTags={!!gallery?.isDetectingTags}
            isGalleryLoading={isLoading}
            hasAiSuggestedTags={hasAiSuggestedTags}
            isDefaultImages={isDefaultImages}
          />
          {shopApiKey && (
            <Button
              variant='secondary'
              startIcon={AvailableIcons.PreviewButton}
              onClick={onPreviewGalleryClick}
            >
              Preview
            </Button>
          )}
        </HeaderButtonsContainerStyled>
      </ContentHeaderRowStyled>
      <BannerDisplay
        gallery={{ ...gallery, images: imagesToRender }}
        onAddTags={navigateToEditTags}
        onImageClick={navigateToEditImageTexts}
        onChange={onChange}
        shopId={shopId}
        dispatch={dispatch}
      />
    </GalleryPreviewStyled>
  );
};
