export enum FallbackType {
  None = 'none',
  BestSellers = 'best_sellers',
  TrendingItems = 'trending_items',
  CustomFallback = 'custom_fallback',
  AlternativeCollection = 'collection',
}

export type Fallback = {
  method: FallbackType;
  targetField: string | undefined;
  collectionId: string | undefined;
  sortingOrder: string | undefined;
};

export enum FallbackFieldLabel {
  FALLBACK_TYPE = 'Fallback Type',
  FALLBACK_FIELD = 'Fallback Field',
  FALLBACK_ORDER = 'Order Of Results',
  FALLBACK_COLLECTION = 'Fallback Collection',
}

export enum FallbackSelectorsPlaceholder {
  ALTERNATIVE_COLLECTION = 'Select a fallback collection',
  CUSTOM_FIELD = 'Select a fallback field',
}
