import styled from '@emotion/styled';
import { Skeleton, Button } from 'src/components-dummy';

export const GalleryPreviewStyled = styled.div`
  width: 100%;
  flex: 1;
  background-color: ${({ theme }) => theme.palette.custom['gray-90']};
  padding: 0 32px 32px;

  overflow-y: auto;
`;

export const ContentHeaderRowStyled = styled.div`
  padding: 32px 0 24px;
  display: flex;
  justify-content: space-between;
`;

export const SkeletonGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 220px));
  justify-items: center;
  justify-content: center;
  padding: 0 4px;
  gap: 16px;
`;

export const SkeletonStyled = styled(Skeleton)``;

export const HeaderButtonsContainerStyled = styled.div`
  display: flex;
  gap: 14px;
`;

export const AddImagesButtonStyled = styled(Button)`
  .syte-basic-icon {
    path {
      stroke: ${({ theme }) => theme.palette.common.white};
    }
  }

  &.Mui-disabled .syte-basic-icon {
    path {
      stroke: ${({ theme }) => theme.palette.custom['gray-30']};
    }
  }
`;
