import React from 'react';
import { SideNavNames } from 'src/components-bl';
import { Icon, AvailableIcons, ISideNavItem, SideNavGroups } from 'src/components-dummy';
import { generatePath } from 'react-router';
import { PermittedRouteMap } from 'src/app-routes';
import { AppSideNavItem, AppSideNavRoute } from './types';
import { ShopFeatureToggles, ShopFeatureToggleBase } from '../../services/src/service/types/shops';

function convertToSideNavItem(
  appSideNavItem: AppSideNavItem & { routeParameters?: Record<string, string | number> }
): ISideNavItem | undefined {
  if (!appSideNavItem.route) {
    return undefined;
  }

  const { icon, className, wrapperClassName, group } = appSideNavItem;

  const url = appSideNavItem.routeParameters
    ? generatePath(appSideNavItem.route.path, appSideNavItem.routeParameters)
    : appSideNavItem.route.path;

  const title = appSideNavItem.title ?? appSideNavItem.route.title;

  const permittedNode = {
    url, // TODO : when we support shopId in URL, need to change this to generatePath
    pathTemplate: url,
    title,
    icon,
    className,
    wrapperClassName,
    group,
  } as ISideNavItem;

  return permittedNode;
}

export function convertToSideNavItems(
  routes: AppSideNavItem[],
  routeParameters?: Record<string, string | number>
): ISideNavItem[] {
  const convertedRoutes = routes
    .map(route => convertToSideNavItem({ ...route, routeParameters }))
    .filter(sideNavItem => !!sideNavItem) as ISideNavItem[];
  return convertedRoutes;
}

function getNavItemIfFeatureEnabled(
  sideNaveItem: AppSideNavItem,
  featureToggle?: ShopFeatureToggleBase
): AppSideNavItem[] {
  return featureToggle?.enabled ? [sideNaveItem] : [];
}

export const buildSideNavRoutes = (
  permittedRoutes: PermittedRouteMap,
  featureToggles?: ShopFeatureToggles
): Record<SideNavNames, AppSideNavItem[]> => {
  const sideNavConfig: Record<SideNavNames, AppSideNavRoute[]> = {
    [SideNavNames.Account]: [
      {
        route: permittedRoutes.shopList,
        icon: <Icon name={AvailableIcons.Shop} />,
      },
      {
        route: permittedRoutes.team,
        icon: <Icon name={AvailableIcons.Team} />,
      },
      {
        route: permittedRoutes.accountSettings,
        icon: <Icon name={AvailableIcons.Gear} />,
      },
    ] as AppSideNavItem[],
    [SideNavNames.Shop]: [
      {
        route: permittedRoutes.home,
        icon: <Icon name={AvailableIcons.Home} />,
        group: SideNavGroups.Home,
      },
      {
        route: permittedRoutes.shopperExperiences,
        icon: <Icon name={AvailableIcons.ShopperExperiences} />,
        group: SideNavGroups.WebsiteExperiences,
      },
      ...getNavItemIfFeatureEnabled(
        {
          route: permittedRoutes.collections,
          icon: (
            <Icon name={AvailableIcons.CollectionsNew} className='syte-basic-icon-transparent' />
          ),
          group: SideNavGroups.WebsiteExperiences,
        },
        featureToggles?.collections
      ),
      {
        route: permittedRoutes.uiTemplates,
        icon: <Icon name={AvailableIcons.UiTemplates} className='syte-basic-icon-transparent' />,
        group: SideNavGroups.WebsiteExperiences,
      },
      {
        route: permittedRoutes.emailMarketing,
        icon: <Icon name={AvailableIcons.Email} />,
        group: SideNavGroups.WebsiteExperiences,
      },
      {
        route: permittedRoutes.merchandisingRules,
        icon: <Icon name={AvailableIcons.MerchRules} />,
        group: SideNavGroups.Merchandiser,
      },
      {
        route: permittedRoutes.ranking,
        icon: <Icon name={AvailableIcons.Sorting} />,
        group: SideNavGroups.Merchandiser,
      },
      {
        route: permittedRoutes.experiments,
        icon: <Icon name={AvailableIcons.Group} />,
        group: SideNavGroups.Experiments,
      },
      {
        route: permittedRoutes.catalogManager,
        icon: <Icon name={AvailableIcons.CatalogManager} />,
        group: SideNavGroups.TagsManagement,
      },
      {
        route: permittedRoutes.lexicons,
        icon: <Icon name={AvailableIcons.Lexicon} />,
        group: SideNavGroups.TagsManagement,
      },
      {
        route: permittedRoutes.deepTagReports,
        icon: <Icon name={AvailableIcons.TagManagement} />,
        group: SideNavGroups.TagsManagement,
      },
      {
        route: permittedRoutes.shopSettings,
        icon: <Icon name={AvailableIcons.Setting} />,
        group: SideNavGroups.ShopSettings,
      },
    ],
  };

  return sideNavConfig;
};
