import React from 'react';
import { RecEnginesGeneralSettingsType } from 'src/services';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { EntityDiffSchema } from '../../types';

type RecEnginesGeneralSettingsDiffSchema =
  RecEnginesGeneralSettingsType.RecEnginesGeneralSettingsSchema;

export const recEnginesGeneralSettingsDiffSchema: EntityDiffSchema<
  RecEnginesGeneralSettingsDiffSchema,
  RecEnginesGeneralSettingsType.RecEnginesGeneralSettingsSchema
> = {
  desktop: {
    displayName: 'Desktop',
  },
  mobile: {
    displayName: 'Mobile',
  },
  active: {
    displayName: 'Active',
  },
  attribute: {
    displayName: 'Attribute',
  },
  selector: {
    displayName: 'Selector',
  },
  enable: {
    displayName: 'Enable',
  },
  prioritizedCarousel: {
    displayName: 'Prioritized Carousel',
    getValue({ entity }) {
      let prioritizedCarouselText = '';
      if (entity) {
        const removeDuplicates = Object.values(entity);
        const prioritizedCarousel: string[] = [];
        removeDuplicates[1].forEach((item: string) => {
          let productName: string;
          switch (item) {
            case 'shop_the_look':
              productName = 'Shop The Look';
              break;
            case 'bought_together':
              productName = 'Bought Together';
              break;
            case 'similar_items':
              productName = 'Similar Items';
              break;
            case 'personalization':
              productName = 'Personalization';
              break;
            default:
              productName = '';
              break;
          }
          prioritizedCarousel.push(productName);
        });
        prioritizedCarouselText = `${prioritizedCarousel.join(', ')}`;
      }

      const text = prioritizedCarouselText ? (
        <p>
          <br />
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
            {prioritizedCarouselText}
          </Typography>
        </p>
      ) : (
        ''
      );

      return text;
    },
  },
};
