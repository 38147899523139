import React, { useCallback, useEffect } from 'react';
import { Dispatch } from 'src/components-bl/types';
import { RoutedComponentProps } from 'src/app-routes';
import { AvailableIcons, Button, Icon, Link, Page } from 'src/components-dummy';
import { EmailMarketingCampaignsTableSkeleton } from './components';
import { columns } from './components/CampaignsTable/constants';
import {
  EmailMarketingCampaignsTablePageStyled,
  PageTitlesWrapper,
} from './EmailMarketingCampaignsTablePage.styles';
import { EmailMarketingCampaignsBreadCrumbs } from '../EmailMarketingCampaignsBreadCrumbs';
import { CampaignsList } from './components/CampaignsTable/CampaignsList';
import { emailMarketingCampaignsActions } from '../EmailMarketingCampaignsActions';
import { EmailMarketingCampaignsReducerState } from 'src/app-state-types';

interface EmailMarketingCampaignsTablePageProps extends RoutedComponentProps {
  shopId: number;
  campaigns: EmailMarketingCampaignsReducerState['campaigns'];
  dispatch: Dispatch;
  navigateToCreateCampaign: () => void;
  navigateToEditCampaign: (campaignId?: string) => void;
}

export const EmailMarketingCampaignsTablePage = ({
  shopId,
  campaigns,
  dispatch,
  permittedRouteMap,
  navigateToCreateCampaign,
  navigateToEditCampaign,
}: EmailMarketingCampaignsTablePageProps): JSX.Element => {
  const isLoadingCampaigns = campaigns === undefined;

  useEffect(() => {
    dispatch(emailMarketingCampaignsActions.getCampaigns({ shopId }));
  }, [dispatch, shopId]);

  const onDeleteCampaign = useCallback(
    (campaignId: string) => {
      dispatch(emailMarketingCampaignsActions.deleteCampaign({ shopId, campaignId }));
    },
    [shopId, dispatch]
  );

  return (
    <EmailMarketingCampaignsTablePageStyled>
      <Page.Header>
        <PageTitlesWrapper>
          <EmailMarketingCampaignsBreadCrumbs
            shopId={shopId}
            permittedRouteMap={permittedRouteMap}
            dispatch={dispatch}
          >
            Email Marketing
          </EmailMarketingCampaignsBreadCrumbs>
          <Page.SubTitle>
            Create and manage recommendation blocks to supercharge your email campaigns.{' '}
            <Link href='https://support.syte.ai/space/SP/2735768893/ER.+Overview'>
              Learn more about Email Marketing Recommendations
              <Icon name={AvailableIcons.ArrowRec} />
            </Link>
          </Page.SubTitle>
        </PageTitlesWrapper>
        <Button variant='primary' onClick={navigateToCreateCampaign}>
          Create New Campaign
        </Button>
      </Page.Header>
      <Page.Content>
        {isLoadingCampaigns ? (
          <EmailMarketingCampaignsTableSkeleton />
        ) : (
          <CampaignsList
            campaigns={campaigns}
            columns={columns}
            onDeleteCampaign={onDeleteCampaign}
            navigateToEditCampaign={navigateToEditCampaign}
            navigateToCreateCampaign={navigateToCreateCampaign}
            dispatch={dispatch}
          />
        )}
      </Page.Content>
    </EmailMarketingCampaignsTablePageStyled>
  );
};
