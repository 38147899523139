import React from 'react';
import { Accordion } from 'src/components-dummy';
import { CustomInspirationsGalleryImage } from 'src/services';
import { ParseJoiValidateResponse } from 'src/utils';
import { ExpandableSectionTrigger } from '../../../../../ExpandableSectionTrigger';
import { TextSection } from '../TextSection';
import {
  ExpandableOptionsStyled,
  ExpandableSectionTriggerStyled,
} from './ExpandableOptions.styled';
import {
  ExpandableSection,
  useExpandableOptions,
} from 'src/components-bl/Galleries/CustomInspirations/hooks/useExpandableOptions';

interface ExpandableOptionsProps {
  image: CustomInspirationsGalleryImage;
  disabled: boolean;
  errors: ParseJoiValidateResponse<Pick<CustomInspirationsGalleryImage, 'altText' | 'caption'>>;
  onChange: (partialImage: Partial<Omit<CustomInspirationsGalleryImage, 'id'>>) => void;
}

export function ExpandableOptions({
  image,
  disabled,
  errors,
  onChange,
}: ExpandableOptionsProps): JSX.Element {
  const { expandedSections, onExpandSelectionChange, isSectionExpanded, onTextChange } =
    useExpandableOptions({
      errors,
      onChange,
    });

  return (
    <ExpandableOptionsStyled>
      <Accordion
        onSelectionChanged={onExpandSelectionChange}
        expandedIds={expandedSections as string[]}
        disabled={disabled}
        canExpandMultiple
      >
        <Accordion.Item id={ExpandableSection.AltText} observeHeight>
          <Accordion.Item.Header>
            <ExpandableSectionTriggerStyled
              title='Alt Text'
              isExpanded={isSectionExpanded(ExpandableSection.AltText)}
              isDisabled={disabled}
            />
          </Accordion.Item.Header>
          <Accordion.Item.Content>
            <TextSection
              label='Alt text'
              value={image.altText}
              errorMessage={errors.altText?.message}
              onChange={onTextChange('altText')}
            />
          </Accordion.Item.Content>
        </Accordion.Item>

        <Accordion.Item id={ExpandableSection.Caption} observeHeight>
          <Accordion.Item.Header>
            <ExpandableSectionTrigger
              title='Caption'
              isExpanded={isSectionExpanded(ExpandableSection.Caption)}
              isDisabled={disabled}
            />
          </Accordion.Item.Header>
          <Accordion.Item.Content>
            <TextSection
              label='Caption'
              value={image.caption}
              errorMessage={errors.caption?.message}
              onChange={onTextChange('caption')}
            />
          </Accordion.Item.Content>
        </Accordion.Item>
      </Accordion>
    </ExpandableOptionsStyled>
  );
}
