import React, { useCallback, useMemo, useState } from 'react';
import { orderBy } from 'lodash';
import { format } from 'date-fns';
import { TableV2 } from 'src/components-dummy';
import { Gallery, GalleryType } from 'src/services';
import { IGalleryTableRow } from './types';
import { tableColumns, tableColumnsArray } from './tableColumns';
import { TableWrapperStyled } from './GalleriesTable.styles';
import { GalleryTableRow } from './components/GalleryTableRow/GalleryTableRow';
import { galleryTypeTranslations } from '../../../constants';

interface GalleriesListProps {
  galleries: Gallery[];
  navigateToEditGalleryPage: (galleryId: string, galleryType: GalleryType) => void;
  deleteGallery: (galleryId: string, galleryType: GalleryType) => void;
  navigateToGalleryPage: (galleryId: string, galleryType: GalleryType) => void;
}

const DATE_FORMAT = 'MMM dd';

interface SortByConfig {
  columnName: string;
  sortAscending: boolean;
}
export const GalleriesList = ({
  galleries,
  navigateToEditGalleryPage,
  deleteGallery,
  navigateToGalleryPage,
}: GalleriesListProps): JSX.Element => {
  const [sortByConfig, setSortByConfig] = useState<SortByConfig>({
    columnName: tableColumns.galleryName.accessor,
    sortAscending: true,
  });

  const tableData: IGalleryTableRow[] = useMemo(() => {
    const mappedTableData = galleries.map(gallery => {
      const formattedLastUpdated = format(gallery.updatedAt, DATE_FORMAT);

      return {
        id: gallery.id,
        galleryName: gallery.galleryName,
        galleryType: gallery.galleryType,
        galleryDisplayType: galleryTypeTranslations[gallery.galleryType],
        totalImages: gallery.images.length,
        lastUpdated: formattedLastUpdated,
        rowActions: true,
      };
    });
    return orderBy(
      mappedTableData,
      [sortByConfig.columnName],
      [sortByConfig.sortAscending ? 'asc' : 'desc']
    );
  }, [galleries, sortByConfig]);

  const onSortChange = useCallback(
    (columnName: string) => {
      setSortByConfig(state => ({
        columnName,
        sortAscending: !state.sortAscending,
      }));
    },
    [setSortByConfig]
  );

  return (
    <TableWrapperStyled>
      <TableV2<IGalleryTableRow>
        options={{ columns: tableColumnsArray, data: tableData }}
        customOptions={{
          shouldUseResizeColumns: true,
          shouldUseFlexLayout: true,
        }}
      >
        {({ getTableBodyProps, headerGroups, rows, prepareRow, state }) => {
          const headerGroup = headerGroups[0];

          const isResizingColumn = !!state.columnResizing.isResizingColumn;

          return (
            <>
              <TableV2.Head {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableV2.HeadRow {...column.getHeaderProps()} isResizable key={column.id}>
                    <TableV2.HeadRowCellText
                      key={`${isResizingColumn}`}
                      isSortAble={tableColumns[column.id].canSort === true}
                      sortAscending={sortByConfig.sortAscending}
                      onClick={() => onSortChange(column.id)}
                    >
                      {column.render('Header')}
                    </TableV2.HeadRowCellText>
                  </TableV2.HeadRow>
                ))}
              </TableV2.Head>
              <TableV2.Body
                {...getTableBodyProps()}
                css={{
                  overflow: 'initial',
                }}
              >
                {rows.map(row => {
                  prepareRow(row);
                  return (
                    <GalleryTableRow
                      key={row.original.id}
                      row={row}
                      navigateToEditGalleryPage={navigateToEditGalleryPage}
                      deleteGallery={deleteGallery}
                      navigateToGalleryPage={navigateToGalleryPage}
                    />
                  );
                })}
              </TableV2.Body>
            </>
          );
        }}
      </TableV2>
    </TableWrapperStyled>
  );
};
