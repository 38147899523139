import React from 'react';
import { CampaignsTableHeadRow } from '../../types';
import { TableV2, TableV2InstanceProps } from 'src/components-dummy';
import { CampaignsTableRow } from './components/CampaignsTableRow';
import { TableWrapperStyled } from './CampaignsTable.styles';
import { CampaignsTableHeaderColumns } from './components/CampaginsTableHeaderColumns';
import { Campaign } from '../../../types/campaign';
import { Dispatch } from 'src/components-bl/types';
import { CampaignsEmptyTable } from './CampaignsEmptyTable';

interface CampaignsListProps {
  campaigns: Campaign[];
  columns: CampaignsTableHeadRow[];
  onDeleteCampaign?: (campaignId: string) => void;
  navigateToEditCampaign?: (campaignId: string) => void;
  navigateToCreateCampaign?: () => void;
  dispatch: Dispatch;
}

export const CampaignsList = ({
  campaigns,
  columns,
  onDeleteCampaign,
  navigateToEditCampaign,
  navigateToCreateCampaign,
  dispatch,
}: CampaignsListProps): JSX.Element => {
  const hasCampaigns = !!campaigns && campaigns.length > 0;

  const renderBody = ({
    rows,
    prepareRow,
  }: Pick<TableV2InstanceProps<any>, 'rows' | 'prepareRow'>) => {
    return rows.map(row => {
      prepareRow(row);
      return (
        <CampaignsTableRow
          key={row.original.id}
          row={row}
          onDeleteCampaign={onDeleteCampaign}
          navigateToEditCampaign={navigateToEditCampaign}
          navigateToCreateCampaign={navigateToCreateCampaign}
          dispatch={dispatch}
        />
      );
    });
  };

  if (!hasCampaigns) {
    return <CampaignsEmptyTable navigateToCreateCampaignPage={navigateToCreateCampaign} />;
  }

  return (
    <TableWrapperStyled>
      <TableV2
        options={{ columns, data: campaigns }}
        customOptions={{
          shouldUseResizeColumns: true,
          shouldUseFlexLayout: true,
        }}
      >
        {({ getTableBodyProps, headerGroups, rows, prepareRow }) => {
          const headerGroup = headerGroups[0];

          return (
            <>
              <TableV2.Head isSticky {...headerGroup.getHeaderGroupProps()}>
                <CampaignsTableHeaderColumns headers={headerGroup.headers} />
              </TableV2.Head>
              <TableV2.Body {...getTableBodyProps()}>
                {renderBody({ rows, prepareRow })}
              </TableV2.Body>
            </>
          );
        }}
      </TableV2>
    </TableWrapperStyled>
  );
};
