/* eslint-disable prettier/prettier */
import React, { useCallback, useMemo } from 'react';
import MuiSelect, {
  SelectProps as MuiSelectProps,
  SelectChangeEvent as MuiSelectChangeEvent,
} from '@mui/material/Select';
import MuiInputLabel from '@mui/material/InputLabel';
import { merge } from 'lodash';
import {
  PrimarySelectStyled,
  MenuSelectStyled,
  SelectCaretIconStyled,
  BaseMenuStyled,
  EllipsisWithTooltipWrapperStyled,
} from './Select.style';
import { EllipsisWithTooltip } from '../EllipsisWithToolTip';
import { MenuItem } from '../MenuItem';
import { ErrorLabel } from '../ErrorLabel';
import { UseSelect } from './useSelect';
import {
  MenuPosition,
  SelectType,
  MENU_MARGIN,
  MENU_MAX_HEIGHT,
  MENU_TRANSITION,
} from './Select.config';
import { AvailableIcons } from '../Icon';

/**
 * Types
 */
type OnChangeEvent = MuiSelectChangeEvent<string>;

export type SelectOnChangeEvent = (event: OnChangeEvent, child?: React.ReactNode) => void;
export type SelectValue = string | number | boolean | undefined;

export interface SelectOption<T extends SelectValue = SelectValue> {
  value: T;
  isDisabled?: boolean;
  text: string;
}

export interface BaseSelectProps
  extends Partial<Pick<MuiSelectProps, 'onClose' | 'renderValue' | 'onClick'>> {
  value?: string | number | readonly string[] | undefined;
  defaultValue?: string | number | readonly string[];
  children?: React.ReactNode | React.ReactNode[];
  disabled?: boolean;
  isError?: boolean;
  errorMessage?: string | JSX.Element;
  variant?: 'standard' | 'outlined' | 'filled';
  onChange?: SelectOnChangeEvent;
  onOpen?: (event: React.SyntheticEvent) => void;
  className?: string;
  prefixId?: string;
  placeholder?: string;
  autoFocus?: boolean;
  hasNoneOption?: boolean;
}

export interface PrimarySelectProps extends BaseSelectProps {
  label?: React.ReactNode;
  labelId?: string;
  multiple?: boolean;
  placeholder?: string;
  prefixId?: string;
}

/**
 * Utils
 */
// Needed due to bug in material UI - when want to stop propagation onChange - it doesn't work
const preventPropagation = (event: React.MouseEvent<HTMLDivElement>) => {
  event.stopPropagation();
  event.preventDefault();
};

const RenderErrorMessage = ({ message }: { message: string | JSX.Element }): JSX.Element => {
  return <>{message && typeof message === 'string' ? <ErrorLabel value={message} /> : message}</>;
};

const SelectCaretIcon = () => (
  <SelectCaretIconStyled name={AvailableIcons.SelectCaret} className='select-caret' />
);

const NoOptions = () => <MenuItem disabled>Empty</MenuItem>;

/**
 * PrimarySelect - standard select with/without label - use for form value selection
 */
const PrimarySelect = ({
  value,
  defaultValue,
  label,
  labelId,
  children = [],
  placeholder = 'Select item...',
  disabled = false,
  isError,
  errorMessage,
  multiple,
  className,
  onChange,
  onClick,
  prefixId,
  autoFocus,
  onClose: onCloseExternal,
  hasNoneOption,
}: PrimarySelectProps): JSX.Element => {
  const selectOptions = useMemo(() => React.Children.toArray(children), [children]);

  const selectOptionsValueToTextMap: Record<string, string> = useMemo(() => {
    return selectOptions.reduce((accumulator: any, item: any) => {
      accumulator[item.props.value] = item.props.text;
      return accumulator;
    }, {});
  }, [selectOptions]);

  const {
    onOpen,
    selectRef,
    inputRef,
    menuPosition,
    selectInputDimensions,
    menuRefTopPosition,
    hasOptions,
    isListOpen,
    onClose,
  } = UseSelect({
    totalOptions: selectOptions.length,
    autoFocus,
    onCloseExternal,
  });

  const dataId = `${prefixId}_primarySelect-${isListOpen}`;

  // Single select (not multiple)
  const selectedOption =
    !multiple &&
    value !== undefined &&
    selectOptions.find(item => `${(item as any).props.value}` === `${value}`);

  // for multiple value is an array
  const actualValue = value || '';

  const onRenderValue = useCallback(
    (selected: string | string[]) => {
      if (
        selected === undefined ||
        value === undefined ||
        (multiple && !(value as string[])?.length)
      ) {
        return (
          <MenuItem className='select-placeholder' value='' disabled>
            {placeholder}
          </MenuItem>
        );
      }

      let text;
      if (multiple) {
        text = (
          (selected as string[]).map(selectedValue => selectOptionsValueToTextMap[selectedValue]) ||
          []
        ).join(', ');
      } else if (selectedOption) {
        text = selectedOption ? (selectedOption as any).props.children : '';
      } else if (value.toString().length > 0) {
        text = value.toString();
      }

      return (
        <EllipsisWithTooltipWrapperStyled>
          <EllipsisWithTooltip tooltipPosition='top center' tooltipText={text}>
            {text}
          </EllipsisWithTooltip>
        </EllipsisWithTooltipWrapperStyled>
      );
    },
    [placeholder, value, selectedOption]
  );

  const isErrorMode = !!isError || !!errorMessage;

  return (
    <PrimarySelectStyled data-id={dataId} className={className}>
      {label && <MuiInputLabel data-id={`${dataId}_label`}>{label}</MuiInputLabel>}
      <div ref={selectRef as React.MutableRefObject<HTMLDivElement>} />
      <MuiSelect
        displayEmpty={multiple ? !(value as string[])?.length : !value}
        labelId={labelId}
        value={actualValue as string}
        error={isErrorMode}
        disabled={disabled}
        onChange={onChange}
        onOpen={onOpen}
        onClose={onClose}
        onClick={onClick || preventPropagation}
        renderValue={onRenderValue}
        multiple={!!multiple}
        MenuProps={
          {
            autoFocus,
            anchorEl: selectRef.current,
            transitionDuration: MENU_TRANSITION,
            anchorOrigin: {
              vertical:
                menuPosition === MenuPosition.Bottom
                  ? selectInputDimensions.height + MENU_MARGIN
                  : menuRefTopPosition - MENU_MARGIN,
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            PaperProps: {
              sx: {
                ...BaseMenuStyled,
                maxHeight: MENU_MAX_HEIGHT,
                maxWidth: `${selectInputDimensions.width}px`,
                marginLeft: `-3px`,
              },
            },
          } as Partial<MuiSelectProps['MenuProps']>
        }
        IconComponent={SelectCaretIcon}
        defaultValue={defaultValue as string}
        inputProps={{ autoFocus, ref: inputRef }}
      >
        {hasNoneOption && (
          <MenuItem key='none' value=''>
            None
          </MenuItem>
        )}
        {hasOptions ? children : <NoOptions />}
      </MuiSelect>
      {!!errorMessage && <RenderErrorMessage message={errorMessage} />}
    </PrimarySelectStyled>
  );
};

export type MenuSelectProps = BaseSelectProps & {
  // TODO: Add some deep partial type - make sure performance of compilation is ok
  MenuProps?: unknown;
};

/**
 * MenuSelect - use in menu - for filtering (without label - with different styling)
 */
const MenuSelect = ({
  value,
  children,
  disabled = false,
  isError,
  errorMessage,
  className,
  onChange,
  prefixId,
  placeholder = 'Select item...',
  autoFocus,
  hasNoneOption,
  MenuProps,
  onClose: onCloseExternal,
  renderValue: onRenderValueExternal,
}: MenuSelectProps): JSX.Element => {
  const selectOptions = React.Children.toArray(children);

  const {
    selectRef,
    inputRef,
    menuPosition,
    selectInputDimensions,
    menuRefTopPosition,
    onOpen,
    hasOptions,
    onClose,
    isListOpen,
  } = UseSelect({
    totalOptions: selectOptions.length,
    autoFocus,
    value,
    onCloseExternal,
  });
  const dataId = `${prefixId}_menuSelect-${isListOpen}`;
  const selectedOption =
    value !== undefined &&
    selectOptions.find(item => `${(item as any).props.value}` === `${value}`);

  const actualValue = (value as string) || '';

  const onRenderValue = useCallback(
    (selected: string) => {
      if (selected === undefined || value === undefined) {
        return (
          <MenuItem className='select-placeholder' value='' disabled>
            {placeholder}
          </MenuItem>
        );
      }

      let text = placeholder;

      if (selectedOption) {
        text = (selectedOption as any).props.children;
      } else if (value.toString().length > 0) {
        text = value.toString();
      }

      return (
        <EllipsisWithTooltipWrapperStyled>
          <EllipsisWithTooltip tooltipPosition='top center' tooltipText={text}>
            {text}
          </EllipsisWithTooltip>
        </EllipsisWithTooltipWrapperStyled>
      );
    },
    [placeholder, value, selectedOption]
  );

  const isErrorMode = hasOptions && (!!isError || !!errorMessage);

  const defaultMenuProps = useMemo(
    () =>
      ({
        autoFocus,
        anchorEl: selectRef.current,
        transitionDuration: MENU_TRANSITION,
        anchorOrigin: {
          vertical:
            menuPosition === MenuPosition.Bottom
              ? selectInputDimensions.height + MENU_MARGIN
              : menuRefTopPosition - MENU_MARGIN,
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        PaperProps: {
          sx: {
            ...BaseMenuStyled,
            maxHeight: MENU_MAX_HEIGHT,
          },
        },
      }) as Partial<MuiSelectProps['MenuProps']>,
    [autoFocus, menuRefTopPosition, selectRef, selectInputDimensions, menuPosition]
  );

  const mergedMenuProps = useMemo(
    () => merge({}, defaultMenuProps, MenuProps),
    [defaultMenuProps, MenuProps]
  );

  return (
    <MenuSelectStyled data-id={dataId} className={className}>
      <div ref={selectRef as React.MutableRefObject<HTMLDivElement>} />
      <MuiSelect
        displayEmpty={!value}
        value={actualValue}
        onChange={onChange}
        disabled={disabled}
        error={isErrorMode}
        onOpen={onOpen}
        onClose={onClose}
        onClick={preventPropagation}
        renderValue={onRenderValueExternal || onRenderValue}
        MenuProps={mergedMenuProps}
        IconComponent={SelectCaretIcon}
        inputProps={{ ref: inputRef }}
      >
        {hasNoneOption && (
          <MenuItem key='none' value=''>
            None
          </MenuItem>
        )}
        {hasOptions ? children : <NoOptions />}
      </MuiSelect>
      {hasOptions && !!errorMessage && <RenderErrorMessage message={errorMessage} />}
    </MenuSelectStyled>
  );
};

type SelectProps = {
  type: SelectType;
} & (PrimarySelectProps | MenuSelectProps);

/**
 * According to type - returns component
 */
const SelectFactory = ({ type, ...props }: SelectProps): JSX.Element => {
  switch (type) {
    case SelectType.Primary:
    default:
      return <PrimarySelect {...props} />;
    case SelectType.Menu:
      return <MenuSelect {...props} />;
    // TODO: create dedicated multiple
  }
};

export { SelectFactory as Select };
