import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CollectionSectionStyled } from '../../styles/CollectionSection.styles';
import { RuleFormSection } from 'src/components-bl/MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormSection/RuleFormSection';
import { SubTitleStyled } from '../CollectionSnippet/SubTitle.styled';
import { TypographyType, TypographyVariant } from 'src/components-dummy';
import { FallbackType, Fallback } from './enums';
import { Collection } from '../../types';
import { useAppSelector } from 'src/hooks';
import { getNumericFields } from 'src/components-bl/helpers/getNumericFields';
import { useMappedSelectValues } from 'src/components-bl/hooks/useMappedSelectValues';
import { SortingOrder } from 'src/types/enums/sorting-order';
import { SelectChangeEvent } from '@mui/material';
import { FallbackTypeSelector } from './components/FallbackTypeSelector';
import { AlternativeCollectionSelector } from './components/AlternativeCollectionSelector';
import { CustomFallbackSelector } from './components/CustomFallbackSelector';

interface CollectionFallbackProps {
  draftCollection: Pick<Collection, 'id' | 'fallback'>;
  handleFieldOnChange: (fieldName: 'fallback', value: string | Fallback) => void;
  withoutBorder?: boolean;
  isReadOnly?: boolean;
}

const fallbackLabels: Record<FallbackType, string> = {
  [FallbackType.None]: 'None',
  [FallbackType.BestSellers]: 'Best Sellers',
  [FallbackType.TrendingItems]: 'Trending Items',
  [FallbackType.CustomFallback]: 'Custom Fallback',
  [FallbackType.AlternativeCollection]: 'Alternative Collection',
};

const orderOptions = [
  { value: SortingOrder.Desc, text: 'Descending' },
  { value: SortingOrder.Asc, text: 'Ascending' },
];

export const CollectionFallback = ({
  withoutBorder,
  handleFieldOnChange,
  draftCollection,
  isReadOnly = false,
}: CollectionFallbackProps) => {
  const [activeCollectionList, setActiveCollectionList] = useState<Collection[]>([]);
  const { collections } = useAppSelector(state => state.collections);
  const { dataFields } = useAppSelector(state => state.dataFields);
  const numericDataFields = useMemo(() => {
    return dataFields ? getNumericFields(dataFields) : [];
  }, [dataFields]);
  const dataFieldsDropdownOptions = useMappedSelectValues(numericDataFields);
  useEffect(() => {
    if (collections.length > 0) {
      setActiveCollectionList(collections.filter(c => c.isActive && c.id !== draftCollection.id));
    }
  }, [collections]);

  const handleFallbackChange = useCallback(
    (updatedFields: Partial<Fallback>) => {
      handleFieldOnChange('fallback', {
        ...draftCollection.fallback,
        ...updatedFields,
      });
    },
    [handleFieldOnChange, draftCollection.fallback]
  );

  const handleFallbackTypeChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const selectedMethod = event.target.value as FallbackType;
      handleFallbackChange({
        method: selectedMethod,
        targetField: undefined,
        collectionId: undefined,
        sortingOrder:
          selectedMethod === FallbackType.CustomFallback ? SortingOrder.Desc : undefined,
      });
    },
    [handleFallbackChange]
  );

  const handleCollectionFallbackChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const selectedCollection = activeCollectionList.find(
        collection => collection.id === event.target.value
      );

      handleFallbackChange({
        collectionId: selectedCollection?.id,
        targetField: selectedCollection?.dataCollectionId,
      });
    },
    [activeCollectionList, handleFallbackChange]
  );

  const handleCustomFallbackChange = useCallback(
    (field: 'targetField' | 'sortingOrder', value: string | undefined) => {
      if (field === 'targetField') {
        handleFallbackChange({ targetField: value });
      } else {
        handleFallbackChange({ sortingOrder: value });
      }
    },
    [handleFallbackChange]
  );

  return draftCollection.fallback ? (
    <CollectionSectionStyled withoutBorder={withoutBorder}>
      <RuleFormSection.Header>Fallback</RuleFormSection.Header>
      <SubTitleStyled type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
        Select the type of fallback products to display when no results are found.
      </SubTitleStyled>
      <FallbackTypeSelector
        method={draftCollection.fallback.method}
        handleChange={handleFallbackTypeChange}
        fallbackLabels={fallbackLabels}
        isReadOnly={isReadOnly}
      />

      {draftCollection.fallback.method === FallbackType.AlternativeCollection && (
        <AlternativeCollectionSelector
          collectionId={draftCollection.fallback.collectionId}
          activeCollectionList={activeCollectionList}
          handleChange={handleCollectionFallbackChange}
          isReadOnly={isReadOnly}
        />
      )}

      {draftCollection.fallback.method === FallbackType.CustomFallback && (
        <CustomFallbackSelector
          targetField={draftCollection.fallback.targetField}
          sortingOrder={draftCollection.fallback.sortingOrder}
          handleChange={handleCustomFallbackChange}
          dataFieldsDropdownOptions={dataFieldsDropdownOptions}
          orderOptions={orderOptions}
          isReadOnly={isReadOnly}
        />
      )}
    </CollectionSectionStyled>
  ) : null;
};
