import React, { useState } from 'react';
import { useAppSelector } from '../../hooks';
import { Collection, CollectionsHeader, Dispatch } from 'src/components-bl';
import { CollectionPage } from './CollectionPage.config';
import { CollectionsMain } from '../../containers/Collections/components/CollectionsMain/CollectionsMain';
import { EditCollectionRulesVariantsContainer } from './EditCollectionRules/CollectionRules/CollectionRules/EditCollectionRulesVariantsContainer';
import { useGetDefaultCollection } from '../../containers/CreateCollection/useGetDefaultCollection';
import { editCollectionActionMethods } from '../../containers/EditCollection/actions/editCollectionActions';
import {
  EditMerchandisingRulesContainer,
  EditRankingStrategyContainer,
} from '../../containers/EditCollection/components';
import { EditCollectionRulesVariantsPage } from './EditCollectionRulesVariantsPage';
import { CollectionsTranslationFileUploadModal } from './CollectionsTranslationFileUploadModal/CollectionsTranslationFileUploadModal';
import { useGetCollectionsList } from './CollectionsList/useGetCollectionsList';

interface CollectionsManagementProps {
  shopId: number;
  dispatch: Dispatch;
  currentPage: CollectionPage;
  selectedVariantId?: string;
  formHeaderElementRef?: React.RefObject<HTMLDivElement | null>;
  isReadOnly?: boolean;
}

export const CollectionsManagement = ({
  shopId,
  dispatch,
  currentPage,
  selectedVariantId,
  formHeaderElementRef,
  isReadOnly = false,
}: CollectionsManagementProps): JSX.Element | null => {
  const { collections } = useAppSelector(state => state.collections);
  const isListEmpty = !collections.length;

  useGetCollectionsList({ shopId, dispatch, variantId: selectedVariantId });

  const collection =
    useAppSelector(state => state.collections.currentCollection) || ({} as Collection);
  const { defaultCollection } = useGetDefaultCollection({ shopId, variantId: selectedVariantId });

  const [isTranslationsModalOpen, setIsTranslationsModalOpen] = useState(false);
  const [page, setPage] = useState(currentPage);

  const onPageChange = (collectionPage: CollectionPage) => {
    setPage(collectionPage);
  };

  const onCreateDefaultCollection = () => {
    onPageChange(CollectionPage.Create);
  };

  const onEditCollection = (collectionId: string) => {
    onPageChange(CollectionPage.Edit);
    dispatch(
      editCollectionActionMethods.getCollection({
        shopId,
        collectionId,
        variantId: selectedVariantId,
      })
    );
  };

  const onManageTranslations = () => {
    setIsTranslationsModalOpen(true);
  };

  const onCollectionsTranslationsModalClose = () => {
    setIsTranslationsModalOpen(false);
  };

  switch (page) {
    case CollectionPage.List:
      return (
        <>
          <CollectionsHeader
            onCreateDefaultCollection={onCreateDefaultCollection}
            onManageTranslations={onManageTranslations}
            isListEmpty={isListEmpty}
            isReadOnly={isReadOnly}
          />
          <CollectionsMain
            shopId={shopId}
            dispatch={dispatch}
            collections={collections}
            isListEmpty={isListEmpty}
            onCreateDefaultCollection={onCreateDefaultCollection}
            onEditCollection={onEditCollection}
            variantId={selectedVariantId}
            isReadOnly={isReadOnly}
          />
          {isTranslationsModalOpen && (
            <CollectionsTranslationFileUploadModal
              shopId={shopId}
              dispatch={dispatch}
              onCollectionsTranslationsModalClose={onCollectionsTranslationsModalClose}
              variantId={selectedVariantId}
            />
          )}
        </>
      );
    case CollectionPage.Edit:
      return (
        <EditCollectionRulesVariantsPage onPageChange={onPageChange}>
          <EditCollectionRulesVariantsContainer
            shopId={shopId}
            dispatch={dispatch}
            currentCollection={collection}
            onPageChange={onPageChange}
            isCreateMode={false}
            variantId={selectedVariantId}
            formHeaderElementRef={formHeaderElementRef}
            isReadOnly={isReadOnly}
          />
        </EditCollectionRulesVariantsPage>
      );
    case CollectionPage.EditMerchRules:
      return (
        <EditCollectionRulesVariantsPage onPageChange={onPageChange}>
          <EditMerchandisingRulesContainer
            shopId={shopId}
            currentCollection={collection}
            formHeaderRef={formHeaderElementRef}
            dispatch={dispatch}
            selectedVariantId={selectedVariantId}
            isReadOnly={isReadOnly}
          />
        </EditCollectionRulesVariantsPage>
      );
    case CollectionPage.EditRanking:
      return (
        <EditCollectionRulesVariantsPage onPageChange={onPageChange}>
          <EditRankingStrategyContainer
            shopId={shopId}
            currentCollection={collection}
            formHeaderRef={formHeaderElementRef}
            dispatch={dispatch}
            selectedVariantId={selectedVariantId}
            isReadOnly={isReadOnly}
          />
        </EditCollectionRulesVariantsPage>
      );
    case CollectionPage.Create:
      return (
        <EditCollectionRulesVariantsContainer
          shopId={shopId}
          dispatch={dispatch}
          currentCollection={defaultCollection}
          onPageChange={onPageChange}
          isCreateMode={true}
          variantId={selectedVariantId}
          formHeaderElementRef={formHeaderElementRef}
          isReadOnly={isReadOnly}
        />
      );
    default:
      return null;
  }
};
