import { SyteProductPlacement } from '../syte-products';
import { GalleryType } from './gallery';

export interface ImageTagCoordinates {
  x: number;
  y: number;
}

export interface ImageTag {
  id: string;
  sku: string;
  title: string;
  coordinates: ImageTagCoordinates;
  isAIDetected: boolean;
}

export type AiSuggestedImageTag = Omit<ImageTag, 'isAIDetected'>;

export interface CustomInspirationsGalleryImage {
  id: string;
  imageUrl: string;
  altText?: string;
  caption?: string;
  tags: ImageTag[];
  aiSuggestedTags: AiSuggestedImageTag[];
  isDetectingTags: boolean;
  usage?: BannerUsage;
}

export interface CustomInspirationsGalleryImageWithOptionId
  extends Omit<CustomInspirationsGalleryImage, 'id'> {
  id?: string;
}

export enum CustomInspirationsGalleryLayoutType {
  Grid = 'GRID',
  Carousel = 'CAROUSEL',
  Collage = 'COLLAGE',
  Banner = 'BANNER',
}

export enum BannerUsage {
  BannerDesktop = 'banner-desktop',
  BannerMobile = 'banner-mobile',
}
interface GridLayout {
  gridImagesPerRow: number;
  gridNumberOfRows: number;
  carouselNumberOfImages: never;
}

interface CarouselLayout {
  gridImagesPerRow: never;
  gridNumberOfRows: never;
  carouselNumberOfImages: number;
}

interface CollageLayout {
  gridImagesPerRow: never;
  gridNumberOfRows: never;
  carouselNumberOfImages: never;
}

interface BannerLayout {
  gridImagesPerRow: 1;
  gridNumberOfRows: 1;
  carouselNumberOfImages: never;
}

interface LayoutTypeMapping {
  [CustomInspirationsGalleryLayoutType.Grid]: GridLayout;
  [CustomInspirationsGalleryLayoutType.Carousel]: CarouselLayout;
  [CustomInspirationsGalleryLayoutType.Collage]: CollageLayout;
  [CustomInspirationsGalleryLayoutType.Banner]: BannerLayout;
}

export interface CustomInspirationsGalleryLayout<
  LayoutType extends CustomInspirationsGalleryLayoutType,
> {
  type: LayoutType;
}

export interface CustomInspirationsGallery<
  LayoutType extends CustomInspirationsGalleryLayoutType = CustomInspirationsGalleryLayoutType,
> {
  id: string;
  displayTitle?: string;
  galleryName: string;
  galleryType: GalleryType;
  placement: SyteProductPlacement;
  layout: CustomInspirationsGalleryLayout<LayoutType> & LayoutTypeMapping[LayoutType];
  images: CustomInspirationsGalleryImage[];
  showSimilarProductWhenUnavailable: boolean;
  updatedAt: Date;
  isDetectingTags: boolean;
  bannerLink?: string;
  bannerTextLink?: string;
}

export interface ShoppableBanner extends Omit<CustomInspirationsGallery, 'layout'> {
  galleryType: GalleryType.ShoppableBanner;
  layout: BannerLayout;
}
