import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router';
import { isEqual, isUndefined, omitBy } from 'lodash';
import { RoutedComponentProps } from 'src/app-routes';
import { AvailableIcons, Typography, TypographyVariant } from 'src/components-dummy';
import {
  CustomInspirationsGallery,
  CustomInspirationsGalleryImage,
  CustomInspirationsGalleryLayoutType,
  GalleryType,
  ShoppableBanner,
} from 'src/services';
import { useValidateSchema } from 'src/hooks';
import { Dispatch } from '../../../types';
import { tagImageModalActions } from './Actions';
import { TaggableImage } from './components';
import {
  CloseIconStyled,
  DialogModalWithPaginationContentStyled,
  DialogModalWithPaginationStyled,
  TagImageModalContentStyled,
  TagImageModalHeaderDividerStyled,
  TagImageModalHeaderStyled,
} from './TagImageModal.styles';
import { ImageSettings } from './components/ImageSettings';
import { galleryImageSettingsValidationSchema } from './constants';
import { useConfirmationDialog } from '../components/DirtyFormConfirmationDialog/useConfirmationDialog';
import {
  Action,
  DirtyFormConfirmationDialog,
} from '../components/DirtyFormConfirmationDialog/DirtyFormConfirmationDialog';
import { DEFAULT_BANNER_IMAGE } from '../../ShoppableBanner/EditShoppableBannerPage/components/BannerPreview/components/constants';

interface TagImageModalProps extends RoutedComponentProps {
  shopId: number;
  dispatch: Dispatch;
  gallery: CustomInspirationsGallery | ShoppableBanner;
  selectedImageId: string;
  isShoppableBanner?: boolean;
}

const getRoutePath = (galleryType, permittedRouteMap, shopId, galleryId) => {
  const route = {
    [GalleryType.CustomInspirations]: permittedRouteMap.editCustomInspirationsGallery,
    [GalleryType.ShoppableBanner]: permittedRouteMap.editShoppableBanner,
  }[galleryType];

  return route ? generatePath(route.path, { shopId, galleryId }) : null;
};

export function TagImageModal({
  shopId,
  dispatch,
  gallery,
  selectedImageId,
  permittedRouteMap,
}: TagImageModalProps): JSX.Element {
  const imagesToRender = useMemo(() => gallery.images, [gallery.images]);

  const selectedImageIndex = useMemo(() => {
    return imagesToRender.findIndex(image => image.id === selectedImageId);
  }, [imagesToRender, selectedImageId]);

  const selectedImage = useMemo(
    () => ({ ...imagesToRender[selectedImageIndex] }),
    [imagesToRender, selectedImageIndex]
  );

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  const [imageDraft, setImageDraft] = useState(selectedImage);
  const [isDirty, setIsDirty] = useState(false);
  const [failedToLoadImage, setFailedToLoadImage] = useState(false);

  const { showDirtyModal, closeModal, triggerTheModalIfDirtyOrCallback, currentActionConfig } =
    useConfirmationDialog({ isDirty });

  const { errors, isValid, validate, resetValidationState } = useValidateSchema<
    Pick<CustomInspirationsGalleryImage, 'altText' | 'caption' | 'imageUrl'>
  >({
    initialData: selectedImage,
    schema: galleryImageSettingsValidationSchema,
    validateOnStart: false,
  });

  const onImageErrorStatusChange = useCallback(
    ({ hasError }: { hasError: boolean }) => {
      setFailedToLoadImage(hasError);
    },
    [setFailedToLoadImage]
  );

  const resetDraft = useCallback(() => {
    setImageDraft({ ...selectedImage });
    resetValidationState();
  }, [selectedImage]);

  useEffect(() => {
    resetDraft();
  }, [resetDraft]);

  const onChange = useCallback(
    (partialImage: Partial<Omit<CustomInspirationsGalleryImage, 'id'>>) => {
      setImageDraft(prevImageDraft => {
        const updatedImage = {
          ...prevImageDraft,
          ...partialImage,
        };

        if ('imageUrl' in partialImage) {
          updatedImage.imageUrl = partialImage.imageUrl ?? DEFAULT_BANNER_IMAGE;
        }

        if (partialImage.imageUrl && partialImage.imageUrl !== prevImageDraft.imageUrl) {
          updatedImage.tags = [];
          updatedImage.aiSuggestedTags = [];
        }
        return updatedImage;
      });
    },
    [setImageDraft]
  );

  const removeTagById = useCallback(
    (id: string) => {
      const updatedTags = imageDraft.tags.filter(tag => tag.id !== id);
      const updatedAiSuggestedTags = imageDraft.aiSuggestedTags.filter(tag => tag.id !== id);

      onChange({ tags: updatedTags, aiSuggestedTags: updatedAiSuggestedTags });
    },
    [onChange, imageDraft]
  );

  const startImageTagsDetection = useCallback(() => {
    dispatch(
      tagImageModalActions.startImageTagsDetection({
        galleryId: gallery.id,
        imageId: selectedImageId,
        shopId,
      })
    );
  }, [shopId, dispatch, gallery.id, selectedImageId]);

  useEffect(() => {
    const imageDraftUndefinedRemoved = omitBy(imageDraft, isUndefined);
    const isDraftEqualToSource = isEqual(selectedImage, imageDraftUndefinedRemoved);
    setIsDirty(!isDraftEqualToSource);
  }, [imageDraft, setIsDirty]);

  useEffect(() => {
    dispatch(tagImageModalActions.notifyIsDirty({ isDirty }));
  }, [isDirty, dispatch]);

  useEffect(() => {
    validate({ dataToValidate: imageDraft });
  }, [imageDraft, validate]);

  const navigateToPreviewGallery = useCallback(() => {
    const routePath = getRoutePath(gallery.galleryType, permittedRouteMap, shopId, gallery.id);
    if (!routePath) {
      return;
    }

    dispatch(
      tagImageModalActions.navigateTo({
        navigateTo: routePath,
      })
    );
  }, [shopId, gallery.id, permittedRouteMap, dispatch]);

  const hasPreviousImage = selectedImageIndex !== 0;
  const hasNextImage = selectedImageIndex !== imagesToRender.length - 1;

  const onPaginationButtonClick = useCallback(
    ({ isNext }: { isNext: boolean }) =>
      () => {
        if (gallery.galleryType !== GalleryType.CustomInspirations) {
          return;
        }
        const targetImageIndex = isNext ? selectedImageIndex + 1 : selectedImageIndex - 1;

        const targetImage: CustomInspirationsGalleryImage | undefined =
          imagesToRender[targetImageIndex];

        if (targetImage && permittedRouteMap.tagImageInCustomInspirationsGallery) {
          dispatch(
            tagImageModalActions.navigateTo({
              navigateTo: generatePath(permittedRouteMap.tagImageInCustomInspirationsGallery.path, {
                shopId,
                galleryId: gallery.id,
                imageId: targetImage.id,
              }),
            })
          );
        }
      },
    [
      shopId,
      gallery.id,
      selectedImageIndex,
      imagesToRender,
      dispatch,
      permittedRouteMap.tagImageInCustomInspirationsGallery,
    ]
  );

  const updateGalleryImage = useCallback(
    async (
      image: CustomInspirationsGalleryImage
    ): Promise<CustomInspirationsGalleryImage | undefined> => {
      const updatedGalleryImages = [...gallery.images];
      updatedGalleryImages[selectedImageIndex] = image;

      setIsSaveInProgress(true);

      let updatedGallery: CustomInspirationsGallery<CustomInspirationsGalleryLayoutType>;

      try {
        updatedGallery = await (
          dispatch(
            tagImageModalActions.updateGallery({
              shopId,
              galleryId: gallery.id,
              payload: { images: updatedGalleryImages },
            })
          ) as any
        ).unwrap();
      } catch (error) {
        console.error(error);
      }

      setIsSaveInProgress(false);

      return updatedGallery?.images?.find(newImage => image.id === newImage.id);
    },
    [
      gallery.id,
      gallery.images,
      shopId,
      selectedImageIndex,
      isValid,
      hasNextImage,
      dispatch,
      onPaginationButtonClick,
      navigateToPreviewGallery,
    ]
  );

  const onApplyAiSuggestedTags = useCallback(
    async (image?: CustomInspirationsGalleryImage) => {
      const targetImage = image || selectedImage;

      if (!targetImage || !imageDraft) return;

      const updatedImage = {
        ...targetImage,
        tags: [
          ...targetImage.tags,
          ...imageDraft.aiSuggestedTags.map(tag => ({ ...tag, isAIDetected: true })),
        ],
        aiSuggestedTags: [],
      };

      await updateGalleryImage(updatedImage);
    },
    [imageDraft, selectedImage, updateGalleryImage]
  );

  const onSave = useCallback(async () => {
    if (!isValid) return;

    await updateGalleryImage(imageDraft);

    dispatch(tagImageModalActions.notifyIsDirty({ isDirty: false }));

    if (hasNextImage) {
      onPaginationButtonClick({ isNext: true })();
    } else {
      navigateToPreviewGallery();
    }

    if (gallery.galleryType === GalleryType.ShoppableBanner) {
      navigateToPreviewGallery();
    }
  }, [imageDraft, updateGalleryImage, gallery, isValid]);

  const resetDraftAndCallback = useCallback(async () => {
    closeModal();

    currentActionConfig?.cb();
    resetDraft();
  }, [resetDraft, closeModal, currentActionConfig]);

  const saveDraftAndCallback = useCallback(async () => {
    if (!isValid) return;

    const updatedImage = await updateGalleryImage(imageDraft);

    closeModal();

    currentActionConfig?.cb(updatedImage);
  }, [updateGalleryImage, imageDraft, isValid, closeModal, currentActionConfig]);

  return (
    <>
      <DialogModalWithPaginationStyled
        open
        onNextButtonClick={
          gallery.galleryType === GalleryType.CustomInspirations && hasNextImage
            ? onPaginationButtonClick({ isNext: true })
            : undefined
        }
        onBackButtonClick={
          gallery.galleryType === GalleryType.CustomInspirations && hasPreviousImage
            ? onPaginationButtonClick({ isNext: false })
            : undefined
        }
        onClose={navigateToPreviewGallery}
        disableArrowKeys
      >
        <DialogModalWithPaginationContentStyled>
          <TagImageModalHeaderStyled>
            <Typography variant={TypographyVariant.LargeMedium}>Image General Settings</Typography>
            <CloseIconStyled name={AvailableIcons.Close} onClick={navigateToPreviewGallery} />
          </TagImageModalHeaderStyled>
          <TagImageModalHeaderDividerStyled />
          <TagImageModalContentStyled>
            <ImageSettings
              galleryType={gallery.galleryType}
              image={imageDraft}
              isDirty={isDirty}
              isValid={isValid}
              failedToLoadImage={failedToLoadImage}
              errors={errors}
              onChange={onChange}
              onSave={onSave}
              resetDraft={resetDraft}
              removeTagById={removeTagById}
            />
            <TaggableImage
              image={imageDraft}
              onChange={onChange}
              shopId={shopId}
              dispatch={dispatch}
              onImageErrorStatusChange={onImageErrorStatusChange}
              removeTagById={removeTagById}
              showTags
              startImageTagsDetection={() =>
                triggerTheModalIfDirtyOrCallback(startImageTagsDetection, Action.Generate)
              }
              onApplyAiSuggestedTags={() =>
                triggerTheModalIfDirtyOrCallback(onApplyAiSuggestedTags, Action.AcceptTags)
              }
            />
          </TagImageModalContentStyled>
        </DialogModalWithPaginationContentStyled>
      </DialogModalWithPaginationStyled>

      <DirtyFormConfirmationDialog
        action={currentActionConfig?.action}
        close={closeModal}
        isOpened={showDirtyModal}
        isLoading={isSaveInProgress}
        onResetDraftAndProceed={resetDraftAndCallback}
        onSaveAndProceed={saveDraftAndCallback}
      />
    </>
  );
}
