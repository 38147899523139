import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ApiKeys } from 'src/services';
import { galleriesService } from 'src/services/src/service/galleries';

const apiKeysService = new ApiKeys();

export const editGalleryPageActions = {
  notifyIsDirty: createAction<{ isDirty: boolean }>('EditGalleryPage/NotifyIsDirty'),
  navigateTo: createAction<{ navigateTo: string }>('EditGalleryPage/NavigateTo'),
  resetCurrentGallery: createAction('EditGalleryPage/ResetCurrentGallery'),
  resetApiKeys: createAction('EditGalleryPage/ResetApiKeys'),
  getApiKeys: createAsyncThunk(
    'EditGalleryPage/GetApiKeys',
    async ({ shopId }: { shopId: number }, { rejectWithValue }) => {
      try {
        const { apiKeys } = await apiKeysService.getApiKeys(shopId);
        return apiKeys;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getGallery: createAsyncThunk(
    'EditGalleryPage/GetGallery',
    async (
      { shopId, galleryId, galleryType }: Parameters<typeof galleriesService.getGallery>[0],
      { rejectWithValue }
    ) => {
      try {
        return await galleriesService.getGallery({ shopId, galleryId, galleryType });
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  updateGallery: createAsyncThunk(
    'EditGalleryPage/UpdateGallery',
    async (
      {
        shopId,
        galleryId,
        payload,
      }: Parameters<typeof galleriesService.updateCustomInspirationsGallery>[0],
      { rejectWithValue }
    ) => {
      try {
        return await galleriesService.updateCustomInspirationsGallery({
          shopId,
          galleryId,
          payload,
        });
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  startTagsDetection: createAsyncThunk(
    'EditGalleryPage/StartTagsDetection',
    async (
      { shopId, galleryId }: Parameters<typeof galleriesService.startGalleryTagsDetection>[0],
      { rejectWithValue }
    ) => {
      try {
        await galleriesService.startGalleryTagsDetection({ shopId, galleryId });
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
