import React, { useCallback } from 'react';
import { RoutedComponentProps } from 'src/app-routes';
import { useAppSelector } from 'src/hooks';
import { emailMarketingCampaignsActions } from 'src/components-bl/EmailMarketing/EmailMarketingCampaignsActions';
import { generatePath } from 'react-router';
import { EmailMarketingCampaignsTablePage } from 'src/components-bl/EmailMarketing/EmailMarketingCampaignsTablePage/EmailMarketingCampaignsTablePage';
import { Dispatch } from 'src/components-bl';
interface EmailMarketingTableContainerProps extends RoutedComponentProps {
  shopId: number;
  dispatch: Dispatch;
}

export const EmailMarketingTableContainer = ({
  shopId,
  permittedRouteMap,
  dispatch,
}: EmailMarketingTableContainerProps): JSX.Element => {
  const campaigns = useAppSelector(state => state.campaigns.campaigns);

  const navigateToCreateCampaign = () => {
    if (!permittedRouteMap.createCampaign) return;

    dispatch(
      emailMarketingCampaignsActions.navigateTo({
        navigateTo: generatePath(permittedRouteMap.createCampaign.path, { shopId }),
      })
    );
  };

  const navigateToEditCampaign = useCallback(
    (campaignId: string) => {
      if (!permittedRouteMap.editCampaign) return;

      dispatch(
        emailMarketingCampaignsActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.editCampaign.path, { shopId, campaignId }),
        })
      );
    },
    [dispatch, shopId, permittedRouteMap]
  );

  return (
    <>
      <EmailMarketingCampaignsTablePage
        dispatch={dispatch}
        shopId={shopId}
        permittedRouteMap={permittedRouteMap}
        campaigns={campaigns}
        navigateToCreateCampaign={navigateToCreateCampaign}
        navigateToEditCampaign={navigateToEditCampaign}
      />
    </>
  );
};
