import styled from '@emotion/styled';

export const BlurContainerStyled = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.palette.custom['gray-70']};
  justify-content: center;
  backdrop-filter: blur(50px);
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  aspect-ratio: 1 / 1;
`;

export const ImageContainerStyled = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  justify-content: center;
  max-width: 100%;
  aspect-ratio: 1 / 1;
`;

export const ImageNotFoundWrapper = styled.div`
  width: 100%;
  height: 350px;
`;

export const ImageStyled = styled.img<{ isLandscape: boolean }>`
  border-radius: 4px;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  ${({ isLandscape }) => {
    if (!isLandscape) {
      return `
        height: 100%;
        object-fit: contain;
      `;
    }

    return '';
  }}

  max-width: 100%;

  transition: width 0.1s;
`;
