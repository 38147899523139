import React, { useEffect } from 'react';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/hooks';
import { LexiconList, LexiconListProps } from '../../../../../components-bl';
import { lexiconsContainerActions } from '../LexiconsContainer.actions';

export type ConnectedLexiconListProps = Pick<LexiconListProps, 'categoriesRoute'>;

export const ConnectedLexiconList = ({
  categoriesRoute,
}: ConnectedLexiconListProps): JSX.Element => {
  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const lexiconLanguages = useAppSelector(state => state.lexicon.allLexiconSupportedLanguages);
  const lexiconsMetadataList = useAppSelector(state => state.lexicon.lexiconsMetadataList);

  const dispatch = useDispatch() as ThunkDispatch<any, any, any>;

  useEffect(() => {
    if (!shopId || !lexiconLanguages) {
      return;
    }

    dispatch(lexiconsContainerActions.getLexiconsMetadataList({ shopId, lexiconLanguages }));
  }, [shopId]);

  return (
    <LexiconList
      shopId={shopId}
      lexiconMetadataList={lexiconsMetadataList}
      categoriesRoute={categoriesRoute}
      dispatch={dispatch}
    />
  );
};
