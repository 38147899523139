/* eslint-disable prefer-rest-params */
import { useEffect } from 'react';
import { UserTypes } from '../../services';

interface UserGuidingWindow extends Window {
  userGuiding?: {
    identify: (userId: string, user?: Partial<UserTypes.User>) => void;
  };
}

declare const window: UserGuidingWindow;

const shouldEnableUserGuiding = process.env.REACT_APP_INJECT_USER_GUIDING === 'true';

export const useInjectUserGuiding = ({ user }: { user: UserTypes.User }): void => {
  if (!shouldEnableUserGuiding) return;

  function injectUserGuiding(g: any, u: any, i: any, d: any, e: any, s: any) {
    g[e] = g[e] || [];
    const f = u.getElementsByTagName(i)[0];
    const k = u.createElement(i);
    k.async = true;
    k.src = 'https://static.userguiding.com/media/user-guiding-' + s + '-embedded.js';
    f.parentNode.insertBefore(k, f);
    if (g[d]) return;
    const ug: any = (g[d] = { q: [] });
    ug.c = function (n) {
      return function () {
        ug.q.push([n, arguments]);
      };
    };
    const m = [
      'previewGuide',
      'finishPreview',
      'track',
      'identify',
      'hideChecklist',
      'launchChecklist',
    ];
    for (let j = 0; j < m.length; j += 1) {
      ug[m[j]] = ug.c(m[j]);
    }
  }

  useEffect(() => {
    injectUserGuiding(
      window,
      document,
      'script',
      'userGuiding',
      'userGuidingLayer',
      '9S895634RI4ID'
    );
  }, [user.userId]);

  const { userId, lastVisited, ...restUser } = user;

  useEffect(() => {
    window.userGuiding.identify(userId.toString(), restUser);
  }, [user]);
};
