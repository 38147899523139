import { parseISO } from 'date-fns';
import { Campaign } from '../../../../components-bl/EmailMarketing/types/campaign';
import { CampaignApi } from './types';
import { orderBy } from 'lodash';

export class CampaignsMapper {
  static mapCampaign(campaign: CampaignApi): Campaign {
    const mappedUpdatedAt = parseISO(campaign.updatedAt);

    return { ...campaign, updatedAt: mappedUpdatedAt };
  }

  static mapCampaignResponse(responseBody: CampaignApi[]): Campaign[] {
    const campaigns: Campaign[] = [];
    responseBody.forEach(campaign => {
      const mappedCampaign = this.mapCampaign(campaign);
      campaigns.push(mappedCampaign);
    });
    return orderBy(campaigns, ['updatedAt'], ['desc']);
  }
}
