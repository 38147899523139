import React, { useCallback } from 'react';
import { Rule, RuleGroup } from 'src/components-bl/Collections';
import { AvailableIcons } from 'src/components-dummy';
import { CollectionRulesList } from '../CollectionRulesList';
import { ParseJoiValidateResponse } from '../../../../../utils';
import {
  OnDeleteRuleGroupCallback,
  OnAddRuleCallback,
  OnDeleteRuleCallback,
  OnEditRuleCallback,
} from '../types';
import { DeleteRuleGroupButtonStyled } from './DeleteRuleGroupButton.styles';
import { ShopDataField } from '../../../../../services';

interface CollectionRulesRuleGroupProps {
  shopId: number;
  ruleGroup: RuleGroup;
  index: number;
  onDeleteRuleGroup: OnDeleteRuleGroupCallback;
  onAddRule: OnAddRuleCallback;
  onDeleteRule: OnDeleteRuleCallback;
  onEditRule: OnEditRuleCallback;
  dataFields: ShopDataField[] | undefined;
  errors: ParseJoiValidateResponse<{ ruleGroups: RuleGroup[] }>;
  isReadOnly?: boolean;
}

export const CollectionRuleGroup: React.FC<CollectionRulesRuleGroupProps> = ({
  ruleGroup,
  index,
  onDeleteRuleGroup,
  onAddRule,
  onDeleteRule,
  onEditRule,
  dataFields,
  shopId,
  errors,
  isReadOnly = false,
}) => {
  const addRule = () => {
    if (!isReadOnly) {
      onAddRule(ruleGroup.id);
    }
  };

  const deleteRule = (ruleIndex: number) => {
    if (!isReadOnly) {
      onDeleteRule(ruleGroup.id, ruleIndex);
    }
  };

  const editRule = (ruleIndex: number, rule: Rule) => {
    if (!isReadOnly) {
      onEditRule(ruleGroup.id, ruleIndex, rule);
    }
  };

  const deleteRuleGroup = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onDeleteRuleGroup(ruleGroup.id);
    },
    [ruleGroup.id, onDeleteRuleGroup]
  );

  return (
    <>
      {index > 0 && (
        <DeleteRuleGroupButtonStyled
          variant='primary'
          endIcon={AvailableIcons.CloseLight}
          onClick={deleteRuleGroup}
          disabled={isReadOnly}
        >
          OR
        </DeleteRuleGroupButtonStyled>
      )}
      <CollectionRulesList
        shopId={shopId}
        rules={ruleGroup.rules}
        ruleGroupIndex={index}
        ruleGroupId={ruleGroup.id}
        onDeleteRuleGroup={onDeleteRuleGroup}
        addRule={addRule}
        deleteRule={deleteRule}
        onChange={editRule}
        dataFields={dataFields}
        errors={errors}
        isReadOnly={isReadOnly}
      />
    </>
  );
};
