import React from 'react';
import { Button, Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import {
  CampaignsEmptyTableContentStyled,
  CampaignsEmptyTableStyled,
  CreateCampaignsActionButtonsWrapperStyled,
  CreateCampaignsSubTitleStyled,
  CreateCampaignsWrapperStyled,
  BlankImageContainer,
} from './CampaignsTable.styles';

interface CampaignsEmptyTableProps {
  navigateToCreateCampaignPage: () => void;
}

const learnMoreLink = 'https://support.syte.ai/space/SP/2735768893/ER.+Overview';

export const CampaignsEmptyTable = ({
  navigateToCreateCampaignPage,
}: CampaignsEmptyTableProps): JSX.Element => {
  const onLearnMoreClick = () => {
    window.open(learnMoreLink, '_blank');
  };
  return (
    <CampaignsEmptyTableStyled>
      <CampaignsEmptyTableContentStyled>
        <BlankImageContainer />
        <CreateCampaignsWrapperStyled>
          <Typography variant={TypographyVariant.LargeBold} type={TypographyType.Body}>
            Visualize Success with Email Marketing
          </Typography>

          <CreateCampaignsSubTitleStyled
            variant={TypographyVariant.MediumRegular}
            type={TypographyType.Paragraph}
          >
            Convert your customers with visually compelling product recommendations across your
            emails
          </CreateCampaignsSubTitleStyled>
          <CreateCampaignsActionButtonsWrapperStyled>
            <Button onClick={onLearnMoreClick} variant='secondary'>
              Learn More
            </Button>
            <Button onClick={navigateToCreateCampaignPage} variant='primary'>
              Get Started
            </Button>
          </CreateCampaignsActionButtonsWrapperStyled>
        </CreateCampaignsWrapperStyled>
      </CampaignsEmptyTableContentStyled>
    </CampaignsEmptyTableStyled>
  );
};
