import styled from '@emotion/styled';
import { Typography } from 'src/components-dummy';
import { ImageGrid } from 'src/components-dummy';

export const SkeletonBanner = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(auto, 100%));
  justify-items: center;
  justify-content: center;
  padding: 0 4px;
  gap: 16px;
`;

export const ContentHeaderRowStyled = styled.div`
  padding: 32px 0 24px;
  display: flex;
  justify-content: flex-end;
`;

export const BannerContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 36px;
`;

export const BannerWrapper = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BannerGridItemStyled = styled(ImageGrid)`
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
`;

export const BannerItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const BannerTitleStyled = styled(Typography)`
  margin-bottom: 16px;
  font-size: 20px;
  text-align: left;
`;
