import React from 'react';
import { TableV2, TableV2InstanceProps } from 'src/components-dummy/TableV2';

interface Props {
  headers: TableV2InstanceProps<any>['headerGroups'][0]['headers'];
}

export const CampaignsTableHeaderColumns: React.FC<Props> = ({ headers }) => {
  const renderHeaderColumn = ({ column }: { column: any }) => {
    return <TableV2.HeadRowCellText>{column.render('header')}</TableV2.HeadRowCellText>;
  };

  return (
    <>
      {headers.map(column => (
        <TableV2.HeadRow {...column.getHeaderProps()} key={column.id}>
          {renderHeaderColumn({ column })}
        </TableV2.HeadRow>
      ))}
    </>
  );
};
