import React from 'react';
import { StyledSkeleton } from './CampaignsTableSkeleton.styles';

export const EmailMarketingCampaignsTableSkeleton = (): JSX.Element => (
  <>
    <StyledSkeleton height={60} variant='rounded' />
    <StyledSkeleton height={60} variant='rounded' />
    <StyledSkeleton height={60} variant='rounded' />
  </>
);
