import React, { useCallback } from 'react';
import { FieldSource, fieldSourceDropDownOptions } from './constants';
import { Rule, RuleGroup } from '../../../types';
import { Select, SelectType, MenuItem, SelectOnChangeEvent } from '../../../../../components-dummy';
import { ParseJoiValidateResponse } from '../../../../../utils';

interface FieldSourceDropdownProps {
  data: Rule;
  onChange: (rule: Rule) => void;
  errors: ParseJoiValidateResponse<{ ruleGroups: RuleGroup[] }>;
  isReadOnly?: boolean;
}

export const FieldSourceDropdown: React.FC<FieldSourceDropdownProps> = ({
  data,
  onChange,
  errors,
  isReadOnly = false,
}) => {
  const { fieldSource } = data;

  const onFieldSourceChange = useCallback(
    (selectedFieldSource?: FieldSource) => {
      onChange({ ...data, fieldSource: selectedFieldSource });
    },
    [onChange]
  );

  const handleChange: SelectOnChangeEvent = event => {
    onFieldSourceChange(event.target.value as FieldSource);
  };

  return (
    <Select
      type={SelectType.Menu}
      value={fieldSource}
      onChange={handleChange}
      className='syte-collection-rule-select'
      isError={!!errors?.fieldSource}
      disabled={isReadOnly}
    >
      {fieldSourceDropDownOptions.map(
        (option: { value: string; text: string; disabled?: boolean }) => (
          <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
            {option.text}
          </MenuItem>
        )
      )}
    </Select>
  );
};
