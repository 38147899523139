import { GalleryType } from 'src/services';

export const learnMoreLink = 'https://support.syte.ai';

export const galleryTypeTranslations: Record<GalleryType, string> = {
  [GalleryType.InspirationalImage]: 'Visual AI Driven (API)',
  [GalleryType.CustomInspirations]: 'Custom Inspirational Gallery',
  [GalleryType.ShoppableBanner]: 'Shoppable Banner',
};

export const MAX_ALLOWED_IMAGES = 250;
