import React, { useCallback } from 'react';
import {
  Typography,
  TypographyVariant,
  TypographyType,
  Accordion,
  AvailableIcons,
} from 'src/components-dummy';
import {
  AccordionStyled,
  BackButtonStyled,
  SideBarHeader,
  SideBarStyled,
  StyledSkeleton,
} from './AccordionSideBar.styles';
import { ExpandableSectionTrigger } from './components/ExpandableSectionTrigger';

function LoadingSkeleton() {
  return (
    <>
      <StyledSkeleton height={40} variant='rounded' />
      <StyledSkeleton height={40} variant='rounded' />
      <StyledSkeleton height={40} variant='rounded' />
    </>
  );
}

interface AccordionSideBarProps {
  header: string;
  navigateToList?: () => void;
  sections: Array<{
    id: string;
    title: string;
    content?: React.ReactNode;
    isExpanded: boolean;
    isDisabled?: boolean;
  }>;
  isLoading: boolean;
  setExpendedSection: React.Dispatch<React.SetStateAction<string | null>>;
  disabled: boolean;
  children?: React.ReactNode;
}

export const AccordionSideBar = ({
  header,
  sections,
  isLoading,
  navigateToList,
  setExpendedSection,
  disabled,
  children,
}: AccordionSideBarProps): JSX.Element => {
  const onExpendSelectionChange = useCallback(
    (section: string[]): void => {
      const newSection = section?.[0] || null;
      const selectedSection = sections.find(sec => sec.id === newSection);

      if (selectedSection?.isDisabled) {
        return;
      }
      setExpendedSection(newSection);
    },
    [setExpendedSection]
  );

  return (
    <SideBarStyled>
      <SideBarHeader>
        <Typography variant={TypographyVariant.LargeBold} type={TypographyType.Body}>
          {header}
        </Typography>
        <BackButtonStyled
          variant='tertiary'
          startIcon={AvailableIcons.ChevronLeft}
          onClick={navigateToList}
        >
          Back
        </BackButtonStyled>
      </SideBarHeader>
      {children}
      {isLoading ? (
        <LoadingSkeleton />
      ) : (
        <AccordionStyled
          onSelectionChanged={onExpendSelectionChange}
          expandedIds={sections.filter(section => section.isExpanded).map(section => section.id)}
          disabled={disabled}
        >
          {sections.map(({ id, title, content, isExpanded, isDisabled }) => (
            <Accordion.Item key={id} id={id} observeHeight>
              <Accordion.Item.Header>
                <ExpandableSectionTrigger
                  title={title}
                  isExpanded={isExpanded}
                  isDisabled={disabled || isDisabled}
                />
              </Accordion.Item.Header>
              <Accordion.Item.Content>{content}</Accordion.Item.Content>
            </Accordion.Item>
          ))}
        </AccordionStyled>
      )}
    </SideBarStyled>
  );
};
