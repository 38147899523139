import React, { useCallback, useMemo } from 'react';
import { Dispatch } from 'src/components-bl/types';
import { SyteProductPlacement } from 'src/services/src/service/types/syte-products';
import { SettingsSection } from '../../../../CustomInspirations/EditCustomInspirationsGalleryPage/components/EditGallerySideBar/components';
import { URLSection } from './components/URLSection';
import { ShoppableBannerDraft } from '../../types';
import { ValidateArguments } from 'src/hooks';
import { ParseJoiValidateResponse } from 'src/utils';
import { ImplementationSection } from '../../../../CustomInspirations/EditCustomInspirationsGalleryPage/components/EditGallerySideBar/components';
import { AccordionSideBar } from 'src/components-dummy/AccordionSideBar';
import { useAccordionExpansion } from 'src/components-dummy/AccordionSideBar/hooks/useAccordionExpansion';

export enum ExpendableSection {
  Settings = 'settings',
  BannerLink = 'banner_link',
  Implementation = 'implementation',
}

const guidelinesLink = 'https://support.syte.ai/space/ET/3970400258/Shoppable+Banner';

interface EditBannerSideBarProps {
  gallery: ShoppableBannerDraft | undefined;
  galleryId: string;
  onChange: (partialDraft: Partial<ShoppableBannerDraft>) => void;
  dispatch: Dispatch;
  navigateToList: () => void;
  errors: ParseJoiValidateResponse<Pick<ShoppableBannerDraft, 'bannerLink' | 'bannerTextLink'>>;
  validate: (
    args: ValidateArguments<Pick<ShoppableBannerDraft, 'bannerLink' | 'bannerTextLink'>>
  ) => void;
}

export const EditBannerSideBar = ({
  gallery,
  onChange,
  galleryId,
  dispatch,
  navigateToList,
  errors,
  validate,
}: EditBannerSideBarProps): JSX.Element => {
  const { expandedSection, handleSectionToggle } = useAccordionExpansion(null);
  const isLoading = useMemo(() => !gallery, [gallery]);

  const isEditEnabled = useMemo(
    () => gallery?.images && gallery.images.length > 0,
    [gallery?.images]
  );

  const onPlacementChange = useCallback(
    (newPlacement: SyteProductPlacement): void => {
      onChange({ placement: newPlacement });
    },
    [onChange]
  );

  return (
    <AccordionSideBar
      header='Shoppable Banner'
      isLoading={isLoading}
      navigateToList={navigateToList}
      setExpendedSection={handleSectionToggle}
      disabled={!isEditEnabled}
      sections={[
        {
          id: ExpendableSection.Settings,
          title: 'Banner Settings',
          content: <SettingsSection gallery={gallery} onChange={onChange} />,
          isExpanded: expandedSection === ExpendableSection.Settings,
        },
        {
          id: ExpendableSection.BannerLink,
          title: 'Banner Settings',
          content: (
            <URLSection
              bannerLink={gallery?.bannerLink}
              bannerTextLink={gallery?.bannerTextLink}
              onChange={onChange}
              validate={validate}
              errors={errors}
            />
          ),
          isExpanded: expandedSection === ExpendableSection.BannerLink,
        },
        {
          id: ExpendableSection.Implementation,
          title: 'Gallery Implementation',
          content: (
            <ImplementationSection
              galleryId={galleryId}
              onPlacementChange={onPlacementChange}
              galleryTitle={gallery?.displayTitle}
              placement={gallery?.placement}
              dispatch={dispatch}
              guidelinesLink={guidelinesLink}
              typeName='banner'
              className='syte-shoppable-banner'
            />
          ),
          isExpanded: expandedSection === ExpendableSection.Implementation,
        },
      ]}
    />
  );
};
