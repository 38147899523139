import { useState, useCallback } from 'react';

export const useAccordionExpansion = (initialExpandedSection: string | null) => {
  const [expandedSection, setExpandedSection] = useState<string | null>(initialExpandedSection);

  const handleSectionToggle = useCallback((sectionId: string) => {
    setExpandedSection(prevExpandedSection =>
      prevExpandedSection === sectionId ? null : sectionId
    );
  }, []);

  return {
    expandedSection,
    handleSectionToggle,
  };
};
