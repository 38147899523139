import React from 'react';
import { RoutedComponentProps } from 'src/app-routes';
import { AddImagesModalForm } from 'src/components-bl/Galleries';
import { useAppDispatch } from 'src/hooks';
import { useGallerySelector } from '../useGallerySelector';

interface AddImagesContainerProps extends RoutedComponentProps {
  shopId: number;
  allowedToAddImages: number | undefined;
}

export const AddImagesContainer = ({
  shopId,
  permittedRouteMap,
  allowedToAddImages,
}: AddImagesContainerProps): JSX.Element | null => {
  const dispatch = useAppDispatch();

  const gallery = useGallerySelector();
  return gallery ? (
    <AddImagesModalForm
      shopId={shopId}
      dispatch={dispatch}
      permittedRouteMap={permittedRouteMap}
      gallery={gallery}
      allowedToAddImages={allowedToAddImages}
    />
  ) : null;
};
