import React from 'react';
import { AppRoute, AppSwitch, RoutedComponentProps } from 'src/app-routes';
import { Redirect, generatePath } from 'react-router';
import { GalleriesTableContainer } from './components/GalleriesTableContainer';
import {
  EditInspirationalImagesGalleryContainer,
  ViewInspirationalImagesGalleryContainer,
} from './components/InspirationalImages';
import { EditCustomInspirationsContainer } from './components/CustomInspirations';

type GalleriesContainerProps = RoutedComponentProps & { shopId: number | undefined };

export const GalleriesContainer = ({
  shopId,
  permittedRouteMap,
}: GalleriesContainerProps): JSX.Element | null => {
  return shopId ? (
    <AppSwitch>
      <AppRoute
        route={permittedRouteMap.galleriesList}
        Component={GalleriesTableContainer}
        componentProps={{ permittedRouteMap, shopId }}
        exact={false}
      />
      <AppRoute
        route={permittedRouteMap.editInspirationalGallery}
        Component={EditInspirationalImagesGalleryContainer}
        componentProps={{ permittedRouteMap, shopId }}
        exact
      />
      <AppRoute
        route={permittedRouteMap.viewInspirationalGallery}
        Component={ViewInspirationalImagesGalleryContainer}
        componentProps={{ permittedRouteMap, shopId }}
        exact
      />
      <AppRoute
        route={permittedRouteMap.editCustomInspirationsGallery}
        Component={EditCustomInspirationsContainer}
        componentProps={{ permittedRouteMap, shopId }}
        exact={false}
      />
      <AppRoute
        route={permittedRouteMap.editShoppableBanner}
        Component={EditCustomInspirationsContainer}
        componentProps={{ permittedRouteMap, shopId }}
        exact={false}
      />
      {permittedRouteMap.galleriesList && shopId && (
        <Redirect to={generatePath(permittedRouteMap.galleriesList.path, { shopId })} />
      )}
    </AppSwitch>
  ) : null;
};
