import Joi from 'joi';
import { CampaignStrategy } from '../types/campaign-strategy';
import { getStringEnumValues } from 'src/utils';

const widgetMakerSchema = Joi.object({
  font: Joi.string().required().default('sans-serif'),
  fontSize: Joi.string().required().default('12px'),
  width: Joi.number().required().default(500),
  numberOfRows: Joi.number().required().default(2),
  itemsPerRow: Joi.number().required().default(4),
  textColor: Joi.string().required().default('black'),
  textBackgroundColor: Joi.string().required().default('white'),
  fields: Joi.array().items(Joi.object()).required(),
  title: Joi.string().optional(),
  CTAButtonText: Joi.string().optional(),
  variables: Joi.object().optional(),

  UTMParameters: Joi.object()
    .pattern(Joi.string().min(1).required(), Joi.string().min(1).required())
    .custom(value => {
      if (Object.keys(value).length === 0 || Object.values(value).length === 0) {
        throw new Error('Tracking parameters cannot be empty.');
      }

      Object.keys(value).forEach(key => {
        if (!key.trim()) {
          throw new Error('Invalid tracking parameter key');
        }
      });

      Object.values(value).forEach((val: string) => {
        if (!val.trim()) {
          throw new Error('Invalid tracking parameter value');
        }
      });

      return value;
    }, 'Validate UTM Parameters')
    .messages({
      'any.invalid': 'Invalid tracking parameters',
      'any.custom': 'Tracking parameter is invalid',
      'string.empty': 'Tracking parameter can not be empty',
    }),
});

export const campaignValidationSchema = {
  campaignName: Joi.string().required().trim().min(1).max(50).messages({
    'string.empty': 'Campaign name can not be empty',
    'string.min': 'Name should be at least 1 character long',
    'string.max': 'Name should be less then 50 characters long',
  }),

  strategy: Joi.string()
    .required()
    .valid(...getStringEnumValues(CampaignStrategy))
    .messages({
      'any.required': 'Please select a recommendation type for this campaign',
    }),

  collectionId: Joi.string()
    .optional()
    .when('strategy', {
      is: CampaignStrategy.Collections,
      then: Joi.required().messages({
        'any.required': 'Please select a collection for this campaign',
      }),
      otherwise: Joi.optional(),
    }),

  widgetMaker: widgetMakerSchema,
};
