import { GalleryType, ShoppableBanner } from 'src/services';
import { ShoppableBannerDraft } from './types';
import { BannerLayout } from 'src/app-state-types/reducer-state-types/discovery-banner';

export class GalleryMapper {
  static mapGalleryToDraft(gallery?: ShoppableBanner): ShoppableBannerDraft | undefined {
    return gallery
      ? {
          displayTitle: gallery.displayTitle,
          layout: gallery.layout as unknown as BannerLayout,
          images: gallery.images,
          placement: gallery.placement,
          showSimilarProductWhenUnavailable: gallery.showSimilarProductWhenUnavailable,
          isDetectingTags: gallery.isDetectingTags,
          galleryType: GalleryType.ShoppableBanner,
          bannerLink: gallery.bannerLink,
          bannerTextLink: gallery.bannerTextLink,
        }
      : undefined;
  }
}
