import React from 'react';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { GridLayoutSectionStyled } from 'src/components-bl/Galleries/CustomInspirations/EditCustomInspirationsGalleryPage/components/EditGallerySideBar/components/GridLayoutSection/GridLayoutSection.styles';
import { LayoutRowStyled } from './LayoutSection.styles';
import { SliderRow } from 'src/components-dummy/SliderRow';
import { CampaignDraft } from 'src/components-bl/EmailMarketing/types/campaign-draft';
import { WidgetMaker } from 'src/components-bl/EmailMarketing/types/widget-maker';

interface LayoutSectionProps {
  widgetMaker: Partial<WidgetMaker>;
  onChange: (campaign: CampaignDraft) => void;
}

export const LayoutSection = ({ widgetMaker, onChange }: LayoutSectionProps): JSX.Element => {
  const handleNumberOfRowsChange = (newNumberOfRows: number) => {
    onChange({
      widgetMaker: {
        ...widgetMaker,
        numberOfRows: newNumberOfRows,
      },
    });
  };

  const handleItemsPerRowChange = (newItemsPerRow: number) => {
    onChange({
      widgetMaker: {
        ...widgetMaker,
        itemsPerRow: newItemsPerRow,
      },
    });
  };

  return (
    <GridLayoutSectionStyled>
      <Typography type={TypographyType.Paragraph} variant={TypographyVariant.MediumRegular}>
        Number of rows
      </Typography>
      <SliderRow
        key='numberOfRows'
        value={widgetMaker.numberOfRows}
        onChange={handleNumberOfRowsChange}
        min={1}
        max={4}
      />
      <LayoutRowStyled>
        <Typography type={TypographyType.Paragraph} variant={TypographyVariant.MediumRegular}>
          Number of columns
        </Typography>
        <SliderRow
          key='itemsPerRow'
          value={widgetMaker.itemsPerRow}
          onChange={handleItemsPerRowChange}
          min={1}
          max={4}
        />
      </LayoutRowStyled>
    </GridLayoutSectionStyled>
  );
};
