import { DomainEntityPath } from 'src/services';

export const domainToDisplayNameMap: Record<DomainEntityPath, string> = {
  [DomainEntityPath.Account]: 'Account',
  [DomainEntityPath.AIModels]: 'AI Models',
  [DomainEntityPath.AugmentedSearch]: 'Augmented Search',
  [DomainEntityPath.AugmentedSearchFilterSet]: 'Filter Set',
  [DomainEntityPath.AugmentedSearchRanking]: 'Augmented Search Ranking',
  [DomainEntityPath.AugmentedSearchGeneralSettings]: 'Augmented Search Settings',
  [DomainEntityPath.AugmentedSearchTuning]: 'Search Relevancy Tuning',
  [DomainEntityPath.BoughtTogether]: 'Bought Together',
  [DomainEntityPath.BoughtTogetherSettings]: 'Bought Together Settings',
  [DomainEntityPath.CollectionGlobalRanking]: 'Collection Global Ranking',
  [DomainEntityPath.CollectionRules]: 'Collection Rules',
  [DomainEntityPath.Collections]: 'Collections',
  [DomainEntityPath.CollectionSettings]: 'Collection Settings',
  [DomainEntityPath.CollectionRanking]: 'Collection Ranking',
  [DomainEntityPath.DataFields]: 'Data Fields',
  [DomainEntityPath.DeepTagReport]: 'Deep Tag Report',
  [DomainEntityPath.Dictionaries]: 'Dictionaries',
  [DomainEntityPath.DiscoveryBanner]: 'Discovery Banner',
  [DomainEntityPath.DiscoveryBannerSettings]: 'Discovery Banner Settings',
  [DomainEntityPath.DiscoveryButton]: 'Discovery Button',
  [DomainEntityPath.DiscoveryButtonRanking]: 'Discovery Button Ranking',
  [DomainEntityPath.EnableUiTest]: 'Enable Ui Test',
  [DomainEntityPath.Experiment]: 'Experiment',
  [DomainEntityPath.ExperimentVariant]: 'Experiment Variant',
  [DomainEntityPath.FeatureToggles]: 'Feature Toggles',
  [DomainEntityPath.FiltersSets]: 'Filters Sets',
  [DomainEntityPath.FiltersSetsFilter]: 'Filters Sets Filter',
  [DomainEntityPath.General]: 'General',
  [DomainEntityPath.Lexicon]: 'Lexicon',
  [DomainEntityPath.MerchandisingRules]: 'Merchandising Rules',
  [DomainEntityPath.Personalization]: 'Personalization',
  [DomainEntityPath.PersonalizationRanking]: 'Personalization Ranking',
  [DomainEntityPath.PersonalizationSettings]: 'Personalization Settings',
  [DomainEntityPath.ProductTranslationsCollectionsTitles]:
    'Product Translations Collections Titles',
  [DomainEntityPath.RecEnginesGeneralSettings]: 'Rec Engines General Settings',
  [DomainEntityPath.RecentlyViewed]: 'Recently Viewed',
  [DomainEntityPath.RecentlyViewedRanking]: 'Recently Viewed Ranking',
  [DomainEntityPath.RecentlyViewedSettings]: 'Recently Viewed Settings',
  [DomainEntityPath.Shop]: 'Shop',
  [DomainEntityPath.ShopCatalogs]: 'Shop Catalogs',
  [DomainEntityPath.ShopTheLook]: 'Shop The Look',
  [DomainEntityPath.ShopTheLookRanking]: 'Shop The Look Ranking',
  [DomainEntityPath.ShopTheLookSettings]: 'Shop The Look Settings',
  [DomainEntityPath.SimilarItems]: 'Similar Items',
  [DomainEntityPath.SimilarItemsRanking]: 'Similar Items Ranking',
  [DomainEntityPath.SimilarItemsSettings]: 'Similar Items Settings',
  [DomainEntityPath.Stories]: 'Stories',
  [DomainEntityPath.StoriesGeneralSettings]: 'Stories General Settings',
  [DomainEntityPath.UiTemplates]: 'Ui Templates',
  [DomainEntityPath.UseKnn]: 'Use OpenSearch KNN',
  [DomainEntityPath.User]: 'User',
  [DomainEntityPath.UserLocation]: 'User Location',
  [DomainEntityPath.Versions]: 'Versions',
  [DomainEntityPath.VisualSearch]: 'Visual Search',
  [DomainEntityPath.VisualSearchRanking]: 'Visual Search Ranking',
  [DomainEntityPath.ResultsModal]: 'Results Modal',
  [DomainEntityPath.CustomInspirationsGallery]: 'Custom Inspirations Gallery',
  [DomainEntityPath.EmailMarketingCampaigns]: 'Email Marketing Campaigns',
};
