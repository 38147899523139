import styled from '@emotion/styled';
import { Icon, Typography } from 'src/components-dummy';
import { LinkButton } from '../../../../../../../components-dummy/LinkButton';
import { Section } from '../../CampaignSideBar.styles';

export const TrackingParametersSectionStyled = styled(Section)`
  max-height: 350px;
  overflow-y: auto;
`;

export const TextStyled = styled(Typography)`
  font-size: 10px;
  margin-bottom: 4px;
  width: 70%;
  display: block;
`;

export const TrackingParametersWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputsRowStyled = styled.div`
  display: flex;
  gap: 8px;
`;

export const IconWrapperStyled = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

export const IconStyled = styled(Icon)`
  path {
    stroke: ${({ theme }) => theme.palette.custom['gray-20']};
  }
`;

export const OperatorText = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-20']};
  display: flex;
  align-items: center;
`;

export const LinkButtonStyled = styled(LinkButton)`
  padding: 12px;
  width: fit-content;
`;

export const InputStyled = styled.div`
  width: 150px;
  & * {
    padding-right: unset !important;
  }
`;
