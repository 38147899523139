import { AxiosResponse } from 'axios';
import { ApiServiceBase } from '../api-service-base';
import { Campaign } from '../../../../components-bl/EmailMarketing/types/campaign';
import { CampaignStrategy } from '../../../../components-bl/EmailMarketing/types/campaign-strategy';
import { WidgetMaker } from '../../../../components-bl/EmailMarketing/types/widget-maker';
import { CampaignsMapper } from './email-marketing-campaigns.mapper';
import { CampaignApi } from './types';
import { CampaignDraft } from '../../../../components-bl/EmailMarketing/types/campaign-draft';

export class EmailMarketingCampaignsService extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getUrl({ shopId }: { shopId: number }): string {
    return `${this.serviceBaseUri}/${shopId}/email-marketing-campaigns`;
  }

  async getCampaigns({ shopId }: { shopId: number }): Promise<Campaign[]> {
    const url = this.getUrl({ shopId });
    const response: AxiosResponse<CampaignApi[]> = await this.httpService.get({
      url,
    });
    return CampaignsMapper.mapCampaignResponse(response.data);
  }

  async getCampaign({
    shopId,
    campaignId,
  }: {
    shopId: number;
    campaignId: string;
  }): Promise<Campaign> {
    const url = `${this.getUrl({ shopId })}/${campaignId}`;

    const response: AxiosResponse<CampaignApi> = await this.httpService.get({
      url,
    });
    return CampaignsMapper.mapCampaign(response.data);
  }

  async createCampaign({
    shopId,
    campaignName,
    strategy,
    collectionId,
    widgetMaker,
  }: {
    shopId: number;
    campaignName: string;
    strategy: CampaignStrategy;
    collectionId?: string;
    widgetMaker?: Partial<WidgetMaker>;
  }): Promise<Campaign> {
    const url = this.getUrl({ shopId });

    const response: AxiosResponse<CampaignApi> = await this.httpService.post({
      url,
      data: { campaignName, strategy, collectionId, widgetMaker },
    });

    return CampaignsMapper.mapCampaign(response.data);
  }

  async deleteCampaign({
    shopId,
    campaignId,
  }: {
    shopId: number;
    campaignId: string;
  }): Promise<boolean> {
    const url = `${this.getUrl({ shopId })}/${campaignId}`;
    const { status }: AxiosResponse = await this.httpService.delete({ url });

    return status === 204;
  }

  async updateCampaign({
    shopId,
    campaignId,
    payload,
  }: {
    shopId: number;
    campaignId: string;
    payload: CampaignDraft;
  }): Promise<Campaign> {
    const url = `${this.getUrl({ shopId })}/${campaignId}`;
    const response: AxiosResponse<CampaignApi> = await this.httpService.patch({
      url,
      data: payload,
    });
    return CampaignsMapper.mapCampaign(response.data);
  }
}

export const emailMarketingCampaignsService = new EmailMarketingCampaignsService();
