import React, { MouseEvent, useCallback, useState } from 'react';
import { Row } from 'react-table';
import {
  displayNameMap,
  tableColumns,
} from 'src/components-bl/EmailMarketing/EmailMarketingCampaignsTablePage/components/CampaignsTable/constants';
import { formatDateToRelative } from 'src/components-bl/helpers/format-date-to-relative';
import { TableBodyRowCellStyled } from 'src/components-dummy/TableV2/TableV2.style';
import { RowActionsMenu } from './RowActionsMenu';
import { CampaignsTableBodyRow } from '../../../types';
import { DeleteCampaignConfirmationDialog } from '../../DeleteCampaignConfirmationDialog';
import { TableV2 } from 'src/components-dummy';
import { emailMarketingCampaignsActions } from 'src/components-bl/EmailMarketing/EmailMarketingCampaignsActions';
import { format } from 'date-fns';
import { Dispatch } from 'src/components-bl';
import { CampaignNameStyled } from '../CampaignsTable.styles';

const getFormattedCurrentTime = () => {
  return format(new Date(), 'HH:mm:ss');
};

interface CampaignsTableRowProps {
  row: Row<CampaignsTableBodyRow>;
  onDeleteCampaign: (campaignId: string) => void;
  navigateToEditCampaign: (campaignId: string) => void;
  navigateToCreateCampaign: () => void;
  dispatch: Dispatch;
}

export const CampaignsTableRow = ({
  row,
  onDeleteCampaign,
  navigateToEditCampaign,
  navigateToCreateCampaign,
  dispatch,
}: CampaignsTableRowProps): JSX.Element => {
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);

  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | null>(null);

  const onMenuClicked = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setMenuAnchorElement(event.currentTarget);
    },
    [setMenuAnchorElement]
  );

  const onMenuClose = useCallback(() => {
    setMenuAnchorElement(null);
  }, [setMenuAnchorElement]);

  const onDeleteCampaignClick = useCallback(() => {
    setShowDeleteConfirmationDialog(true);
  }, [onMenuClose]);

  const onEditCampaignClick = useCallback(() => {
    navigateToEditCampaign(row.original.id);
  }, [row.original.id]);

  const onCloseDeleteDialog = useCallback(() => {
    setShowDeleteConfirmationDialog(false);
  }, []);

  const onDeleteCampaignConfirm = useCallback(() => {
    onDeleteCampaign(row.original.id);
    onCloseDeleteDialog();
  }, [row.original.id, onDeleteCampaign]);

  const onDuplicateCampaignClick = useCallback(() => {
    const campaignToDuplicate = {
      ...row.original,
      campaignName: `${row.original.campaignName} - (${getFormattedCurrentTime()}))`,
    };

    dispatch(
      emailMarketingCampaignsActions.setDuplicateCampaignDraft({
        campaign: campaignToDuplicate,
      })
    );

    navigateToCreateCampaign();
  }, [dispatch, row.original]);

  const renderCellContent = (propName: string, cellValue: any) => {
    if (propName === tableColumns.campaignName.accessor) {
      return (
        <CampaignNameStyled variant='primary' onClick={onEditCampaignClick} key={propName}>
          <TableV2.BodyRowCellText>{cellValue}</TableV2.BodyRowCellText>
        </CampaignNameStyled>
      );
    }

    if (propName === tableColumns.campaignStrategy.accessor) {
      return (
        <TableV2.BodyRowCellText>{displayNameMap[cellValue] || cellValue}</TableV2.BodyRowCellText>
      );
    }

    if (propName === tableColumns.lastEdit.accessor) {
      return <TableV2.BodyRowCellText>{formatDateToRelative(cellValue)}</TableV2.BodyRowCellText>;
    }

    if (propName === tableColumns.rowActions.accessor) {
      return (
        <RowActionsMenu
          menuAnchorElement={menuAnchorElement}
          onMenuClicked={onMenuClicked}
          onMenuClose={onMenuClose}
          onEditCampaignClick={onEditCampaignClick}
          onDeleteCampaignClick={onDeleteCampaignClick}
          onDuplicateCampaignClick={onDuplicateCampaignClick}
        />
      );
    }

    return cellValue;
  };

  return (
    <>
      {showDeleteConfirmationDialog && (
        <DeleteCampaignConfirmationDialog
          onCloseDeleteDialog={onCloseDeleteDialog}
          onDeleteConfirm={onDeleteCampaignConfirm}
          campaignName={row.original.campaignName}
        />
      )}
      <TableV2.BodyRow {...row.getRowProps()} key={row.original.id}>
        {row.cells.map(cell => {
          const propName = cell.column.id;
          const cellValue = cell.value;
          const cellContent = renderCellContent(propName, cellValue);
          return (
            <TableBodyRowCellStyled
              {...cell.getCellProps()}
              key={propName}
              width={cell.column.width as string}
              minWidth={cell.column.minWidth}
              data-id={`${row.original.campaignName}-${propName}`}
            >
              {cellContent}
            </TableBodyRowCellStyled>
          );
        })}
      </TableV2.BodyRow>
    </>
  );
};
