import React from 'react';
import { Accordion } from 'src/components-dummy';
import { CustomInspirationsGalleryImage } from 'src/services';
import { ParseJoiValidateResponse } from 'src/utils';
import { TextSection } from '../../../../../CustomInspirations/TagImageModal/components/ImageSettings/components/TextSection';
import {
  ExpandableOptionsStyled,
  ExpandableSectionTriggerStyled,
} from '../../../../../CustomInspirations/TagImageModal/components/ImageSettings/components/ExpandableOptions/ExpandableOptions.styled';
import {
  ExpandableSection,
  useExpandableOptions,
} from 'src/components-bl/Galleries/CustomInspirations/hooks/useExpandableOptions';
import { DEFAULT_BANNER_IMAGE } from './constants';
import { ExpandableSectionTrigger } from 'src/components-bl/Galleries/CustomInspirations/ExpandableSectionTrigger';

interface ExpandableOptionsBannerProps {
  image: CustomInspirationsGalleryImage;
  disabled: boolean;
  errors: ParseJoiValidateResponse<Pick<CustomInspirationsGalleryImage, 'altText' | 'imageUrl'>>;
  onChange: (partialImage: Partial<Omit<CustomInspirationsGalleryImage, 'id'>>) => void;
}

export function ExpandableOptionsBanner({
  image,
  disabled,
  errors,
  onChange,
}: ExpandableOptionsBannerProps): JSX.Element {
  const { expandedSections, onExpandSelectionChange, isSectionExpanded, onTextChange } =
    useExpandableOptions({
      errors,
      onChange,
    });

  const getImageUrlValue = (value: string) => {
    return value === DEFAULT_BANNER_IMAGE ? '' : value;
  };

  const imageUrlValue = getImageUrlValue(image.imageUrl);

  return (
    <ExpandableOptionsStyled>
      <Accordion
        onSelectionChanged={onExpandSelectionChange}
        expandedIds={expandedSections as string[]}
        disabled={disabled}
        canExpandMultiple
      >
        <Accordion.Item id={ExpandableSection.ImageUrl} observeHeight>
          <Accordion.Item.Header>
            <ExpandableSectionTriggerStyled
              title='Image URL'
              isExpanded={isSectionExpanded(ExpandableSection.ImageUrl)}
              isDisabled={!image.imageUrl || disabled}
            />
          </Accordion.Item.Header>
          <Accordion.Item.Content>
            <TextSection
              label='Image URL'
              value={imageUrlValue}
              errorMessage={errors.imageUrl?.message}
              onChange={onTextChange('imageUrl')}
              placeholder='Enter Image URL...'
            />
          </Accordion.Item.Content>
        </Accordion.Item>

        <Accordion.Item id={ExpandableSection.AltText} observeHeight>
          <Accordion.Item.Header>
            <ExpandableSectionTrigger
              title='Alt Text'
              isExpanded={isSectionExpanded(ExpandableSection.AltText)}
              isDisabled={disabled}
            />
          </Accordion.Item.Header>
          <Accordion.Item.Content>
            <TextSection
              label='Alt text'
              value={image.altText}
              errorMessage={errors.altText?.message}
              onChange={onTextChange('altText')}
            />
          </Accordion.Item.Content>
        </Accordion.Item>
      </Accordion>
    </ExpandableOptionsStyled>
  );
}
