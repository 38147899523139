import { orderBy } from 'lodash';
import { parseISO } from 'date-fns';
import {
  CustomInspirationsGallery,
  CustomInspirationsGalleryLayoutType,
  GalleryType,
  InspirationalImagesGallery,
} from '../types/galleries';
import { CustomInspirationsApi, IGalleriesResponseGet, InspirationalImageApi } from './types';
export class GalleriesMapper {
  static mapInspirationalImagesGallery(gallery: InspirationalImageApi): InspirationalImagesGallery {
    const mappedUpdatedAt = parseISO(gallery.updatedAt);

    return { ...gallery, galleryType: GalleryType.InspirationalImage, updatedAt: mappedUpdatedAt };
  }

  static mapCustomInspirationsGallery(gallery: CustomInspirationsApi): CustomInspirationsGallery {
    const mappedUpdatedAt = parseISO(gallery.updatedAt);

    return {
      ...gallery,
      galleryType: GalleryType.CustomInspirations,
      updatedAt: mappedUpdatedAt,
    };
  }

  static mapGalleriesResponse(
    responseBody: IGalleriesResponseGet
  ): (InspirationalImagesGallery | CustomInspirationsGallery)[] {
    const galleries: (InspirationalImagesGallery | CustomInspirationsGallery)[] = [];

    responseBody.inspirationalImages.forEach(inspirationImagesGallery => {
      const mappedGallery = this.mapInspirationalImagesGallery(inspirationImagesGallery);
      galleries.push(mappedGallery);
    });

    responseBody.customInspirations.forEach(customInspirationsGallery => {
      const mappedGallery = this.mapCustomInspirationsGallery(customInspirationsGallery);
      if (customInspirationsGallery.layout?.type === CustomInspirationsGalleryLayoutType.Banner) {
        mappedGallery.galleryType = GalleryType.ShoppableBanner;
      } else {
        mappedGallery.galleryType = GalleryType.CustomInspirations;
      }
      galleries.push(mappedGallery);
    });

    return orderBy(galleries, ['updatedAt']);
  }
}
