import {
  CollectionSettingsAction,
  CollectionSettingsActionTypes,
  CollectionSettingsErrorActionPayload,
  SaveSettingsActionPayload,
  SaveSettingsSuccessActionPayload,
} from './types';
import { Dispatch } from '../../../../types';
import { collectionsService } from '../../../../../services/src/service/collections';

const collectionSettingsActions = {
  saveRequest(payload: SaveSettingsActionPayload): CollectionSettingsAction {
    return {
      type: CollectionSettingsActionTypes.SaveRequest,
      payload,
    };
  },

  saveSuccess(payload: SaveSettingsSuccessActionPayload): CollectionSettingsAction {
    return {
      type: CollectionSettingsActionTypes.SaveSuccess,
      payload,
    };
  },

  saveError(payload: CollectionSettingsErrorActionPayload): CollectionSettingsAction {
    return {
      type: CollectionSettingsActionTypes.SaveError,
      payload,
    };
  },
};

export const collectionSettingsMethods = {
  save(payload: SaveSettingsActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(collectionSettingsActions.saveRequest(payload));
        const { collectionId, shopId, name, placement, uniqueByField, fallback, variantId } =
          payload;

        const {
          name: updatedName,
          placement: updatedPlacement,
          uniqueByField: updatedUniqueByField,
          fallback: updatedFallback,
        } = await collectionsService.updateCollection({
          shopId: shopId,
          collectionId: collectionId,
          body: {
            shopId,
            name,
            placement,
            uniqueByField,
            fallback,
          },
          variantId,
        });
        dispatch(
          collectionSettingsActions.saveSuccess({
            name: updatedName,
            placement: updatedPlacement,
            uniqueByField: updatedUniqueByField,
            fallback: updatedFallback,
          })
        );
      } catch (error) {
        dispatch(collectionSettingsActions.saveError({ error }));
      }
    };
  },
};
