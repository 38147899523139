import React, { useCallback, useMemo } from 'react';

import { CollectionsTableRow } from './CollectionsTableRow';
import { TableV2, TableV2InstanceProps } from '../../../components-dummy';
import { formatDateToNow } from '../../../utils/format-date-to-now';
import { CollectionsTableHeaderColumns } from './CollectionsTableHeaderColumns';
import { sortCollectionsMethods } from './actions/sortingCollectionsActions';
import { collectionsListActionMethods } from './actions';

import { tableColumns } from './consts';

import { CollectionsSortingOptionValue, CollectionsTableBodyRow } from './types';
import { Collection } from '../types';
import { Dispatch } from '../../types';

import { TableBodyStyled } from './CollectionsList.styles';

interface CollectionsListProps {
  shopId: number;
  dispatch: Dispatch;
  collectionsList: Collection[];
  onEditCollection?: (collectionId: string) => void;
  variantId?: string;
  isReadOnly?: boolean;
}

export const CollectionsList: React.FC<CollectionsListProps> = ({
  shopId,
  dispatch,
  collectionsList,
  onEditCollection,
  variantId,
  isReadOnly = false,
}) => {
  const tableData: CollectionsTableBodyRow[] = useMemo(() => {
    return collectionsList.map(collection => {
      const { id, name, updatedAt } = collection;

      return {
        id,
        name,
        lastUpdated: updatedAt ? formatDateToNow(updatedAt) : '',
      };
    });
  }, [collectionsList]);

  const columns = useMemo(() => Object.values(tableColumns), [tableColumns]);

  const handleSortingValue = useCallback(
    (newSortingValue: CollectionsSortingOptionValue) => {
      try {
        dispatch(
          sortCollectionsMethods.sortCollectionsSuccess({
            sortingValue: newSortingValue,
          })
        );
      } catch (e) {
        dispatch(collectionsListActionMethods.updateCollectionsError(e));
      }
    },
    [collectionsList]
  );

  const renderBody = ({
    rows,
    prepareRow,
  }: Pick<TableV2InstanceProps<CollectionsTableBodyRow>, 'rows' | 'prepareRow'>) => {
    if (!collectionsList.length) {
      return <TableV2.NoResults width={291} />;
    }
    return rows.map(row => {
      prepareRow(row);
      return (
        <CollectionsTableRow
          key={row.original.id}
          shopId={shopId}
          dispatch={dispatch}
          row={row}
          onEditCollection={onEditCollection}
          variantId={variantId}
          isReadOnly={isReadOnly}
        />
      );
    });
  };

  return (
    <TableV2<CollectionsTableBodyRow> options={{ columns, data: tableData }}>
      {({ getTableBodyProps, headerGroups, rows, prepareRow }) => {
        const headerGroup = headerGroups[0];

        return (
          <>
            <TableV2.Head {...headerGroup.getHeaderGroupProps()}>
              <CollectionsTableHeaderColumns
                headers={headerGroup.headers}
                columns={columns}
                handleSortingValue={handleSortingValue}
              />
            </TableV2.Head>
            <TableBodyStyled {...getTableBodyProps()}>
              {renderBody({ rows, prepareRow })}
            </TableBodyStyled>
          </>
        );
      }}
    </TableV2>
  );
};
