import { useEffect, useState, useCallback } from 'react';
import { useValidateSchema } from 'src/hooks';
import { RecEnginesGeneralSettingsReducerState } from 'src/app-state-types';
import {
  UseRecEnginesGeneralSettingsDraftArgs,
  UseRecEnginesGeneralSettingsDraft,
  RecEnginesGeneralSettingsRecord,
} from './types';
import { recEnginesGeneralSettingsValidationSchema } from './constants';
import { recEnginesGeneralSettingsActionsMethods } from './Actions';
import { isDataDirty } from '../../../../utils';
import { DEFAULT_SIZE_VARIANTS } from '../../../AddToCartSettings/defaults/default-size-variants';
import { DEFAULT_COLOR_VARIANTS_WITH_DISPLAY_ALL_VARIANTS } from '../../../AddToCartSettings/defaults/default-color-variants-with-display-all-variants';

export function useRecEnginesGeneralSettingsDraft({
  generalSettings,
  dispatch,
  shopId,
  selectedVariantId,
  onIsDirty,
  shouldRefetch = false,
}: UseRecEnginesGeneralSettingsDraftArgs): UseRecEnginesGeneralSettingsDraft {
  const [draftGeneralSettings, setDraftGeneralSettings] = useState(generalSettings);
  const { errors, validate, isValid } = useValidateSchema<RecEnginesGeneralSettingsRecord>({
    schema: recEnginesGeneralSettingsValidationSchema,
  });

  const isDirty = isDataDirty(generalSettings, draftGeneralSettings);

  const onSubmit = (): void => {
    if (isValid) {
      dispatch(
        recEnginesGeneralSettingsActionsMethods.updateRecEnginesGeneralSettings({
          shopId,
          offerNavOpenResultsInNewTabDesktop:
            draftGeneralSettings.offerNavOpenResultsInNewTabDesktop,
          offerNavOpenResultsInNewTabMobile: draftGeneralSettings.offerNavOpenResultsInNewTabMobile,
          overrideImageUrl: draftGeneralSettings.overrideImageUrl,
          removeDuplicates: draftGeneralSettings.removeDuplicates,
          variantId: selectedVariantId,
          addToCart: draftGeneralSettings.addToCart,
        })
      );
    }
  };

  const onDiscard = (): void => {
    setDraftGeneralSettings(generalSettings);
  };

  const onChange = (partialSettings: Partial<RecEnginesGeneralSettingsReducerState>) => {
    const updatedState = { ...draftGeneralSettings, ...partialSettings };
    validate({ dataToValidate: updatedState });
    setDraftGeneralSettings(updatedState);
    const { sizeVariants, colorVariants } = updatedState?.addToCart;
    if (!sizeVariants.active) {
      setDraftGeneralSettings({
        ...updatedState,
        addToCart: {
          ...updatedState.addToCart,
          sizeVariants: DEFAULT_SIZE_VARIANTS,
        },
      });
    }
    if (!colorVariants.active) {
      setDraftGeneralSettings({
        ...updatedState,
        addToCart: {
          ...updatedState.addToCart,
          colorVariants: DEFAULT_COLOR_VARIANTS_WITH_DISPLAY_ALL_VARIANTS,
        },
      });
    }
  };

  const fetchRecEnginesGeneralSettings = useCallback(() => {
    dispatch(
      recEnginesGeneralSettingsActionsMethods.fetchRecEnginesGeneralSettings({
        shopId,
        variantId: selectedVariantId,
      })
    );
  }, [shopId, selectedVariantId]);

  const onChangeOverrideImage = (
    partialSettings: Partial<RecEnginesGeneralSettingsReducerState['overrideImageUrl']>
  ) => {
    const updatedState = {
      ...draftGeneralSettings.overrideImageUrl,
      ...partialSettings,
    };
    onChange({ overrideImageUrl: updatedState });
  };

  const onChangeRemoveDuplicates = (
    partialSettings: Partial<RecEnginesGeneralSettingsReducerState['removeDuplicates']>
  ) => {
    const updatedState = { ...draftGeneralSettings.removeDuplicates, ...partialSettings };
    onChange({ removeDuplicates: updatedState });
  };

  const onChangeAddToCart = (
    partialSettings: Partial<RecEnginesGeneralSettingsReducerState['addToCart']>
  ) => {
    const updatedState = { ...draftGeneralSettings.addToCart, ...partialSettings };
    onChange({ addToCart: updatedState });
  };

  useEffect((): void => {
    setDraftGeneralSettings(generalSettings);
  }, [generalSettings]);

  useEffect(() => {
    const payload = { isDirty: !!isDirty };
    if (onIsDirty) {
      onIsDirty(payload);
    } else {
      dispatch(recEnginesGeneralSettingsActionsMethods.notifyIsDirty(payload));
    }
  }, [isDirty]);

  useEffect(() => {
    fetchRecEnginesGeneralSettings();
  }, [fetchRecEnginesGeneralSettings]);

  useEffect(() => {
    if (shouldRefetch) {
      fetchRecEnginesGeneralSettings();
    }
  }, [shouldRefetch]);

  return {
    draftGeneralSettings,
    errors,
    isValid,
    isDirty,
    onSubmit,
    onDiscard,
    onChange,
    onChangeOverrideImage,
    onChangeRemoveDuplicates,
    onChangeAddToCart,
  };
}
