import { createReducer } from '@reduxjs/toolkit';
import { EmailMarketingCampaignsReducerState } from 'src/app-state-types/reducer-state-types/email-marketing-campaign';
import { campaignModalPageActions } from 'src/components-bl/EmailMarketing/CampaignModalPage/Actions';
import { emailMarketingCampaignsActions } from 'src/components-bl/EmailMarketing/EmailMarketingCampaignsActions';
import { Campaign } from '../components-bl/EmailMarketing/types/campaign';

const initialState: EmailMarketingCampaignsReducerState = {
  campaigns: undefined,
  currentCampaign: undefined,
};

export const emailMarketingCampaignReducer = createReducer(initialState, builder => {
  builder
    .addCase(emailMarketingCampaignsActions.getCampaigns.fulfilled, (state, action) => {
      return { ...state, campaigns: action.payload };
    })

    .addCase(emailMarketingCampaignsActions.deleteCampaign.fulfilled, (state, action) => {
      return {
        ...state,
        campaigns: state.campaigns?.filter(
          campaign => campaign.id !== action.payload.deletedCampaignId
        ),
      };
    })

    .addCase(campaignModalPageActions.getCampaign.fulfilled, (state, action) => {
      return { ...state, currentCampaign: action.payload };
    })

    .addCase(campaignModalPageActions.resetCurrentCampaign, state => {
      return { ...state, currentCampaign: undefined };
    })

    .addCase(emailMarketingCampaignsActions.setDuplicateCampaignDraft, (state, action) => {
      const campaign = action.payload.campaign as Campaign;
      return { ...state, currentCampaign: campaign };
    });
});
