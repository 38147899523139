import React from 'react';
import { RoutedComponentProps } from 'src/app-routes';
import { Dispatch } from 'src/components-bl';
import { CampaignModalPage } from 'src/components-bl/EmailMarketing/CampaignModalPage/CampaignModalPage';
import { useHasDraft } from 'src/components-bl/hooks';
import { useAppSelector } from 'src/hooks';

interface CreateCampaignContainerProps extends RoutedComponentProps {
  shopId: number;
  dispatch: Dispatch;
  navigateToCampaigns: () => void;
}

export const CreateCampaignContainer = ({
  shopId,
  permittedRouteMap,
  dispatch,
  navigateToCampaigns,
}: CreateCampaignContainerProps): JSX.Element => {
  const currentCampaign = useAppSelector(state => state.campaigns.currentCampaign);
  const hasDraft = useHasDraft();

  return shopId ? (
    <CampaignModalPage
      dispatch={dispatch}
      shopId={shopId}
      permittedRouteMap={permittedRouteMap}
      navigateToCampaigns={navigateToCampaigns}
      currentCampaign={currentCampaign}
      hasDraft={hasDraft}
      isCreateMode
    />
  ) : null;
};
