import React from 'react';
import { CreateCollectionButton } from '../CreateCollectionButton';
import { CollectionsHeaderWrapper, CollectionTitle } from './CollectionsHeader.styles';
import { CollectionsSettings } from './CollectionsSettings';

interface CollectionsHeaderProps {
  onCreateDefaultCollection: VoidFunction;
  onManageTranslations: VoidFunction;
  isListEmpty: boolean;
  isReadOnly?: boolean;
}

export const CollectionsHeader = ({
  onCreateDefaultCollection,
  onManageTranslations,
  isListEmpty,
  isReadOnly = false,
}: CollectionsHeaderProps) => {
  const hasCollectionSettings = !(isListEmpty || isReadOnly);
  const hasCreateButton = !isReadOnly;
  return (
    <CollectionsHeaderWrapper>
      <CollectionTitle>Collections</CollectionTitle>
      {hasCollectionSettings && <CollectionsSettings onManageTranslations={onManageTranslations} />}
      {hasCreateButton && (
        <CreateCollectionButton
          onCreateDefaultCollection={onCreateDefaultCollection}
          buttonText='Create collection'
        />
      )}
    </CollectionsHeaderWrapper>
  );
};
