import { EditExperimentContainerAction } from 'src/containers';
import { RecEnginesGeneralSettingsReducerState } from '../app-state-types';
import {
  RecEnginesActionTypes,
  RecEnginesGeneralSettingsAction,
  VariantPanelAction,
} from '../components-bl';
import { DEFAULT_SIZE_VARIANTS } from '../components-bl/AddToCartSettings/defaults/default-size-variants';
import { DEFAULT_COLOR_VARIANTS_WITH_DISPLAY_ALL_VARIANTS } from '../components-bl/AddToCartSettings/defaults/default-color-variants-with-display-all-variants';

const initialState: RecEnginesGeneralSettingsReducerState = {
  offerNavOpenResultsInNewTabDesktop: false,
  offerNavOpenResultsInNewTabMobile: false,
  overrideImageUrl: {
    active: false,
    attribute: '',
    selector: '',
  },
  removeDuplicates: {
    enable: true,
    prioritizedCarousel: ['Similar Items', 'Shop The Look', 'Personalization', 'Bought Together'],
  },
  shouldRefetch: false,
  addToCart: {
    sizeVariants: DEFAULT_SIZE_VARIANTS,
    colorVariants: DEFAULT_COLOR_VARIANTS_WITH_DISPLAY_ALL_VARIANTS,
  },
};

export function recEnginesGeneralSettingsReducer(
  state: RecEnginesGeneralSettingsReducerState = initialState,
  action?: RecEnginesGeneralSettingsAction | EditExperimentContainerAction | VariantPanelAction
): RecEnginesGeneralSettingsReducerState {
  switch (action?.type) {
    case RecEnginesActionTypes.GetRecEnginesGeneralSettingsSuccess: {
      const {
        offerNavOpenResultsInNewTabDesktop,
        offerNavOpenResultsInNewTabMobile,
        overrideImageUrl,
        removeDuplicates,
        addToCart,
      } = action.payload;
      return {
        ...state,
        offerNavOpenResultsInNewTabDesktop,
        offerNavOpenResultsInNewTabMobile,
        overrideImageUrl,
        removeDuplicates,
        addToCart,
        shouldRefetch: false,
      };
    }
    case RecEnginesActionTypes.GetRecEnginesGeneralSettingsRequest:
      return { ...state };
    case RecEnginesActionTypes.GetRecEnginesGeneralSettingsError:
      return { ...state };
    case RecEnginesActionTypes.UpdateRecEnginesGeneralSettingsSuccess: {
      const {
        offerNavOpenResultsInNewTabDesktop,
        offerNavOpenResultsInNewTabMobile,
        overrideImageUrl,
        removeDuplicates,
        addToCart,
      } = action.payload;
      const updatedSettings = {
        ...state,
        offerNavOpenResultsInNewTabDesktop,
        offerNavOpenResultsInNewTabMobile,
        overrideImageUrl,
        removeDuplicates,
        addToCart,
      };
      return {
        ...state,
        offerNavOpenResultsInNewTabDesktop: updatedSettings.offerNavOpenResultsInNewTabDesktop,
        offerNavOpenResultsInNewTabMobile: updatedSettings.offerNavOpenResultsInNewTabMobile,
        overrideImageUrl: updatedSettings.overrideImageUrl,
        removeDuplicates: updatedSettings.removeDuplicates,
        addToCart: updatedSettings.addToCart,
      };
    }
    default:
      return state;
  }
}
