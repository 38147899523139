import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { OverlayModal } from 'src/components-dummy';

export const CreateCampaignPageStyled = styled.div`
  min-height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const SideBarLayout = styled.div`
  min-width: 400px;
  max-width: 400px;
  z-index: 1;
`;

export const PreviewLayout = styled.div`
  width: 100%;
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CampaignPreview = styled.div`
  width: 100%;
  flex: 1;
  background-color: ${({ theme }) => theme.palette.custom['gray-90']};
  padding: 0 32px;
  overflow-y: auto;
`;

export const OverlayModalStyled = styled(OverlayModal)<{ fullscreen: boolean }>`
  ${({ fullscreen }) => {
    if (fullscreen) {
      return css`
        height: 100%;
      `;
    }
    return css`
      height: calc(100% - 32px);
      top: 32px;
    `;
  }}
`;
