import React, { MouseEventHandler, useCallback } from 'react';
import { Dispatch } from 'src/components-bl/types';
import { CustomInspirationsGalleryImage } from 'src/services';
import { GalleryImage } from 'src/components-bl/Galleries/CustomInspirations/EditCustomInspirationsGalleryPage/components/GalleryPreview/components/GalleryImage';
import { SkuConfiguration } from 'src/components-bl/ProductCard/ProductCard';
import { BannerGridItemStyled } from '../BannerPreview.styles';

interface BannerGridItemProps {
  image: CustomInspirationsGalleryImage;
  onImageClick: (imageId: string) => void;
  onAddTags: (imageId: string) => void;
  onRemoveTagFromImage: (parameters: { imageId: string; tagId: string }) => void;
  dispatch: Dispatch;
  shopId: number;
  loading: boolean;
  productInformationLookup: Record<string, SkuConfiguration>;
}

export function BannerGridItem({
  image,
  onAddTags,
  shopId,
  dispatch,
  onImageClick,
  loading,
  productInformationLookup,
  onRemoveTagFromImage,
}: BannerGridItemProps): JSX.Element {
  const onClick: MouseEventHandler = useCallback(() => {
    onImageClick(image.id);
  }, [image.id, onImageClick]);

  const removeTagById = useCallback(
    (tagId: string) => {
      onRemoveTagFromImage({ tagId, imageId: image.id });
    },
    [onRemoveTagFromImage, image.id]
  );

  return (
    <>
      <BannerGridItemStyled onClick={onClick}>
        <GalleryImage
          image={image}
          onAddTags={onAddTags}
          dispatch={dispatch}
          shopId={shopId}
          loading={loading}
          productInformationLookup={productInformationLookup}
          removeTagById={removeTagById}
          isShoppableBanner={true}
        />
      </BannerGridItemStyled>
    </>
  );
}
