import React from 'react';
import { useParams } from 'react-router';
import { RoutedComponentProps } from 'src/app-routes';
import { TagImageModal } from 'src/components-bl/Galleries/CustomInspirations/TagImageModal/TagImageModal';
import { useAppDispatch } from 'src/hooks';
import { useGallerySelector } from '../useGallerySelector';
interface TagImageContainerProps extends RoutedComponentProps {
  shopId: number;
}

export function TagImageContainer({
  shopId,
  permittedRouteMap,
}: TagImageContainerProps): JSX.Element | null {
  const dispatch = useAppDispatch();
  const { imageId } = useParams<{ imageId: string }>();

  const gallery = useGallerySelector();
  return gallery ? (
    <TagImageModal
      shopId={shopId}
      dispatch={dispatch}
      permittedRouteMap={permittedRouteMap}
      gallery={gallery}
      selectedImageId={imageId}
    />
  ) : null;
}
