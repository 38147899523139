import React from 'react';
import { SortingOrder } from 'src/types/enums/sorting-order';
import { SelectField } from './SelectField';
import { FallbackFieldLabel, FallbackSelectorsPlaceholder } from '../enums';

interface CustomFallbackSelectorProps {
  targetField: string | undefined;
  sortingOrder: string | undefined;
  handleChange: (field: 'targetField' | 'sortingOrder', value: string | undefined) => void;
  dataFieldsDropdownOptions: { value: string; text: string }[];
  orderOptions: { value: SortingOrder; text: string }[];
  isReadOnly?: boolean;
}

export const CustomFallbackSelector = ({
  targetField,
  sortingOrder,
  handleChange,
  dataFieldsDropdownOptions,
  orderOptions,
  isReadOnly = false,
}: CustomFallbackSelectorProps) => {
  return (
    <>
      <SelectField
        value={targetField || undefined}
        options={dataFieldsDropdownOptions}
        onChange={e => handleChange('targetField', e.target.value)}
        autoFocus={false}
        labelText={FallbackFieldLabel.FALLBACK_FIELD}
        placeholder={FallbackSelectorsPlaceholder.CUSTOM_FIELD}
        disabled={isReadOnly}
      />

      <SelectField
        value={sortingOrder || undefined}
        options={orderOptions}
        onChange={e => handleChange('sortingOrder', e.target.value)}
        autoFocus={false}
        labelText={FallbackFieldLabel.FALLBACK_ORDER}
        disabled={isReadOnly}
      />
    </>
  );
};
