import {
  CustomInspirationsGallery,
  ShoppableBanner as ShoppableBannerLayout,
} from './custom-inspirations-gallery';

export enum GalleryType {
  InspirationalImage = 'inspirational_images',
  CustomInspirations = 'custom_inspirations',
  ShoppableBanner = 'shoppable_banner',
}

export interface IGalleryImage {
  imageUrl: string;
  productIds: string[];
}

export interface InspirationalImagesGallery {
  id: string;
  galleryName: string;
  galleryType: GalleryType;
  images: IGalleryImage[];
  updatedAt: Date;
}

export type Gallery =
  | InspirationalImagesGallery
  | CustomInspirationsGallery
  | ShoppableBannerLayout;
