import React, { useCallback } from 'react';
import { TextBox } from '../../../../../../../components-dummy';
import { ParseJoiValidateResponse } from 'src/utils';
import { Campaign } from '../../../../../types/campaign';
import { CampaignDraft } from '../../../../../types/campaign-draft';
import { Section } from '../../CampaignSideBar.styles';

interface CampaignNameSectionProps {
  value: string | undefined;
  onChange: (campaign: CampaignDraft) => void;
  errors: ParseJoiValidateResponse<Pick<Campaign, 'campaignName'>>;
}

export const CampaignNameSection = ({
  value,
  onChange,
  errors,
}: CampaignNameSectionProps): JSX.Element => {
  const onNameChange = useCallback(
    (newName: string) => {
      onChange({ campaignName: newName });
    },
    [onChange]
  );

  return (
    <Section>
      <TextBox
        label='Title'
        value={value}
        onChange={onNameChange}
        placeholder='type...'
        error={errors?.campaignName?.message}
      />
    </Section>
  );
};
