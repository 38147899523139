import { CollectionsReducerState } from '../app-state-types';
import {
  CollectionAction,
  CollectionActionTypes,
  CollectionsListAction,
  CollectionsListActionTypes,
  Collection,
  CollectionRulesAction,
  CollectionRulesActionTypes,
  CollectionSettingsActionTypes,
  CollectionSettingsAction,
} from '../components-bl';
import {
  CreateCollectionButtonActionTypes,
  CreateCollectionButtonCreateCollectionAction,
} from '../components-bl/Collections/CreateCollectionButton';
import { EditCollectionActionTypes, EditCollectionAction } from '../containers';
import { SortOption } from '../utils';
import { updateList } from './helpers';

const defaultState: CollectionsReducerState = {
  collections: [],
  currentCollection: undefined,
  sortingValue: {
    property: 'updatedAt',
    option: SortOption.Desc,
  },
};

type CollectionsActions =
  | CollectionsListAction
  | CreateCollectionButtonCreateCollectionAction
  | CollectionAction
  | CollectionSettingsAction
  | EditCollectionAction
  | CollectionRulesAction;

export const collectionsReducer = (
  state = defaultState,
  action?: CollectionsActions
): CollectionsReducerState => {
  switch (action?.type) {
    case CollectionsListActionTypes.GetCollectionsSuccess:
      return {
        ...state,
        collections: action.payload.collections ?? [],
      };
    case CreateCollectionButtonActionTypes.CreateCollectionSuccess:
      return {
        ...state,
        collections: updateList(action.payload.collection, state.collections),
      };
    case CollectionActionTypes.DeleteCollectionSuccess:
      return {
        ...state,
        collections: action.payload.collections ?? [],
      };
    case EditCollectionActionTypes.GetCollectionSuccess:
      return {
        ...state,
        currentCollection: {
          ...(state.currentCollection as Collection),
          uniqueByField: action.payload.uniqueByField,
          ...action.payload,
        },
      };
    case CollectionRulesActionTypes.GetRuleGroupsSuccess:
      return {
        ...state,
        currentCollection: {
          ...(state.currentCollection as Collection),
          ruleGroups: action.payload.ruleGroups,
        },
      };
    case CollectionRulesActionTypes.SaveDiffSuccess:
      return {
        ...state,
        currentCollection: {
          ...(state.currentCollection as Collection),
          ruleGroups: action.payload.ruleGroups,
        },
      };
    case CollectionSettingsActionTypes.SaveSuccess:
      return {
        ...state,
        currentCollection: {
          ...(state.currentCollection as Collection),
          name: action.payload.name,
          placement: action.payload.placement,
          uniqueByField: action.payload.uniqueByField,
          fallback: action.payload.fallback,
        },
      };
    case CollectionsListActionTypes.SortCollectionsSuccess: {
      return {
        ...state,
        sortingValue: action.payload.sortingValue,
      };
    }
    default:
      return state;
  }
};
