import React, { useCallback, useEffect, useState } from 'react';
import { CustomInspirationsGalleryLayoutType } from 'src/services';
import { CustomInspirationGalleryDraft } from '../../../../types';
import { GridLayoutSectionStyled, GridLayoutTypesRow } from './GridLayoutSection.styles';
import { LayoutSpecificConfigurations, LayoutTypeImage } from './components';

interface GridLayoutSectionProps {
  gallery: CustomInspirationGalleryDraft | undefined;
  onChange: (partialDraft: Partial<CustomInspirationGalleryDraft>) => void;
}

const layoutTypeToDefaults: Record<
  CustomInspirationsGalleryLayoutType,
  CustomInspirationGalleryDraft['layout']
> = {
  [CustomInspirationsGalleryLayoutType.Carousel]: {
    carouselNumberOfImages: 3,
  } as CustomInspirationGalleryDraft<CustomInspirationsGalleryLayoutType.Carousel>['layout'],
  [CustomInspirationsGalleryLayoutType.Grid]: {
    gridImagesPerRow: 3,
    gridNumberOfRows: 3,
  } as CustomInspirationGalleryDraft<CustomInspirationsGalleryLayoutType.Grid>['layout'],
  [CustomInspirationsGalleryLayoutType.Collage]: {
    type: CustomInspirationsGalleryLayoutType.Collage,
  } as CustomInspirationGalleryDraft<CustomInspirationsGalleryLayoutType.Collage>['layout'],
  [CustomInspirationsGalleryLayoutType.Banner]: {
    type: CustomInspirationsGalleryLayoutType.Banner,
  } as CustomInspirationGalleryDraft<CustomInspirationsGalleryLayoutType.Banner>['layout'],
};

export const GridLayoutSection = ({
  gallery,
  onChange,
}: GridLayoutSectionProps): JSX.Element | null => {
  const [layout, setLayout] = useState(gallery?.layout);

  const onLayoutChange = useCallback(
    (partialLayout: Partial<CustomInspirationGalleryDraft['layout']>) => {
      if (!layout) {
        return;
      }

      const type = partialLayout.type || layout.type;
      const selectedTypeLayoutOptions = Object.keys(layoutTypeToDefaults[type]);

      const updatedLayout = selectedTypeLayoutOptions.reduce(
        (layoutOptions, layoutOption) => {
          const defaultValue = layoutTypeToDefaults[type][layoutOption];
          return {
            ...layoutOptions,
            [layoutOption]: partialLayout[layoutOption] || layout[layoutOption] || defaultValue,
          };
        },
        { type } as CustomInspirationGalleryDraft['layout']
      );

      onChange({ layout: updatedLayout });
    },
    [layout, onChange]
  );

  const onSelectGridType = useCallback(
    (newType: CustomInspirationsGalleryLayoutType) => {
      onLayoutChange({ type: newType });
    },
    [onLayoutChange]
  );

  useEffect(() => {
    setLayout(previousLayout => {
      return { ...previousLayout, ...gallery?.layout };
    });
  }, [gallery?.layout, setLayout]);

  if (!layout) {
    return null;
  }

  return (
    <GridLayoutSectionStyled>
      <GridLayoutTypesRow>
        <LayoutTypeImage
          type={CustomInspirationsGalleryLayoutType.Grid}
          onSelect={onSelectGridType}
          currentSelected={layout.type}
        />

        <LayoutTypeImage
          type={CustomInspirationsGalleryLayoutType.Carousel}
          onSelect={onSelectGridType}
          currentSelected={layout.type}
        />

        <LayoutTypeImage
          type={CustomInspirationsGalleryLayoutType.Collage}
          onSelect={onSelectGridType}
          currentSelected={layout.type}
          disabled
        />
      </GridLayoutTypesRow>

      <LayoutSpecificConfigurations layout={layout} onChange={onLayoutChange} />
    </GridLayoutSectionStyled>
  );
};
