import styled from '@emotion/styled';

export const IconWrapperStyled = styled.div<{ disabled?: boolean }>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    background-color: ${({ theme, disabled }) =>
      disabled ? 'transparent' : theme.palette.custom['gray-100']};

    path {
      stroke: ${({ theme, disabled }) => (disabled ? 'inherit' : theme.palette.common.black)};
    }
  }
`;
