import React, { useEffect, useState } from 'react';
import { Dispatch } from 'src/components-bl/types';
import { ShoppableBannerDraft } from '../../../types';
import { useFetchProductsInBulk } from 'src/components-bl/Galleries/CustomInspirations/hooks/useFetchProductsInBulk';
import {
  BannerContainerStyled,
  BannerItemWrapper,
  BannerWrapper,
  BannerTitleStyled,
} from '../../BannerPreview/BannerPreview.styles';
import { CustomInspirationsGalleryImage } from 'src/services';
import { BannerGridItem } from './BannerGridItem';
import { TypographyType } from 'src/components-dummy';
import { GalleryMapper } from 'src/components-bl/Galleries/CustomInspirations/EditCustomInspirationsGalleryPage/mapper';

interface BannerDisplayProps {
  gallery: ShoppableBannerDraft;
  onImageClick: (imageId?: string) => void;
  onAddTags: (imageId: string) => void;
  onChange: (partialDraft: Partial<ShoppableBannerDraft>) => void;
  dispatch: Dispatch;
  shopId: number;
}

export function BannerDisplay({
  gallery,
  onAddTags,
  onImageClick,
  onChange,
  dispatch,
  shopId,
}: BannerDisplayProps): JSX.Element {
  const [bannerImages, setBannerImages] = useState<CustomInspirationsGalleryImage[]>(
    gallery.images || []
  );

  useEffect(() => {
    setBannerImages(gallery.images || []);
  }, [gallery.images]);

  const onRemoveTagFromImage = ({ imageId, tagId }: { imageId: string; tagId: string }) => {
    const updatedImages = bannerImages.map(image => {
      if (image.id === imageId) {
        return {
          ...image,
          tags: image.tags.filter(tag => tag.id !== tagId),
          aiSuggestedTags: image.aiSuggestedTags.filter(tag => tag.id !== tagId),
        };
      }
      return image;
    });

    onChange({ images: updatedImages });
  };

  const { productInformationLookup, fetchProductsInBulk, resetOptions, loading } =
    useFetchProductsInBulk({
      dispatch,
      shopId,
      initialLoading: true,
    });

  useEffect(() => {
    resetOptions();

    const skus = GalleryMapper.getUniqueSkusFromImages(gallery.images);

    fetchProductsInBulk(skus);
  }, [gallery.images]);

  return (
    <BannerContainerStyled>
      <BannerItemWrapper key={bannerImages[0].id}>
        <BannerTitleStyled type={TypographyType.Heading}>Desktop Banner</BannerTitleStyled>
        <BannerWrapper>
          <BannerGridItem
            image={bannerImages[0]}
            onImageClick={onImageClick}
            onAddTags={onAddTags}
            shopId={shopId}
            dispatch={dispatch}
            onRemoveTagFromImage={onRemoveTagFromImage}
            loading={loading}
            productInformationLookup={productInformationLookup}
          />
        </BannerWrapper>
      </BannerItemWrapper>
      <BannerItemWrapper key={bannerImages[1].id}>
        <BannerTitleStyled type={TypographyType.Heading}>Mobile Banner</BannerTitleStyled>
        <BannerWrapper>
          <BannerGridItem
            image={bannerImages[1]}
            onImageClick={onImageClick}
            onAddTags={onAddTags}
            shopId={shopId}
            dispatch={dispatch}
            onRemoveTagFromImage={onRemoveTagFromImage}
            loading={loading}
            productInformationLookup={productInformationLookup}
          />
        </BannerWrapper>
      </BannerItemWrapper>
    </BannerContainerStyled>
  );
}
