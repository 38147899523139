import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { EditCollectionRules } from './EditCollectionRules';
import { EditCollectionRulesFooter } from './EditCollectionRulesFooter';
import { useCollection } from '../useCollection';
import { editCollectionRulesContainerActions } from './EditCollectionRulesContainerActions';
import { Collection } from '../../../types';
import { Dispatch } from '../../../../types';
import { CollectionPage } from '../../../CollectionPage.config';

export interface EditCollectionRulesVariantsContainerProps {
  shopId: number;
  currentCollection: Collection;
  dispatch: Dispatch;
  onPageChange: (page: CollectionPage) => void;
  isCreateMode?: boolean;
  variantId?: string;
  formHeaderElementRef?: React.RefObject<HTMLDivElement | null>;
  isReadOnly?: boolean;
}

export const EditCollectionRulesVariantsContainer = ({
  shopId,
  currentCollection,
  dispatch,
  onPageChange,
  isCreateMode,
  variantId,
  formHeaderElementRef,
  isReadOnly = false,
}: EditCollectionRulesVariantsContainerProps): JSX.Element => {
  const {
    draftCollection,
    canSubmit,
    errors,
    onAddRuleGroup,
    onDeleteRuleGroup,
    onAddRule,
    onDeleteRule,
    onEditRule,
    onSaveChanges,
    onCreateCollection,
    onCancel,
    handleFieldOnChange,
    dataFields,
  } = useCollection({
    shopId,
    currentCollection,
    dispatch,
    isCreateMode,
    variantId,
  });

  useEffect(() => {
    if (shopId) {
      dispatch(
        editCollectionRulesContainerActions.getDataFields({
          shopId,
        })
      );
    }
  }, [shopId]);

  const onCreate = () => {
    onCreateCollection(() => onPageChange(CollectionPage.List));
  };

  const onCancelChanges = () => {
    onCancel(() => onPageChange(CollectionPage.List));
  };

  const formButtonsSection = createPortal(
    <EditCollectionRulesFooter
      isCreateMode={isCreateMode}
      canSubmit={canSubmit}
      onCancel={onCancelChanges}
      onSaveChanges={onSaveChanges}
      onCreate={onCreate}
      className='experiments-rule-form-footer'
      isReadOnly={isReadOnly}
    />,
    formHeaderElementRef?.current
  );

  return (
    <>
      <EditCollectionRules
        shopId={shopId}
        dispatch={dispatch}
        draftCollection={draftCollection}
        isCreateMode={isCreateMode}
        onDeleteRule={onDeleteRule}
        onEditRule={onEditRule}
        onAddRule={onAddRule}
        onAddRuleGroup={onAddRuleGroup}
        onDeleteRuleGroup={onDeleteRuleGroup}
        handleFieldOnChange={handleFieldOnChange}
        dataFields={dataFields}
        errors={errors}
        isReadOnly={isReadOnly}
      />
      {formButtonsSection}
    </>
  );
};
