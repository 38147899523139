import { useMemo } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { Collection } from 'src/components-bl/Collections/types/Collection';
import { SyteProductPlacement } from '../../services/src/service/types/syte-products';
import { buildDefaultRuleGroup } from '../../components-bl/Collections/EditCollectionRules/CollectionRules/helpers/defaults';
import { getDefaultFallback } from 'src/components-bl/Collections/EditCollectionRules/CollectionFallback/helpers/getDefaultFallback';

interface UseGetDefaultCollection {
  shopId: number;
  variantId?: string;
}

interface UseGetDefaultCollectionReturn {
  defaultCollection: Collection;
  collectionId: string;
}

export const useGetDefaultCollection = ({
  shopId,
  variantId,
}: UseGetDefaultCollection): UseGetDefaultCollectionReturn => {
  const collectionId = useMemo(() => uuidV4(), []);
  const collectionName = '';
  const defaultCollection = useMemo(
    () => ({
      shopId,
      id: collectionId,
      name: collectionName,
      dataCollectionId: collectionName,
      placement: SyteProductPlacement.PDP,
      createdAt: null,
      updatedAt: null,
      ruleGroups: [buildDefaultRuleGroup(collectionId, collectionName)],
      variantId,
      fallback: getDefaultFallback(),
      isActive: true,
    }),
    []
  );

  return {
    defaultCollection,
    collectionId,
  };
};
