import React, { useCallback } from 'react';
import {
  Switch,
  SwitchSizes,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { CustomInspirationGalleryDraft } from '../../../../types';
import { SettingsSectionStyled, SimilarProductToggleStyled } from './SettingsSection.styles';
import { ShoppableBannerDraft } from 'src/components-bl/Galleries/ShoppableBanner/EditShoppableBannerPage/types';

interface SettingsSectionProps {
  gallery?: CustomInspirationGalleryDraft | ShoppableBannerDraft;
  onChange: (partialDraft: Partial<CustomInspirationGalleryDraft | ShoppableBannerDraft>) => void;
}

export function SettingsSection({ gallery, onChange }: SettingsSectionProps): JSX.Element | null {
  const onSimilarProductToggleChange = useCallback(
    isChecked => {
      onChange({ showSimilarProductWhenUnavailable: isChecked });
    },
    [onChange]
  );

  if (!gallery) {
    return null;
  }

  return (
    <SettingsSectionStyled>
      <SimilarProductToggleStyled>
        <Typography variant={TypographyVariant.SmallRegular} type={TypographyType.Paragraph}>
          Show similar product, when product is not available
        </Typography>
        <Switch
          size={SwitchSizes.Medium}
          onChange={onSimilarProductToggleChange}
          checked={gallery.showSimilarProductWhenUnavailable}
        />
      </SimilarProductToggleStyled>
    </SettingsSectionStyled>
  );
}
