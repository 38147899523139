import React, { MouseEvent, useCallback } from 'react';
import {
  EllipsisMenuButton,
  Menu,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { MenuActionsStyled, MenuItemStyled } from './RowActionsMenu.styles';

interface RowActionMenuProps {
  menuAnchorElement: HTMLElement | null;
  onMenuClicked: (event: MouseEvent<HTMLElement>) => void;
  onMenuClose: () => void;
  onEditCampaignClick?: () => void;
  onDeleteCampaignClick?: () => void;
  onDuplicateCampaignClick: () => void;
}

export const RowActionsMenu = ({
  menuAnchorElement,
  onMenuClicked,
  onMenuClose,
  onEditCampaignClick,
  onDeleteCampaignClick,
  onDuplicateCampaignClick,
}: RowActionMenuProps): JSX.Element => {
  const isOpen = Boolean(menuAnchorElement);

  const onItemClick = useCallback(
    onClick => {
      onClick();
      onMenuClose();
    },
    [onMenuClose]
  );

  return (
    <MenuActionsStyled>
      <EllipsisMenuButton onClick={onMenuClicked} isActive={isOpen} />
      <Menu
        open={isOpen}
        anchorEl={menuAnchorElement}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -4,
          horizontal: 'right',
        }}
      >
        {!!onEditCampaignClick && (
          <MenuItemStyled key='edit' onClick={() => onItemClick(onEditCampaignClick)}>
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              Edit
            </Typography>
          </MenuItemStyled>
        )}
        {!!onDuplicateCampaignClick && (
          <MenuItemStyled key='duplicate' onClick={() => onItemClick(onDuplicateCampaignClick)}>
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              Duplicate
            </Typography>
          </MenuItemStyled>
        )}
        {!!onDeleteCampaignClick && (
          <MenuItemStyled key='delete' onClick={onDeleteCampaignClick} shouldHighlightRed>
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              Delete
            </Typography>
          </MenuItemStyled>
        )}
      </Menu>
    </MenuActionsStyled>
  );
};
