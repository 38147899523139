export enum ShopFeature {
  AugmentedSearch = 'augmentedSearch',
  Camera = 'camera',
  Collections = 'collections',
  DeepTagReports = 'deepTagReports',
  DeepTagReportsContentGeneration = 'deepTagReportsContentGeneration',
  DeepTagUploadCSV = 'deepTagReportsUploadCsv',
  DiscoveryBanner = 'discoveryBanner',
  DiscoveryButton = 'discoveryButton',
  DiscoveryStories = 'discoveryStories',
  Experiments = 'experiments',
  FrequentlyBoughtTogether = 'frequentlyBoughtTogether',
  InspirationGallery = 'inspirationGallery',
  Lexicon = 'lexicon',
  MerchandisingRulesAiTags = 'merchandisingRulesAiTags',
  Personalization = 'personalization',
  RecentlyViewed = 'recentlyViewed',
  ShopTheLook = 'shopTheLook',
  SimilarItems = 'similarItems',
  SocialDiscovery = 'socialDiscovery',
  UiTemplates = 'uiTemplates',
  Preview = 'preview',
  apiDomainsWhitelist = 'apiDomainsWhitelist',
  imageDownloadWhitelist = 'imageDownloadWhitelist',
  Filters = 'filters',
  AddToCart = 'addToCart',
  CatalogManager = 'catalogManager',
  CatalogManagerUploadLogs = 'catalogManagerUploadLogs',
  Galleries = 'galleries',
  SizeColorVariants = 'sizeColorVariants',
  BrowsePLP = 'browsePLP',
  EmailMarketing = 'emailMarketing',
  FileBasedRecommendations = 'fileBasedRecommendations',
  Insights = 'insights',
}
