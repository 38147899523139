import React, { useCallback, useState } from 'react';
import { AvailableIcons, Button, Icon } from 'src/components-dummy';
import { CustomInspirationsGalleryImage, GalleryType } from 'src/services';
import { ParseJoiValidateResponse } from 'src/utils';
import { ExpandableOptions, ResetDraftConfirmationDialog } from './components';
import { ImageSettingsStyled, SettingsFooterStyled } from './ImageSettings.styles';
import { TagsSection } from './components/TagsSection';
import { ProductTagsSectionTriggerStyled } from './components/ExpandableOptions/ExpandableOptions.styled';
import { ExpandableSectionTrigger } from '../../../ExpandableSectionTrigger';
import { ExpandableOptionsBanner } from '../../../../ShoppableBanner/EditShoppableBannerPage/components/BannerPreview/components/ExpandableOptionsBanner';

interface ImageSettingsProps {
  galleryType: GalleryType;
  image: CustomInspirationsGalleryImage;
  isDirty: boolean;
  failedToLoadImage: boolean;
  isValid: boolean;
  errors: ParseJoiValidateResponse<
    Pick<CustomInspirationsGalleryImage, 'altText' | 'caption' | 'imageUrl'>
  >;
  onChange: (partialImage: Partial<Omit<CustomInspirationsGalleryImage, 'id'>>) => void;
  onSave: () => void;
  resetDraft: () => void;
  removeTagById: (id: string) => void;
}

export function ImageSettings({
  galleryType,
  image,
  isDirty,
  failedToLoadImage,
  errors,
  isValid,
  onChange,
  onSave,
  resetDraft,
  removeTagById,
}: ImageSettingsProps): JSX.Element {
  const [showConfirmResetDraftDialog, setShowConfirmResetDraftDialog] = useState(false);

  const onCancel = useCallback(() => {
    setShowConfirmResetDraftDialog(true);
  }, [setShowConfirmResetDraftDialog]);

  const onResetConfirm = useCallback(() => {
    resetDraft();
    setShowConfirmResetDraftDialog(false);
  }, [resetDraft, setShowConfirmResetDraftDialog]);

  const onResetCancel = useCallback(() => {
    setShowConfirmResetDraftDialog(false);
  }, [setShowConfirmResetDraftDialog]);

  return (
    <>
      <ImageSettingsStyled>
        {galleryType === GalleryType.ShoppableBanner ? (
          <ExpandableOptionsBanner
            image={image}
            disabled={failedToLoadImage}
            errors={errors}
            onChange={onChange}
          />
        ) : (
          <ExpandableOptions
            image={image}
            disabled={failedToLoadImage}
            errors={errors}
            onChange={onChange}
          />
        )}

        <ProductTagsSectionTriggerStyled title='Product Tags' isExpanded isDisabled={false}>
          <ExpandableSectionTrigger.TitleIcon>
            <Icon name={AvailableIcons.ProductTags} />
          </ExpandableSectionTrigger.TitleIcon>
        </ProductTagsSectionTriggerStyled>
        <TagsSection tags={image.tags} removeTagById={removeTagById} />
        <SettingsFooterStyled>
          <Button variant='tertiary' onClick={onCancel} disabled={!isDirty}>
            Cancel
          </Button>
          <Button variant='primary' onClick={onSave} disabled={!isDirty || !isValid}>
            Save
          </Button>
        </SettingsFooterStyled>
      </ImageSettingsStyled>
      {showConfirmResetDraftDialog && (
        <ResetDraftConfirmationDialog onConfirm={onResetConfirm} onCancel={onResetCancel} />
      )}
    </>
  );
}
