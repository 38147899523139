import { MerchandisingRuleTypes, FeedDataFieldType, SyteProductTypes } from 'src/services';
import {
  ConditionRowType,
  ConditionTypesOptions,
  DataFieldFormToConditionTypeMap,
  MerchandisingRuleSettings,
  SyteProductToRuleSettingsMap,
} from './types';

const defaultConditionTypes: MerchandisingRuleTypes.MerchandisingRuleSubType[] = [
  MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotContain,
  MerchandisingRuleTypes.MerchandisingRuleSubType.Contains,
  MerchandisingRuleTypes.MerchandisingRuleSubType.Equals,
  MerchandisingRuleTypes.MerchandisingRuleSubType.EqualsSource,
  MerchandisingRuleTypes.MerchandisingRuleSubType.HigherThan,
  MerchandisingRuleTypes.MerchandisingRuleSubType.LowerThan,
  MerchandisingRuleTypes.MerchandisingRuleSubType.RelativeRange,
  MerchandisingRuleTypes.MerchandisingRuleSubType.IsBetween,
  MerchandisingRuleTypes.MerchandisingRuleSubType.HasValue,
  MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotHaveValue,
];

const defaultConditionTypesOptions: ConditionTypesOptions = {
  [ConditionRowType.ApplyWhenCondition]: [
    MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotContain,
    MerchandisingRuleTypes.MerchandisingRuleSubType.Contains,
    MerchandisingRuleTypes.MerchandisingRuleSubType.Equals,
    MerchandisingRuleTypes.MerchandisingRuleSubType.HigherThan,
    MerchandisingRuleTypes.MerchandisingRuleSubType.LowerThan,
    MerchandisingRuleTypes.MerchandisingRuleSubType.IsBetween,
    MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotHaveValue,
    MerchandisingRuleTypes.MerchandisingRuleSubType.HasValue,
  ],
};

const defaultRuleActions: MerchandisingRuleTypes.RuleAction[] = [
  MerchandisingRuleTypes.RuleAction.Demote,
  MerchandisingRuleTypes.RuleAction.Promote,
  MerchandisingRuleTypes.RuleAction.Include,
  MerchandisingRuleTypes.RuleAction.Exclude,
];

export const defaultRuleSettings: MerchandisingRuleSettings = {
  subRulesConditionTypes: defaultConditionTypes,
  conditionTypesOptions: defaultConditionTypesOptions,
  conditionTypes: [ConditionRowType.ApplyWhenCondition],
  actions: defaultRuleActions,
};

export const syteProductToRuleSettingsMap: SyteProductToRuleSettingsMap = {
  [SyteProductTypes.SyteProductType.SimilarItems]: defaultRuleSettings,
  [SyteProductTypes.SyteProductType.ShopTheLook]: defaultRuleSettings,
  [SyteProductTypes.SyteProductType.DiscoveryButton]: defaultRuleSettings,
  [SyteProductTypes.SyteProductType.Personalization]: defaultRuleSettings,
  [SyteProductTypes.SyteProductType.EmailMarketing]: defaultRuleSettings,
  [SyteProductTypes.SyteProductType.AugmentedSearch]: {
    actions: [...defaultRuleActions, MerchandisingRuleTypes.RuleAction.Redirect],
    subRulesConditionTypes: [
      MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotContain,
      MerchandisingRuleTypes.MerchandisingRuleSubType.Contains,
      MerchandisingRuleTypes.MerchandisingRuleSubType.Equals,
      MerchandisingRuleTypes.MerchandisingRuleSubType.HasValue,
      MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotHaveValue,
      MerchandisingRuleTypes.MerchandisingRuleSubType.HigherThan,
      MerchandisingRuleTypes.MerchandisingRuleSubType.LowerThan,
      MerchandisingRuleTypes.MerchandisingRuleSubType.IsBetween,
    ],
    conditionTypesOptions: {
      [ConditionRowType.SearchCondition]: [
        MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotContain,
        MerchandisingRuleTypes.MerchandisingRuleSubType.Contains,
        MerchandisingRuleTypes.MerchandisingRuleSubType.Equals,
        MerchandisingRuleTypes.MerchandisingRuleSubType.HasValue,
        MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotHaveValue,
        MerchandisingRuleTypes.MerchandisingRuleSubType.HigherThan,
        MerchandisingRuleTypes.MerchandisingRuleSubType.LowerThan,
        MerchandisingRuleTypes.MerchandisingRuleSubType.IsBetween,
      ],
    },
    conditionTypes: [ConditionRowType.SearchCondition],
  },
  [SyteProductTypes.SyteProductType.VisualSearch]: {
    actions: defaultRuleActions,
    subRulesConditionTypes: [
      MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotContain,
      MerchandisingRuleTypes.MerchandisingRuleSubType.Contains,
      MerchandisingRuleTypes.MerchandisingRuleSubType.Equals,
      MerchandisingRuleTypes.MerchandisingRuleSubType.HigherThan,
      MerchandisingRuleTypes.MerchandisingRuleSubType.LowerThan,
      MerchandisingRuleTypes.MerchandisingRuleSubType.HasValue,
      MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotHaveValue,
      MerchandisingRuleTypes.MerchandisingRuleSubType.IsBetween,
    ],
    conditionTypesOptions: {
      [ConditionRowType.ApplyWhenCondition]: [
        MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotContain,
        MerchandisingRuleTypes.MerchandisingRuleSubType.Contains,
        MerchandisingRuleTypes.MerchandisingRuleSubType.Equals,
        MerchandisingRuleTypes.MerchandisingRuleSubType.HigherThan,
        MerchandisingRuleTypes.MerchandisingRuleSubType.LowerThan,
        MerchandisingRuleTypes.MerchandisingRuleSubType.HasValue,
        MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotHaveValue,
        MerchandisingRuleTypes.MerchandisingRuleSubType.IsBetween,
      ],
    },
    conditionTypes: null,
  },
  [SyteProductTypes.SyteProductType.Collections]: {
    actions: [...defaultRuleActions, MerchandisingRuleTypes.RuleAction.PinToPosition],
    subRulesConditionTypes: defaultConditionTypes,
    conditionTypesOptions: {
      [ConditionRowType.ApplyWhenCondition]: [
        MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotContain,
        MerchandisingRuleTypes.MerchandisingRuleSubType.Contains,
        MerchandisingRuleTypes.MerchandisingRuleSubType.Equals,
        MerchandisingRuleTypes.MerchandisingRuleSubType.HigherThan,
        MerchandisingRuleTypes.MerchandisingRuleSubType.LowerThan,
        MerchandisingRuleTypes.MerchandisingRuleSubType.IsBetween,
        MerchandisingRuleTypes.MerchandisingRuleSubType.HasValue,
        MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotHaveValue,
      ],
      [ConditionRowType.FilterByCondition]: [
        MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotContain,
        MerchandisingRuleTypes.MerchandisingRuleSubType.Contains,
        MerchandisingRuleTypes.MerchandisingRuleSubType.Equals,
        MerchandisingRuleTypes.MerchandisingRuleSubType.HasValue,
        MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotHaveValue,
      ],
    },
    conditionTypes: [ConditionRowType.ApplyWhenCondition, ConditionRowType.FilterByCondition],
  },
};

export const ruleActionsWithWeight = new Set([
  MerchandisingRuleTypes.RuleAction.Promote,
  MerchandisingRuleTypes.RuleAction.Demote,
]);

export const weightMin = 0;
export const weightMax = 100;

export const dataFieldFormToConditionTypeMap: DataFieldFormToConditionTypeMap = {
  [FeedDataFieldType.Boolean]: [
    MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotHaveValue,
    MerchandisingRuleTypes.MerchandisingRuleSubType.HasValue,
    MerchandisingRuleTypes.MerchandisingRuleSubType.EqualsSource,
    MerchandisingRuleTypes.MerchandisingRuleSubType.Equals,
    MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotContain,
  ],
  [FeedDataFieldType.Text]: [
    MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotHaveValue,
    MerchandisingRuleTypes.MerchandisingRuleSubType.HasValue,
    MerchandisingRuleTypes.MerchandisingRuleSubType.Contains,
    MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotContain,
  ],
  [FeedDataFieldType.Keyword]: [
    MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotHaveValue,
    MerchandisingRuleTypes.MerchandisingRuleSubType.HasValue,
    MerchandisingRuleTypes.MerchandisingRuleSubType.EqualsSource,
    MerchandisingRuleTypes.MerchandisingRuleSubType.Equals,
    MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotContain,
  ],
  [FeedDataFieldType.Integer]: [
    MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotHaveValue,
    MerchandisingRuleTypes.MerchandisingRuleSubType.HasValue,
    MerchandisingRuleTypes.MerchandisingRuleSubType.Equals,
    MerchandisingRuleTypes.MerchandisingRuleSubType.EqualsSource,
    MerchandisingRuleTypes.MerchandisingRuleSubType.LowerThan,
    MerchandisingRuleTypes.MerchandisingRuleSubType.HigherThan,
    MerchandisingRuleTypes.MerchandisingRuleSubType.RelativeRange,
    MerchandisingRuleTypes.MerchandisingRuleSubType.IsBetween,
    MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotContain,
  ],
  [FeedDataFieldType.Long]: [
    MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotHaveValue,
    MerchandisingRuleTypes.MerchandisingRuleSubType.HasValue,
    MerchandisingRuleTypes.MerchandisingRuleSubType.Equals,
    MerchandisingRuleTypes.MerchandisingRuleSubType.EqualsSource,
    MerchandisingRuleTypes.MerchandisingRuleSubType.LowerThan,
    MerchandisingRuleTypes.MerchandisingRuleSubType.HigherThan,
    MerchandisingRuleTypes.MerchandisingRuleSubType.RelativeRange,
    MerchandisingRuleTypes.MerchandisingRuleSubType.IsBetween,
    MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotContain,
  ],
  [FeedDataFieldType.Float]: [
    MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotHaveValue,
    MerchandisingRuleTypes.MerchandisingRuleSubType.HasValue,
    MerchandisingRuleTypes.MerchandisingRuleSubType.Equals,
    MerchandisingRuleTypes.MerchandisingRuleSubType.EqualsSource,
    MerchandisingRuleTypes.MerchandisingRuleSubType.LowerThan,
    MerchandisingRuleTypes.MerchandisingRuleSubType.HigherThan,
    MerchandisingRuleTypes.MerchandisingRuleSubType.RelativeRange,
    MerchandisingRuleTypes.MerchandisingRuleSubType.IsBetween,
    MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotContain,
  ],
};
