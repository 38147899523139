import { merchandisingRulesService, Ranking } from 'src/services';
import { SyteProductType } from 'src/services/src/service/types/syte-products';
import { MerchandiseRule } from 'src/components-bl/FilterCard/types';
import {
  DuplicateCollectionActionTypes,
  DuplicateCollectionActionPayload,
  DuplicateCollectionRequestActionPayload,
  DuplicateCollectionSuccessActionPayload,
  DuplicateCollectionErrorActionPayload,
  DuplicateCollectionErrorAction,
  DuplicateCollectionRequestAction,
  DuplicateCollectionSuccessAction,
} from './types';
import { Dispatch } from '../../../types';
import { collectionsService } from '../../../../services/src/service/collections';
import { FallbackType } from '../../EditCollectionRules/CollectionFallback/enums';

const DuplicateCollectionActions = {
  Request(payload: DuplicateCollectionRequestActionPayload): DuplicateCollectionRequestAction {
    return {
      type: DuplicateCollectionActionTypes.Request,
      payload,
    };
  },

  Success(payload: DuplicateCollectionSuccessActionPayload): DuplicateCollectionSuccessAction {
    return {
      type: DuplicateCollectionActionTypes.Success,
      payload,
    };
  },

  DuplicateCollectionError(
    payload: DuplicateCollectionErrorActionPayload
  ): DuplicateCollectionErrorAction {
    return {
      type: DuplicateCollectionActionTypes.Error,
      payload,
    };
  },
};

async function fetchAllMerchandisingRules({
  shopId,
  entityId,
  variantId,
}: {
  shopId: number;
  entityId: string;
  variantId?: string;
}): Promise<MerchandiseRule[]> {
  const allRules = [];

  const pageSize = 100;

  let skip = 0;

  while (true) {
    // eslint-disable-next-line no-await-in-loop
    const { merchandisingRules } = await merchandisingRulesService.getRules({
      shopId,
      entityId,
      variantId,
      skip,
      limit: pageSize,
    });

    allRules.push(...merchandisingRules);

    const isLastPage = merchandisingRules.length < pageSize;

    if (isLastPage) {
      break;
    } else {
      skip = +pageSize;
    }
  }

  return allRules;
}
export const DuplicateCollectionActionMethods = {
  DuplicateCollection(payload: DuplicateCollectionActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(DuplicateCollectionActions.Request(payload));

        const { shopId, collectionId: entityId, variantId } = payload;
        const rankingService = new Ranking();
        const syteProduct = SyteProductType.Collections;

        const sourceCollection = await collectionsService.getCollection(payload);
        const rules = await collectionsService.getRuleGroups(payload);
        const merchRules = await fetchAllMerchandisingRules({
          shopId,
          entityId,
          variantId,
        });
        const ranking = await rankingService.get({
          shopId,
          entityId,
          variantId,
          syteProduct,
        });

        const newCollectionName = `${sourceCollection.name}_copy_${new Date()
          .getTime()
          .toString(36)}`;

        const collection = await collectionsService.createCollection({
          shopId,
          name: newCollectionName,
          placement: sourceCollection.placement,
          ruleGroups: sourceCollection.ruleGroups,
          variantId,
          fallback: {
            method: sourceCollection.fallback?.method ?? FallbackType.None,
            targetField: sourceCollection.fallback?.targetField ?? undefined,
            collectionId: sourceCollection.fallback?.collectionId ?? undefined,
            sortingOrder: sourceCollection.fallback?.sortingOrder ?? undefined,
          },
        });
        const { id: collectionId } = collection;

        const rulesSaver = collectionsService.saveCollectionRulesDiff({
          shopId,
          collectionId,
          variantId,
          collectionName: newCollectionName,
          body: {
            addedRuleGroups: rules.map(g => ({ ...g, collectionId, shopId })),
          },
        });
        const merchSavers = merchRules.map(rule =>
          merchandisingRulesService.create({
            shopId,
            rule: {
              ...rule,
              entityId: collectionId,
            },
            variantId,
          })
        );
        const rankingSaver = rankingService.update({
          shopId,
          syteProduct,
          entityId: collectionId,
          variantId,
          ...ranking,
        });

        await Promise.all([rulesSaver, ...merchSavers, rankingSaver] as Promise<never>[]);

        dispatch(DuplicateCollectionActions.Success({ collection }));
      } catch (error) {
        dispatch(DuplicateCollectionActions.DuplicateCollectionError({ error }));
      }
    };
  },
};
