import React, { useCallback, useMemo, useState } from 'react';
import { NotificationType, TableV2, TableV2InstanceProps } from 'src/components-dummy';
import { ICatalogFeedUploadLog } from 'src/services';
import { format } from 'date-fns';
import { css } from '@emotion/react';
import { DATE_FORMAT } from 'src/components-bl/CatalogManager/CatalogManager.config';
import { Dispatch } from 'src/components-bl/types';
import {
  CatalogUploadLogsTableStatusTextEnum,
  tableColumnsAdmin,
  tableColumnsClient,
} from './CatalogFeedUploadLogList.config';
import { CatalogFeedUploadLogTableBodyRow } from './types';
import { CatalogFeedUploadLogTableRow } from './CatalogFeedUploadLogTableRow';
import { formatDuration } from './CatalogFeedUploadLogList.helpers';
import {
  CatalogFeedUploadLogListFilters,
  CatalogFeedUploadLogListFiltersState,
} from '../CatalogFeedUploadLogListFilters/CatalogFeedUploadLogListFilters';
import { catalogFeedUploadLogActions } from '../../CatalogFeedUploadLog.actions';

const NOT_AVAILABLE = '-';
interface CatalogFeedUploadLogListProps {
  catalogFeedUploadLogs: ICatalogFeedUploadLog[];
  isAdminUser: boolean;
  navigateToFeedErrorReport: (startDateInMilliseconds: string) => void;
  shopId: number;
  catalogName: string;
  dispatch: Dispatch;
}

export const CatalogFeedUploadLogList = ({
  catalogFeedUploadLogs,
  navigateToFeedErrorReport,
  isAdminUser,
  shopId,
  catalogName,
  dispatch,
}: CatalogFeedUploadLogListProps): JSX.Element => {
  const [selectedFiltersState, setSelectedFiltersState] =
    useState<CatalogFeedUploadLogListFiltersState>({});

  const tableData: CatalogFeedUploadLogTableBodyRow[] = useMemo(() => {
    return (
      catalogFeedUploadLogs
        .filter(log => {
          // Filter by status
          if (
            selectedFiltersState.status?.value &&
            log.status !== selectedFiltersState.status?.value
          ) {
            return false;
          }

          // Filter by dateRange
          if (
            selectedFiltersState.dateRange?.value?.startDate &&
            selectedFiltersState.dateRange?.value?.endDate
          ) {
            const { startDate: dateRangeStartDate, endDate: dateRangeEndDate } =
              selectedFiltersState.dateRange.value;

            const isStartDateInFilterRange = log.startDate >= dateRangeStartDate;
            const isEndDateInFilterRange = log.endDate <= dateRangeEndDate;

            if (!isStartDateInFilterRange || !isEndDateInFilterRange) {
              return false;
            }
          }

          return true;
        })
        // Descending (latest to earliest)
        .sort((a, b) => b.endDate.getTime() - a.endDate.getTime())
        .map((catalogFeedUploadLog: ICatalogFeedUploadLog) => {
          const {
            startDate,
            endDate,
            status,
            numberOfIndexedProducts,
            numberOfFailedProducts,
            errorReason,
            errorReasonInternal,
            actionRequired,
            actionRequiredInternal,
          } = catalogFeedUploadLog;

          const actionsColumnObject = {
            rowActions: true,
          };

          const baseRowData = {
            startDate: startDate ? format(startDate, DATE_FORMAT) : '',
            endDate: startDate ? format(endDate, DATE_FORMAT) : '',
            durationInMilliseconds: formatDuration(endDate.getTime() - startDate.getTime()),
            originalData: catalogFeedUploadLog,
            status: CatalogUploadLogsTableStatusTextEnum[status],
            numberOfIndexedProducts: numberOfIndexedProducts?.toLocaleString() || NOT_AVAILABLE,
            numberOfFailedProducts: numberOfFailedProducts?.toLocaleString() || NOT_AVAILABLE,
            errorReason: errorReason || NOT_AVAILABLE,
            actionRequired: actionRequired || NOT_AVAILABLE,
          };

          if (isAdminUser) {
            const adminRowData = {
              ...baseRowData,
              errorReasonInternal: errorReasonInternal || NOT_AVAILABLE,
              actionRequiredInternal: actionRequiredInternal || NOT_AVAILABLE,
              ...actionsColumnObject,
            };

            return adminRowData;
          }

          const clientRowData = {
            ...baseRowData,
            ...actionsColumnObject,
          };

          return clientRowData;
        })
    );
  }, [catalogFeedUploadLogs, selectedFiltersState, isAdminUser]);

  const columnsConfig = isAdminUser ? tableColumnsAdmin : tableColumnsClient;

  const tableColumnsConfig = useMemo(() => {
    return Object.values(columnsConfig);
  }, [columnsConfig]);

  const onNavigateToErrorReport = useCallback(
    (startDateInMilliseconds: string) => {
      navigateToFeedErrorReport(startDateInMilliseconds);
    },
    [navigateToFeedErrorReport]
  );

  const onChangesFilterChanged = useCallback(
    (selectedFilters: CatalogFeedUploadLogListFiltersState) => {
      setSelectedFiltersState(selectedFilters);
    },
    []
  );

  const onDownloadFile = useCallback(
    ({ jobId }: { jobId: string }) => {
      dispatch(
        catalogFeedUploadLogActions.notification({
          type: NotificationType.Success,
          customMessage:
            'Your download is in progress. Due to the file size, this may take some time. Thank you for your patience.',
        })
      );

      dispatch(
        catalogFeedUploadLogActions.downloadUploadLogFeedFile({ shopId, catalogName, jobId })
      );
    },
    [catalogName, dispatch, shopId]
  );

  return (
    <>
      <CatalogFeedUploadLogListFilters
        onChange={onChangesFilterChanged}
        filters={selectedFiltersState}
      />
      <TableV2<CatalogFeedUploadLogTableBodyRow>
        options={{ columns: tableColumnsConfig, data: tableData }}
        customOptions={{
          shouldUseFlexLayout: true,
          shouldUseResizeColumns: true,
        }}
      >
        {({
          getTableBodyProps,
          headerGroups,
          rows,
          prepareRow,
          state,
        }: TableV2InstanceProps<CatalogFeedUploadLogTableBodyRow>) => {
          const headerGroup = headerGroups[0];

          const isResizingColumn = !!state.columnResizing.isResizingColumn;

          return (
            <>
              <TableV2.Head {...headerGroup.getHeaderGroupProps()} isSticky>
                {headerGroup.headers.map(column => (
                  <TableV2.HeadRow {...column.getHeaderProps()} isResizable key={column.id}>
                    <TableV2.HeadRowCellText key={`${isResizingColumn}`}>
                      {column.render('Header')}
                    </TableV2.HeadRowCellText>
                    {(columnsConfig as Record<string, any>)[column.id].canResize ===
                    false ? null : (
                      <TableV2.HeaderRowCellResizableCursor column={column} />
                    )}
                  </TableV2.HeadRow>
                ))}
              </TableV2.Head>
              <TableV2.Body
                {...getTableBodyProps()}
                css={css`
                  overflow-y: auto;
                  scrollbar-gutter: auto;
                `}
              >
                {!rows?.length ? (
                  <TableV2.NoResults width={291} />
                ) : (
                  rows.map(row => {
                    prepareRow(row);
                    return (
                      <CatalogFeedUploadLogTableRow
                        key={`${row.original.originalData.jobId}_${
                          row.original.originalData.status
                        }_${row.original.originalData.startDate.getTime()}_${row.original.originalData.endDate.getTime()}`}
                        row={row}
                        isAdminUser={isAdminUser}
                        navigateToFeedErrorReport={onNavigateToErrorReport}
                        isResizingColumn={isResizingColumn}
                        onDownloadFile={onDownloadFile}
                      />
                    );
                  })
                )}
              </TableV2.Body>
            </>
          );
        }}
      </TableV2>
    </>
  );
};
