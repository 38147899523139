import React, { useCallback } from 'react';
import { ParseJoiValidateResponse } from 'src/utils';
import { ValidateArguments } from 'src/hooks';
import { ShoppableBannerDraft } from 'src/components-bl/Galleries/ShoppableBanner/EditShoppableBannerPage/types';
import {
  TextBoxStyled,
  TextSectionStyled,
} from 'src/components-bl/Galleries/CustomInspirations/EditCustomInspirationsGalleryPage/components/EditGallerySideBar/components/TextSection/TextSection.styles';

interface URLSectionProps {
  bannerLink: string | undefined;
  bannerTextLink: string | undefined;
  onChange: (newBannerLinks: { bannerLink?: string; bannerTextLink?: string }) => void;
  validate: (
    args: ValidateArguments<Pick<ShoppableBannerDraft, 'bannerLink' | 'bannerTextLink'>>
  ) => void;
  errors: ParseJoiValidateResponse<Pick<ShoppableBannerDraft, 'bannerLink' | 'bannerTextLink'>>;
}

export const URLSection = ({
  bannerLink,
  bannerTextLink,
  onChange,
  validate,
  errors,
}: URLSectionProps): JSX.Element => {
  const handleBannerLinkChange = useCallback(
    (newBannerLink: string) => {
      onChange({ bannerLink: newBannerLink });
      validate({ dataToValidate: { bannerLink: newBannerLink } });
    },
    [onChange]
  );

  const handleBannerTextLink = useCallback(
    (newBannerTextLink: string) => {
      onChange({ bannerTextLink: newBannerTextLink });
      validate({ dataToValidate: { bannerTextLink } });
    },
    [onChange]
  );

  return (
    <TextSectionStyled>
      <TextBoxStyled
        label='Banner URL Navigation'
        value={bannerLink || ''}
        onChange={handleBannerLinkChange}
        placeholder='Enter Target URL...'
        error={errors.bannerLink?.message}
      />
      <TextBoxStyled
        label='Banner Text Link (Optional)'
        value={bannerTextLink || ''}
        onChange={handleBannerTextLink}
        placeholder='Enter Banner Link Text'
        error={errors.bannerTextLink?.message}
      />
    </TextSectionStyled>
  );
};
