import { CampaignStrategy } from '../types/campaign-strategy';

export const recommendationTypeToDisplayName: Record<CampaignStrategy, string> = {
  [CampaignStrategy.Similars]: 'Similar Items',
  [CampaignStrategy.Collections]: 'Collections',
  [CampaignStrategy.ShopTheLook]: 'Shop The Look',
  [CampaignStrategy.RecentlyViewed]: 'Recently Viewed',
  [CampaignStrategy.FrequentlyBoughtTogether]: 'Frequently Bought Together',
  [CampaignStrategy.CompleteTheLook]: 'Complete The Look',
};
