import React from 'react';
import { useOperatorDropdown } from './useOperatorDropdown';
import { Select, SelectType, MenuItem, SelectOnChangeEvent } from '../../../../../components-dummy';
import { Rule, RuleGroup } from '../../../types';
import { ParseJoiValidateResponse } from '../../../../../utils';
import { ShopDataField } from '../../../../../services';

interface OperatorDropdownProps {
  data: Rule;
  onChange: (rule: Rule) => void;
  dataFields: ShopDataField[] | undefined;
  errors: ParseJoiValidateResponse<{ ruleGroups: RuleGroup[] }>;
  isReadOnly?: boolean;
}

export const OperatorDropdown: React.FC<OperatorDropdownProps> = ({
  data,
  dataFields,
  onChange,
  errors,
  isReadOnly = false,
}) => {
  const { conditionDropDownValues, onOperatorChange } = useOperatorDropdown({
    data,
    dataFields,
    onChange,
  });

  const handleChange: SelectOnChangeEvent = event => {
    onOperatorChange(event.target.value as Rule['operator']);
  };

  return (
    <Select
      type={SelectType.Menu}
      value={data.operator}
      onChange={handleChange}
      disabled={!data?.field || isReadOnly}
      className='syte-collection-rule-select'
      isError={!!errors?.operator}
    >
      {conditionDropDownValues.map((option: { value?: any; text: string }) => (
        <MenuItem key={option.value} value={option.value}>
          {option.text}
        </MenuItem>
      ))}
    </Select>
  );
};
