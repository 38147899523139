import { useAppSelector } from 'src/hooks';
import { CustomInspirationsGallery, GalleryType, ShoppableBanner } from 'src/services';

export const useGallerySelector = (): CustomInspirationsGallery | ShoppableBanner | undefined => {
  return useAppSelector(state => {
    const gallery = state.galleries.currentGallery;

    if (!gallery) return undefined;

    if (
      gallery.galleryType === GalleryType.CustomInspirations &&
      'layout' in gallery &&
      gallery.layout?.type === 'BANNER'
    ) {
      return {
        ...gallery,
        galleryType: GalleryType.ShoppableBanner,
      } as ShoppableBanner;
    } else if (gallery.galleryType === GalleryType.CustomInspirations) {
      return gallery as CustomInspirationsGallery;
    }

    return undefined;
  });
};
