import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { RoutedComponentProps } from 'src/app-routes';
import { CustomInspirationsGallery, ShoppableBanner } from 'src/services';
import { generatePath } from 'react-router';
import {
  Button,
  ConfirmationDialog,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { Dispatch } from '../../../types';
import { addImagesModalFormActions } from './Actions';
import {
  ButtonStyled,
  MultilineTextBoxStyled,
  TypographyStyled,
} from './AddImagesModalForm.styles';
import { parseUrls } from './helpers';
import { CustomInspirationsGalleryUpdatePayload } from 'src/services/src/service/galleries/types';

interface AddImagesModalFormProps extends RoutedComponentProps {
  shopId: number;
  dispatch: Dispatch;
  gallery: CustomInspirationsGallery | ShoppableBanner;
  allowedToAddImages: number | undefined;
}

export const AddImagesModalForm = ({
  shopId,
  dispatch,
  permittedRouteMap,
  gallery,
  allowedToAddImages,
}: AddImagesModalFormProps): JSX.Element => {
  const [formState, setFormState] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  const parsedUrls = useMemo(() => {
    if (!allowedToAddImages) return;

    try {
      const result = parseUrls(formState);

      if (result.length > allowedToAddImages) {
        setErrorMessage('Max number of images reached');
        return;
      }

      setErrorMessage(undefined);

      return result;
    } catch {
      setErrorMessage('Some of the links are incorrect');
      return undefined;
    }
  }, [formState, setErrorMessage, allowedToAddImages]);

  const isDirty = useMemo(() => {
    return formState !== '';
  }, [formState]);

  const onModalCancel = useCallback(() => {
    if (permittedRouteMap.editCustomInspirationsGallery) {
      dispatch(
        addImagesModalFormActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.editCustomInspirationsGallery.path, {
            shopId,
            galleryId: gallery.id,
          }),
        })
      );
    }
  }, [dispatch, shopId, gallery.id, permittedRouteMap.editCustomInspirationsGallery]);

  const textAreaError = useMemo(() => isDirty && errorMessage, [isDirty, errorMessage]);

  const onSave = useCallback(async () => {
    if (textAreaError || !parsedUrls) {
      return;
    }

    const mappedNewImages = parsedUrls.map(imageUrl => ({
      imageUrl,
      tags: [],
      aiSuggestedTags: [],
      isDetectingTags: false,
    }));

    const mappedPayload: CustomInspirationsGalleryUpdatePayload = {
      images: [...gallery.images, ...mappedNewImages],
    };

    setIsSaveInProgress(true);
    try {
      (
        dispatch(
          addImagesModalFormActions.updateGallery({
            galleryId: gallery.id,
            payload: mappedPayload,
            shopId,
          })
        ) as any
      ).unwrap();

      setFormState('');
      dispatch(addImagesModalFormActions.notifyIsDirty({ isDirty: false }));
      onModalCancel();
    } catch (error: unknown) {
      console.error(error);
    }
    setIsSaveInProgress(false);
  }, [
    dispatch,
    setIsSaveInProgress,
    setFormState,
    gallery.images,
    gallery.id,
    shopId,
    onModalCancel,
    textAreaError,
    parsedUrls,
  ]);

  useEffect(() => {
    dispatch(addImagesModalFormActions.notifyIsDirty({ isDirty }));
  }, [dispatch, isDirty]);

  return (
    <ConfirmationDialog onCancel={onModalCancel}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Add images</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        <TypographyStyled type={TypographyType.Paragraph} variant={TypographyVariant.LargeMedium}>
          Type or paste the URL of a single image or enter multiple URLs at once. Different URLs
          must be separated by a comma, a space or a new line.
        </TypographyStyled>
        <MultilineTextBoxStyled
          value={formState}
          error={textAreaError}
          onChange={setFormState}
          placeholder='Type a url here...'
          multiline
          maxRows={20}
          shouldStyle={formState.length > 0}
        />
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonStyled
          variant='primary'
          onClick={onSave}
          disabled={!(!textAreaError && isDirty)}
          loading={isSaveInProgress}
        >
          Continue
        </ButtonStyled>
        <Button variant='tertiary' onClick={onModalCancel}>
          Close
        </Button>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
