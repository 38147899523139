import React from 'react';
import { FallbackFieldLabel, FallbackType } from '../enums';
import { SelectChangeEvent } from '@mui/material';
import { SelectField } from './SelectField';

interface FallbackTypeSelectorProps {
  method: FallbackType;
  handleChange: (event: SelectChangeEvent<string>) => void;
  fallbackLabels: Record<FallbackType, string>;
  isReadOnly?: boolean;
}

export const FallbackTypeSelector = ({
  method,
  handleChange,
  fallbackLabels,
  isReadOnly = false,
}: FallbackTypeSelectorProps) => {
  const fallbackTypeOptions = Object.values(FallbackType).map(fallback => ({
    value: fallback,
    text: fallbackLabels[fallback as FallbackType],
  }));

  return (
    <SelectField
      value={method ?? FallbackType.None}
      options={fallbackTypeOptions}
      onChange={handleChange}
      autoFocus={false}
      labelText={FallbackFieldLabel.FALLBACK_TYPE}
      disabled={isReadOnly}
    />
  );
};
