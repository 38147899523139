import styled from '@emotion/styled';
import { TabV2 } from 'src/components-dummy';

export const TabWrapper = styled.div`
  display: flex;
  padding: 0 20px;
`;

export const StyledTab = styled(TabV2)<{ isActive: boolean }>`
  flex: 1;
  background-color: ${({ isActive, theme }) =>
    isActive ? 'transparent' : theme.palette.custom['gray-80']};

  border: ${({ isActive, theme }) =>
    isActive ? 'none' : `1px solid ${theme.palette.custom['gray-60']}`};

  border-top: ${({ theme }) => `1px solid ${theme.palette.custom['gray-60']}}`};

  &.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit {
    color: black;
    opacity: unset;
    font-size: 14px;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-80']};
  background: ${({ theme }) => theme.palette.custom['gray-120']};
  padding: 24px 16px;
  gap: 16px;
`;
