import React from 'react';
import { SelectChangeEvent } from '@mui/material';
import { Collection } from '../../../types';
import { SelectField } from './SelectField';
import { FallbackFieldLabel, FallbackSelectorsPlaceholder } from '../enums';

interface AlternativeCollectionSelectorProps {
  collectionId: string | undefined;
  activeCollectionList: Collection[];
  handleChange: (event: SelectChangeEvent<string>) => void;
  isReadOnly?: boolean;
}

export const AlternativeCollectionSelector = ({
  collectionId,
  activeCollectionList,
  handleChange,
  isReadOnly = false,
}: AlternativeCollectionSelectorProps) => {
  const alternativeCollectionOptions = activeCollectionList.map(collection => ({
    value: collection.id,
    text: collection.name,
  }));

  return (
    <SelectField
      value={collectionId || undefined}
      options={alternativeCollectionOptions}
      onChange={handleChange}
      autoFocus={false}
      labelText={FallbackFieldLabel.FALLBACK_COLLECTION}
      placeholder={FallbackSelectorsPlaceholder.ALTERNATIVE_COLLECTION}
      disabled={isReadOnly}
    />
  );
};
