import React, { useEffect } from 'react';
import { AppRoute, AppSwitch } from 'src/app-routes';
import { generatePath, Redirect } from 'react-router';
import { AvailableIcons, Icon, Link, Page } from 'src/components-dummy';
import { ContainerComponentProps } from 'src/containers/AugSearchFilters/augmented-search-filters.types';
import { SynonymsLanguagesListContainer } from './components/SynonymsLanguagesListContainer';
import { SynonymsTableContainer } from './components/SynonymsTableContainer';
import { useAugmentedSearchCatalog } from '../../hooks/use-augmented-search-catalog';
import { PageTitlesWrapper, SynonymsContainerStyled } from '../SynonymsContainer.styles';
import {
  SynonymLanguage,
  SynonymLanguageCode,
} from 'src/services/src/service/augmented-search/synonyms/types';
import { synonymsActions } from 'src/components-bl';

type SynonymsContainerProps = ContainerComponentProps;

export const SynonymsContainer = ({
  shopId,
  permittedRouteMap,
  dispatch,
}: SynonymsContainerProps): JSX.Element | null => {
  const { hasAugmentedSearchCatalog, availableLanguageCodes } = useAugmentedSearchCatalog({
    shopId,
    dispatch,
  });

  useEffect(() => {
    if (availableLanguageCodes.length === 1 && permittedRouteMap.augmentedSearchSynonymsLanguage) {
      const singleLanguage = availableLanguageCodes[0];
      const language = SynonymLanguageCode[singleLanguage as keyof typeof SynonymLanguage];

      dispatch(
        synonymsActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.augmentedSearchSynonymsLanguage.path, {
            shopId,
            language,
          }),
        })
      );
    }
  }, [availableLanguageCodes, permittedRouteMap, shopId, dispatch]);

  return shopId ? (
    <SynonymsContainerStyled>
      <PageTitlesWrapper>
        <Page.Title>Manage Synonyms</Page.Title>
        <Page.SubTitle>
          Manage synonyms for a specific language.{' '}
          <Link href='https://support.syte.ai/space/ET/3972464648/Synonyms+Management+(Text+Search)'>
            Learn more about Synonyms
            <Icon name={AvailableIcons.ArrowRec} />
          </Link>
        </Page.SubTitle>
      </PageTitlesWrapper>
      <AppSwitch>
        <AppRoute
          route={permittedRouteMap.augmentedSearchSynonymsLanguagesList}
          Component={SynonymsLanguagesListContainer}
          componentProps={{
            permittedRouteMap,
            shopId,
            dispatch,
            hasAugmentedSearchCatalog,
            availableLanguageCodes,
          }}
        />
        <AppRoute
          route={permittedRouteMap.augmentedSearchSynonymsLanguage}
          Component={SynonymsTableContainer}
          componentProps={{
            permittedRouteMap,
            shopId,
            dispatch,
            hasAugmentedSearchCatalog,
            availableLanguageCodes,
          }}
          exact={false}
        />
        {permittedRouteMap.augmentedSearchSynonymsLanguagesList && shopId && (
          <Redirect
            to={generatePath(permittedRouteMap.augmentedSearchSynonymsLanguagesList.path, {
              shopId,
            })}
          />
        )}
      </AppSwitch>
    </SynonymsContainerStyled>
  ) : null;
};
