import React, { useCallback, useMemo } from 'react';
import { Dispatch } from 'src/components-bl/types';
import { SyteProductPlacement } from 'src/services/src/service/types/syte-products';
import {
  GridLayoutSection,
  ImplementationSection,
  SettingsSection,
  TextSection,
} from './components';
import { CustomInspirationGalleryDraft } from '../../types';
import { AccordionSideBar } from 'src/components-dummy/AccordionSideBar';
import { useAccordionExpansion } from 'src/components-dummy/AccordionSideBar/hooks/useAccordionExpansion';

export enum ExpendableSection {
  Layout = 'layout',
  Settings = 'settings',
  Text = 'text',
  Implementation = 'implementation',
}

interface EditGallerySideBarProps {
  gallery: CustomInspirationGalleryDraft | undefined;
  galleryId: string;
  onChange: (partialDraft: Partial<CustomInspirationGalleryDraft>) => void;
  dispatch: Dispatch;
  navigateToList: () => void;
}

const guidelinesLink = 'https://support.syte.ai/space/ET/3149037814';

export const EditGallerySideBar = ({
  gallery,
  onChange,
  galleryId,
  dispatch,
  navigateToList,
}: EditGallerySideBarProps): JSX.Element => {
  const { expandedSection, handleSectionToggle } = useAccordionExpansion(null);
  const isLoading = useMemo(() => !gallery, [gallery]);

  const isEditEnabled = useMemo(
    () => gallery?.images && gallery.images.length > 0,
    [gallery?.images]
  );

  const onDisplayNameChange = useCallback(
    (displayTitle: string): void => {
      onChange({ displayTitle });
    },
    [onChange]
  );

  const onPlacementChange = useCallback(
    (newPlacement: SyteProductPlacement): void => {
      onChange({ placement: newPlacement });
    },
    [onChange]
  );

  return (
    <AccordionSideBar
      header='Shoppable Galleries'
      isLoading={isLoading}
      navigateToList={navigateToList}
      setExpendedSection={handleSectionToggle}
      disabled={!isEditEnabled}
      sections={[
        {
          id: ExpendableSection.Layout,
          title: 'Gallery Layouts',
          content: <GridLayoutSection gallery={gallery} onChange={onChange} />,
          isExpanded: expandedSection === ExpendableSection.Layout,
        },
        {
          id: ExpendableSection.Settings,
          title: 'Gallery Settings',
          content: <SettingsSection gallery={gallery} onChange={onChange} />,
          isExpanded: expandedSection === ExpendableSection.Settings,
        },
        {
          id: ExpendableSection.Text,
          title: 'Gallery Text',
          content: <TextSection value={gallery?.displayTitle} onChange={onDisplayNameChange} />,
          isExpanded: expandedSection === ExpendableSection.Text,
        },
        {
          id: ExpendableSection.Implementation,
          title: 'Gallery Implementation',
          content: (
            <ImplementationSection
              galleryId={galleryId}
              onPlacementChange={onPlacementChange}
              galleryTitle={gallery?.displayTitle}
              placement={gallery?.placement}
              dispatch={dispatch}
              guidelinesLink={guidelinesLink}
              typeName='gallery'
              className='syte-shopper-experiences-galleries'
            />
          ),
          isExpanded: expandedSection === ExpendableSection.Implementation,
        },
      ]}
    />
  );
};
