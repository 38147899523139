import React, { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import {
  Accordion,
  AvailableIcons,
  Button,
  Icon,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import {
  BoughtTogetherSettings,
  DiscoveryBannerSettings,
  EnableUiTest,
  PersonalizationSettings,
  RankingStrategy,
  RecEnginesGeneralSettings,
  RecentlyViewedSettings,
  ShopTheLookSettings,
  SimilarItemsSettings,
  UseKnn,
  variantPanelActionMethods,
} from 'src/components-bl';
import { ExperimentStatus, UserTypes } from 'src/services';
import {
  MerchandisingRulesManagement,
  RulePages,
  useMerchandisingRulesManagement,
} from 'src/components-bl/MerchandisingRules/components/MerchandisingRulesManagement';
import { featureTranslationMap, VariantFeature } from './constants';
import { VariantIsDirtyPayload, VariantPanelProps } from './types';
import './VariantPanel.scss';
import { FormActionButtonsStyled } from './VariantPanel.styles';
import { ResultsModalGeneralSettings } from '../../../../containers/ResultsModalSettings/components';
import { CollectionsManagement } from '../../../Collections/CollectionsManagement';
import { CollectionPage } from '../../../Collections/CollectionPage.config';

const FEATURE_CLASS_NAME_CONTENT = 'syte-variant-panel-item-content';

const COMPONENT_NAME = 'VariantPanel';

export const VariantPanel = ({
  dispatch,
  shopId,
  loggedInUserRole,
  variant,
  dataFields,
  rankingProps,
  merchandisingRulesProps,
  recEnginesGeneralSettingsProps,
  experiment,
  selectedVariantFeatureId,
  personalizationSettingsProps,
  shopTheLookSettingsProps,
  boughtTogetherSettingsProps,
  recentlyViewedSettingsProps,
  similarItemsSettingsProps,
  enableUiTestProps,
  useKnnProps,
  discoveryBannerSettingsProps,
  featureToggles,
  availableRegions,
  isMerchRulesAiTagsEnabled,
  categoryFilterRule,
}: VariantPanelProps): JSX.Element => {
  const [, setIsVariantDirty] = useState(false);

  const rankingFormHeaderRef = useRef<HTMLDivElement>(null);

  const merchandisingRulesSettings = useMerchandisingRulesManagement({ dispatch });

  const collectionsFormHeaderRef = useRef<HTMLDivElement>(null);
  const personalizationSettingsFormHeaderRef = useRef<HTMLDivElement>(null);
  const boughtTogetherSettingsFormHeaderRef = useRef<HTMLDivElement>(null);
  const recentlyViewedSettingsFormHeaderRef = useRef<HTMLDivElement>(null);
  const recEnginesGeneralSettingsFormHeaderRef = useRef<HTMLDivElement>(null);
  const similarItemsSettingsFormHeaderRef = useRef<HTMLDivElement>(null);
  const shopTheLookSettingsFormHeaderRef = useRef<HTMLDivElement>(null);
  const enableUiTestFormHeaderRef = useRef<HTMLDivElement>(null);
  const useKnnFormHeaderRef = useRef<HTMLDivElement>(null);
  const discoveryBannerSettingsFormHeaderRef = useRef<HTMLDivElement>(null);
  const resultsModalSettingsFormHeaderRef = useRef<HTMLDivElement>(null);
  const isUserAdmin = loggedInUserRole === UserTypes.UserRoles.SyteAdmin;

  const selectedVariantId = variant?.id;
  const isReadOnly = experiment?.status !== ExperimentStatus.Created;

  const onIsDirtyChanged = useCallback(
    ({ isDirty }: VariantIsDirtyPayload) => {
      dispatch(variantPanelActionMethods.notifyIsDirty({ isDirty }));
      setIsVariantDirty(isDirty);
    },
    [setIsVariantDirty]
  );

  const VariantFeatureHeader = ({ feature }: { feature: VariantFeature }) => {
    return (
      <div className='syte-variant-panel-feature-item-header-title'>
        <span>
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
            {featureTranslationMap[feature]}
          </Typography>
        </span>
      </div>
    );
  };

  function onFeatureSelectionChange(selectedIds: string[]) {
    const selectedId = selectedIds?.[0];
    dispatch(
      variantPanelActionMethods.setSelectedVariantFeature({ selectedVariantFeatureId: selectedId })
    );
  }

  const renderEditButton = (itemKey: string) => {
    const isCurrentItem = itemKey === selectedVariantFeatureId;
    return (
      <Accordion.Item.Header.Trigger className={classNames('syte-variant-panel-item-trigger')}>
        {isCurrentItem ? (
          <Icon name={AvailableIcons.Back} />
        ) : (
          <Icon
            name={isReadOnly ? AvailableIcons.Back : AvailableIcons.Pencil}
            className={classNames({ 'syte-variant-panel-item-view': isReadOnly })}
          />
        )}
      </Accordion.Item.Header.Trigger>
    );
  };

  const description = isReadOnly
    ? 'This section displays the products list for the experiment. No modifications can be made in read-only mode.'
    : 'Select and modify the product that you think will improve the main metric of your experiment. For the most accurate result, make only one change!';
  return (
    <div className={classNames('syte-variant-panel')}>
      <div className='syte-variant-panel-header'>
        <Typography
          type={TypographyType.Heading}
          variant={TypographyVariant.ExtraSmallMedium}
          className='syte-variant-panel-header-title'
        >
          {isReadOnly ? 'VIEW' : 'EDIT'} {variant?.name.toUpperCase()}
        </Typography>
      </div>
      <Typography
        type={TypographyType.Body}
        variant={TypographyVariant.MediumRegular}
        className='syte-variant-panel-sub-title'
      >
        {description}
      </Typography>
      <Accordion
        className='syte-variant-panel-features-accordion'
        onSelectionChanged={onFeatureSelectionChange}
        expandedIds={selectedVariantFeatureId}
      >
        <Accordion.Item id={VariantFeature.RankingStrategy} observeHeight>
          <Accordion.Item.Header className='syte-variant-panel-feature-item-header'>
            <VariantFeatureHeader feature={VariantFeature.RankingStrategy} />
            <div className='feature-buttons-container' ref={rankingFormHeaderRef} />
            {renderEditButton(VariantFeature.RankingStrategy)}
          </Accordion.Item.Header>
          <Accordion.Item.Content className={FEATURE_CLASS_NAME_CONTENT}>
            <RankingStrategy
              prefixId={COMPONENT_NAME}
              {...rankingProps}
              dispatch={dispatch}
              shopId={shopId}
              dataFields={dataFields}
              selectedVariantId={selectedVariantId}
              onIsDirty={onIsDirtyChanged}
              disabled={isReadOnly}
              formHeaderElementRef={rankingFormHeaderRef}
            />
          </Accordion.Item.Content>
        </Accordion.Item>
        <Accordion.Item
          id={VariantFeature.MerchandisingRules}
          className='variant-panel-merchandising-rules'
          observeHeight
        >
          <Accordion.Item.Header className='syte-variant-panel-feature-item-header'>
            <VariantFeatureHeader feature={VariantFeature.MerchandisingRules} />
            <div className='feature-buttons-container'>
              <FormActionButtonsStyled>
                {merchandisingRulesProps.currentPage !== RulePages.List && (
                  <>
                    <Button onClick={merchandisingRulesSettings.onCancel} variant='tertiary'>
                      Cancel
                    </Button>
                    <Button
                      onClick={merchandisingRulesSettings.onSubmit}
                      disabled={!merchandisingRulesSettings.formStatus.canSubmit}
                      variant='primary'
                      loading={merchandisingRulesSettings.formStatus.isSubmitting}
                    >
                      {selectedVariantId ? 'Apply' : 'Save'}
                    </Button>
                  </>
                )}
              </FormActionButtonsStyled>
            </div>
            {renderEditButton(VariantFeature.MerchandisingRules)}
          </Accordion.Item.Header>
          <Accordion.Item.Content className={FEATURE_CLASS_NAME_CONTENT}>
            <MerchandisingRulesManagement
              shopId={shopId}
              dataFields={dataFields}
              dispatch={dispatch}
              selectedVariantId={selectedVariantId}
              isReadOnly={isReadOnly}
              selectedRule={merchandisingRulesProps.selectedRule}
              duplicateRuleDraft={merchandisingRulesProps.duplicateRuleDraft}
              rules={merchandisingRulesProps.rules}
              onFormStatusChange={merchandisingRulesSettings.onFormStatusChange}
              formApiRef={merchandisingRulesSettings.formApiRef}
              onPageChange={merchandisingRulesSettings.onPageChange}
              currentPage={merchandisingRulesProps.currentPage}
              availableRegions={availableRegions}
              isAiTagsEnabled={isMerchRulesAiTagsEnabled}
              categoryFilterRule={categoryFilterRule}
              overview={merchandisingRulesProps.overview}
              totalRulesCount={merchandisingRulesProps.totalRulesCount}
              featureToggles={featureToggles}
            />
          </Accordion.Item.Content>
        </Accordion.Item>
        {featureToggles?.collections.enabled === true ? (
          <Accordion.Item id={VariantFeature.Collections} observeHeight>
            <Accordion.Item.Header className='syte-variant-panel-feature-item-header'>
              <VariantFeatureHeader feature={VariantFeature.Collections} />
              <div className='feature-buttons-container' ref={collectionsFormHeaderRef} />
              {renderEditButton(VariantFeature.Collections)}
            </Accordion.Item.Header>
            <Accordion.Item.Content className={FEATURE_CLASS_NAME_CONTENT}>
              <CollectionsManagement
                shopId={shopId}
                dispatch={dispatch}
                currentPage={CollectionPage.List}
                selectedVariantId={selectedVariantId}
                formHeaderElementRef={collectionsFormHeaderRef}
                isReadOnly={isReadOnly}
              />
            </Accordion.Item.Content>
          </Accordion.Item>
        ) : (
          <></>
        )}
        {isUserAdmin &&
        (featureToggles?.similarItems.enabled === true ||
          featureToggles?.shopTheLook.enabled === true ||
          featureToggles?.personalization.enabled === true ||
          featureToggles?.frequentlyBoughtTogether.enabled === true ||
          featureToggles?.recentlyViewed.enabled === true) ? (
          <Accordion.Item id={VariantFeature.RecEnginesGeneralSettings}>
            <Accordion.Item.Header className='syte-variant-panel-feature-item-header'>
              <VariantFeatureHeader feature={VariantFeature.RecEnginesGeneralSettings} />
              <div
                className='feature-buttons-container'
                ref={recEnginesGeneralSettingsFormHeaderRef}
              />
              {renderEditButton(VariantFeature.RecEnginesGeneralSettings)}
            </Accordion.Item.Header>
            <Accordion.Item.Content className={FEATURE_CLASS_NAME_CONTENT}>
              <RecEnginesGeneralSettings
                {...recEnginesGeneralSettingsProps}
                shopId={shopId}
                dispatch={dispatch}
                selectedVariantId={selectedVariantId}
                disabled={isReadOnly}
                onIsDirty={onIsDirtyChanged}
                formHeaderElementRef={recEnginesGeneralSettingsFormHeaderRef}
              />
            </Accordion.Item.Content>
          </Accordion.Item>
        ) : (
          <></>
        )}
        {featureToggles?.similarItems.enabled === true ? (
          <Accordion.Item id={VariantFeature.SimilarItemsSettings}>
            <Accordion.Item.Header className='syte-variant-panel-feature-item-header'>
              <VariantFeatureHeader feature={VariantFeature.SimilarItemsSettings} />
              <div className='feature-buttons-container' ref={similarItemsSettingsFormHeaderRef} />
              {renderEditButton(VariantFeature.SimilarItemsSettings)}
            </Accordion.Item.Header>
            <Accordion.Item.Content className={FEATURE_CLASS_NAME_CONTENT}>
              <SimilarItemsSettings
                {...similarItemsSettingsProps}
                shopId={shopId}
                dispatch={dispatch}
                isUserAdmin={isUserAdmin}
                selectedVariantId={selectedVariantId}
                disabled={isReadOnly}
                onIsDirty={onIsDirtyChanged}
                formHeaderElementRef={similarItemsSettingsFormHeaderRef}
              />
            </Accordion.Item.Content>
          </Accordion.Item>
        ) : (
          <></>
        )}
        {featureToggles?.shopTheLook.enabled === true ? (
          <Accordion.Item id={VariantFeature.ShopTheLookSettings}>
            <Accordion.Item.Header className='syte-variant-panel-feature-item-header'>
              <VariantFeatureHeader feature={VariantFeature.ShopTheLookSettings} />
              <div className='feature-buttons-container' ref={shopTheLookSettingsFormHeaderRef} />
              {renderEditButton(VariantFeature.ShopTheLookSettings)}
            </Accordion.Item.Header>
            <Accordion.Item.Content className={FEATURE_CLASS_NAME_CONTENT}>
              <ShopTheLookSettings
                {...shopTheLookSettingsProps}
                shopId={shopId}
                dispatch={dispatch}
                selectedVariantId={selectedVariantId}
                disabled={isReadOnly}
                onIsDirty={onIsDirtyChanged}
                formHeaderElementRef={shopTheLookSettingsFormHeaderRef}
              />
            </Accordion.Item.Content>
          </Accordion.Item>
        ) : (
          <></>
        )}
        {featureToggles?.personalization.enabled === true ? (
          <Accordion.Item id={VariantFeature.PersonalizationSettings}>
            <Accordion.Item.Header className='syte-variant-panel-feature-item-header'>
              <VariantFeatureHeader feature={VariantFeature.PersonalizationSettings} />
              <div
                className='feature-buttons-container'
                ref={personalizationSettingsFormHeaderRef}
              />
              {renderEditButton(VariantFeature.PersonalizationSettings)}
            </Accordion.Item.Header>
            <Accordion.Item.Content className={FEATURE_CLASS_NAME_CONTENT}>
              <PersonalizationSettings
                {...personalizationSettingsProps}
                dataFields={dataFields || []}
                shopId={shopId}
                dispatch={dispatch}
                selectedVariantId={selectedVariantId}
                disabled={isReadOnly}
                onIsDirty={onIsDirtyChanged}
                formHeaderElementRef={personalizationSettingsFormHeaderRef}
              />
            </Accordion.Item.Content>
          </Accordion.Item>
        ) : (
          <></>
        )}
        {featureToggles?.recentlyViewed.enabled === true ? (
          <Accordion.Item id={VariantFeature.RecentlyViewedSettings}>
            <Accordion.Item.Header className='syte-variant-panel-feature-item-header'>
              <VariantFeatureHeader feature={VariantFeature.RecentlyViewedSettings} />
              <div
                className='feature-buttons-container'
                ref={recentlyViewedSettingsFormHeaderRef}
              />
              {renderEditButton(VariantFeature.RecentlyViewedSettings)}
            </Accordion.Item.Header>
            <Accordion.Item.Content className={FEATURE_CLASS_NAME_CONTENT}>
              <RecentlyViewedSettings
                {...recentlyViewedSettingsProps}
                shopId={shopId}
                dispatch={dispatch}
                selectedVariantId={selectedVariantId}
                disabled={isReadOnly}
                onIsDirty={onIsDirtyChanged}
                formHeaderElementRef={recentlyViewedSettingsFormHeaderRef}
              />
            </Accordion.Item.Content>
          </Accordion.Item>
        ) : (
          <></>
        )}

        {isUserAdmin && featureToggles?.frequentlyBoughtTogether.enabled === true ? (
          <Accordion.Item id={VariantFeature.BoughtTogetherSettings}>
            <Accordion.Item.Header className='syte-variant-panel-feature-item-header'>
              <VariantFeatureHeader feature={VariantFeature.BoughtTogetherSettings} />
              <div
                className='feature-buttons-container'
                ref={boughtTogetherSettingsFormHeaderRef}
              />
              {renderEditButton(VariantFeature.BoughtTogetherSettings)}
            </Accordion.Item.Header>
            <Accordion.Item.Content className={FEATURE_CLASS_NAME_CONTENT}>
              <BoughtTogetherSettings
                {...boughtTogetherSettingsProps}
                dataFields={dataFields || []}
                shopId={shopId}
                dispatch={dispatch}
                selectedVariantId={selectedVariantId}
                disabled={isReadOnly}
                onIsDirty={onIsDirtyChanged}
                formHeaderElementRef={boughtTogetherSettingsFormHeaderRef}
              />
            </Accordion.Item.Content>
          </Accordion.Item>
        ) : (
          <></>
        )}
        {featureToggles?.discoveryBanner.enabled === true ? (
          <Accordion.Item id={VariantFeature.DiscoveryBannerSettings}>
            <Accordion.Item.Header className='syte-variant-panel-feature-item-header'>
              <VariantFeatureHeader feature={VariantFeature.DiscoveryBannerSettings} />
              <div
                className='feature-buttons-container'
                ref={discoveryBannerSettingsFormHeaderRef}
              />
              {renderEditButton(VariantFeature.DiscoveryBannerSettings)}
            </Accordion.Item.Header>
            <Accordion.Item.Content className={FEATURE_CLASS_NAME_CONTENT}>
              <DiscoveryBannerSettings
                {...discoveryBannerSettingsProps}
                shopId={shopId}
                dispatch={dispatch}
                selectedVariantId={selectedVariantId}
                disabled={isReadOnly}
                onIsDirty={onIsDirtyChanged}
                formHeaderElementRef={discoveryBannerSettingsFormHeaderRef}
              />
            </Accordion.Item.Content>
          </Accordion.Item>
        ) : (
          <></>
        )}
        <Accordion.Item id={VariantFeature.ResultsModalSettings} observeHeight>
          <Accordion.Item.Header className='syte-variant-panel-feature-item-header'>
            <VariantFeatureHeader feature={VariantFeature.ResultsModalSettings} />
            <div className='feature-buttons-container' ref={resultsModalSettingsFormHeaderRef} />
            {renderEditButton(VariantFeature.ResultsModalSettings)}
          </Accordion.Item.Header>
          <Accordion.Item.Content className={FEATURE_CLASS_NAME_CONTENT}>
            <ResultsModalGeneralSettings
              shopId={shopId}
              dispatch={dispatch}
              selectedVariantId={selectedVariantId}
              formHeaderRef={resultsModalSettingsFormHeaderRef}
              role={loggedInUserRole}
            />
          </Accordion.Item.Content>
        </Accordion.Item>
        {isUserAdmin ? (
          <Accordion.Item id={VariantFeature.EnableUiTest}>
            <Accordion.Item.Header className='syte-variant-panel-feature-item-header'>
              <VariantFeatureHeader feature={VariantFeature.EnableUiTest} />
              <div className='feature-buttons-container' ref={enableUiTestFormHeaderRef} />
              {renderEditButton(VariantFeature.EnableUiTest)}
            </Accordion.Item.Header>
            <Accordion.Item.Content className={FEATURE_CLASS_NAME_CONTENT}>
              <EnableUiTest
                {...enableUiTestProps}
                shopId={shopId}
                dispatch={dispatch}
                selectedVariantId={selectedVariantId}
                disabled={isReadOnly}
                onIsDirty={onIsDirtyChanged}
                formHeaderElementRef={enableUiTestFormHeaderRef}
              />
            </Accordion.Item.Content>
          </Accordion.Item>
        ) : (
          <></>
        )}
        {isUserAdmin ? (
          <Accordion.Item id={VariantFeature.UseKnn}>
            <Accordion.Item.Header className='syte-variant-panel-feature-item-header'>
              <VariantFeatureHeader feature={VariantFeature.UseKnn} />
              <div className='feature-buttons-container' ref={useKnnFormHeaderRef} />
              {renderEditButton(VariantFeature.UseKnn)}
            </Accordion.Item.Header>
            <Accordion.Item.Content className={FEATURE_CLASS_NAME_CONTENT}>
              <UseKnn
                {...useKnnProps}
                shopId={shopId}
                dispatch={dispatch}
                selectedVariantId={selectedVariantId}
                disabled={isReadOnly}
                onIsDirty={onIsDirtyChanged}
                formHeaderElementRef={useKnnFormHeaderRef}
              />
            </Accordion.Item.Content>
          </Accordion.Item>
        ) : (
          <></>
        )}
      </Accordion>
    </div>
  );
};
