import React, { useCallback } from 'react';
import { AppRoute, AppSwitch, RoutedComponentProps } from 'src/app-routes';
import { Redirect, generatePath } from 'react-router';
import { EmailMarketingTableContainer } from './components/EmailMarketingTableContainer';
import { CreateCampaignContainer, EditCampaignContainer } from './components';
import { useAppDispatch } from 'src/hooks';
import { campaignModalPageActions } from 'src/components-bl/EmailMarketing/CampaignModalPage/Actions';

type EmailMarketingContainerProps = RoutedComponentProps & { shopId: number | undefined };

export const EmailMarketingContainer = ({
  shopId,
  permittedRouteMap,
}: EmailMarketingContainerProps): JSX.Element | null => {
  const dispatch = useAppDispatch();

  const navigateToCampaigns = useCallback(() => {
    if (!permittedRouteMap.emailMarketing) return;

    dispatch(
      campaignModalPageActions.navigateTo({
        navigateTo: generatePath(permittedRouteMap.emailMarketing.path, { shopId }),
      })
    );
    dispatch(campaignModalPageActions.resetCurrentCampaign());
  }, [shopId, permittedRouteMap.emailMarketing, dispatch]);

  return shopId ? (
    <AppSwitch>
      <AppRoute
        route={permittedRouteMap.emailMarketing}
        Component={EmailMarketingTableContainer}
        componentProps={{ permittedRouteMap, shopId, dispatch }}
        exact
      />
      <AppRoute
        route={permittedRouteMap.createCampaign}
        Component={CreateCampaignContainer}
        componentProps={{ permittedRouteMap, shopId, dispatch, navigateToCampaigns }}
        exact
      />
      <AppRoute
        route={permittedRouteMap.editCampaign}
        Component={EditCampaignContainer}
        componentProps={{ permittedRouteMap, shopId, dispatch, navigateToCampaigns }}
        exact
      />
      {permittedRouteMap.emailMarketing && shopId && (
        <Redirect to={generatePath(permittedRouteMap.emailMarketing.path, { shopId })} />
      )}
    </AppSwitch>
  ) : null;
};
