export enum EmailVariablesKeys {
  sku = 'sku',
  imageUrl = 'imageUrl',
  userId = 'userId',
}

export const defaultEmailVariables = {
  [EmailVariablesKeys.sku]: '',
  [EmailVariablesKeys.imageUrl]: '',
  [EmailVariablesKeys.userId]: '',
};
