export const defaultWidgetMakerConfig = {
  font: 'sans-serif',
  fontSize: '12px',
  width: 500,
  numberOfRows: 2,
  itemsPerRow: 4,
  textColor: 'black',
  textBackgroundColor: 'white',
  fields: [
    { data: 'description', extraText: '' },
    { data: 'price', extraText: '' },
  ],
  title: 'Recommended for you',
  CTAButtonText: 'Shop Now',
};
