import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { AvailableIcons, DialogModal, Icon } from 'src/components-dummy';
import { DialogMUIProps } from '../DialogModal/DialogModal';
import {
  ModalContentWrapperStyled,
  ModalContentStyled,
  PaginationButtonStyled,
} from './DialogModalWithPagination.style';

const RIGHT_CLICK_KEY = ['ArrowRight', 39];
const LEFT_CLICK_KEY = ['ArrowLeft', 37];

const isClickedOnRef = (event: MouseEvent) => (ref: React.MutableRefObject<null>) => {
  if (ref.current === null) return false;
  return (ref.current as any).contains(event.target);
};

/**
 * Main
 */
export interface DialogModalWithPaginationProps extends Omit<DialogMUIProps, 'sx'> {
  children: React.ReactElement;
  sx?: DialogMUIProps['sx'];
  onBackButtonClick?: () => void;
  onNextButtonClick?: () => void;
  className?: string;
  disableArrowKeys?: boolean;
}
export const DialogModalWithPagination = ({
  sx,
  open,
  hideBackdrop,
  fullScreen,
  onClose,
  onBackButtonClick,
  onNextButtonClick,
  children,
  className,
  disableArrowKeys = false,
}: DialogModalWithPaginationProps): JSX.Element => {
  const contentRef = useRef(null);
  const backButtonRef = useRef(null);
  const nextButtonRef = useRef(null);
  const dialogRef: any = useRef();

  /**
   * Go back/next product on keyboard left <- -> right keys click
   */
  const onKeyDown = useCallback(
    ({ code }) => {
      if (disableArrowKeys) {
        return;
      }

      if (RIGHT_CLICK_KEY.includes(code) && onNextButtonClick) {
        onNextButtonClick();
      }

      if (LEFT_CLICK_KEY.includes(code) && onBackButtonClick) {
        onBackButtonClick();
      }
    },
    [disableArrowKeys, onNextButtonClick, onBackButtonClick]
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onBackButtonClick, onNextButtonClick, onKeyDown]);

  /**
   * handle click outside modal content (content/backButton/nextButton)
   */
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (!onClose) return;

      // bind event
      const isClickedOn = isClickedOnRef(event);

      // Not clicked on: contentRef && backButtonRef && nextButtonRef
      const isClickedOutside =
        isClickedOn(dialogRef) &&
        !isClickedOn(contentRef) &&
        !isClickedOn(backButtonRef) &&
        !isClickedOn(nextButtonRef);

      if (isClickedOutside) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const sxValue = useMemo(() => {
    return {
      ...(sx || {
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '72%',
            maxWidth: '1280px',
            minWidth: '890px',
            overflow: 'hidden',
          },
        },
      }),
      '& .MuiDialog-paper': {
        backgroundColor: 'transparent !important',
        boxShadow: 'none',
      },
    };
  }, [sx]);

  return (
    <DialogModal
      open={open}
      hideBackdrop={hideBackdrop}
      fullScreen={fullScreen}
      onClose={onClose}
      ref={dialogRef}
      sx={sxValue}
      className={className}
    >
      <>
        <ModalContentWrapperStyled className='syte-modal-content-wrapper'>
          {/* Left */}
          {onBackButtonClick && (
            <PaginationButtonStyled isLeft ref={backButtonRef} onClick={onBackButtonClick}>
              <Icon name={AvailableIcons.ArrowRounded} />
            </PaginationButtonStyled>
          )}
          <ModalContentStyled className='syte-pagination-dialog-modal-content' ref={contentRef}>
            {children}
          </ModalContentStyled>

          {/* Right */}
          {onNextButtonClick && (
            <PaginationButtonStyled ref={nextButtonRef} onClick={onNextButtonClick}>
              <Icon name={AvailableIcons.ArrowRounded} />
            </PaginationButtonStyled>
          )}
        </ModalContentWrapperStyled>
      </>
    </DialogModal>
  );
};
