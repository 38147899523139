import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
  AutoCompleteOption,
  AutoCompleteSingleValue,
  AvailableIcons,
  Icon,
  TextBox,
} from 'src/components-dummy';
import { defaultTrackingParameters, TrackingParameterKeys } from './types';
import { getStringEnumValues, ParseJoiValidateResponse } from 'src/utils';
import { WidgetMaker } from '../../../../../types/widget-maker';
import { CampaignDraft } from '../../../../../types/campaign-draft';
import { ErrorLabelStyled } from '../../../../../../VisualEditor/components/VisualEditorResultsScreen/DataFieldsList/DataFieldsList.styles';
import {
  IconStyled,
  IconWrapperStyled,
  InputsRowStyled,
  InputStyled,
  LinkButtonStyled,
  OperatorText,
  TextStyled,
  TrackingParametersSectionStyled,
  TrackingParametersWrapperStyled,
} from './TrackingParametersSection.styles';

interface TrackingParametersSectionProps {
  widgetMaker: Partial<WidgetMaker>;
  trackingParametersOptions?: string[];
  onChange: (campaign: CampaignDraft) => void;
  errors: ParseJoiValidateResponse<Record<string, string>>;
}

const enumParamsOptions = getStringEnumValues(TrackingParameterKeys).map(value => {
  return {
    value,
    title: value,
  };
});

const setOfEnumParams = new Set<string>(getStringEnumValues(TrackingParameterKeys));

export const TrackingParametersSection = ({
  onChange,
  widgetMaker,
  trackingParametersOptions = [],
  errors,
}: TrackingParametersSectionProps): JSX.Element => {
  const [parametersOptions, setParametersOptions] = useState<AutoCompleteOption[]>([]);

  const { UTMParameters } = widgetMaker;
  const initialTrackingParameters = useMemo(
    () =>
      UTMParameters && Object.keys(UTMParameters).length > 0
        ? Object.entries(UTMParameters).map(([key, value]) => ({
            id: uuid(),
            key,
            value,
          }))
        : defaultTrackingParameters,
    [UTMParameters]
  );

  const [utmParams, setUtmParams] =
    useState<{ id: string; key: string; value: string }[]>(initialTrackingParameters);

  const allTrackingParameters = useMemo(() => {
    const paramsOptions = trackingParametersOptions
      .filter(paramsValue => !setOfEnumParams.has(paramsValue))
      .map(paramsValue => {
        return {
          value: paramsValue,
          title: paramsValue,
        };
      });

    const result = [...enumParamsOptions, ...paramsOptions];

    return result;
  }, [trackingParametersOptions]);

  const onFocus = useCallback(() => {
    setParametersOptions(allTrackingParameters);
  }, [allTrackingParameters]);

  const onBlur = useCallback(() => {
    setParametersOptions([]);
    const updatedParams = utmParams?.reduce((acc, item) => {
      acc[item.key] = item.value;
      return acc;
    }, {});
    onChange({
      widgetMaker: {
        ...widgetMaker,
        UTMParameters: updatedParams,
      },
    });
  }, [setParametersOptions, utmParams]);

  const handleParamChange = useCallback(
    (id: string, field: 'key' | 'value', newValue: string) => {
      setUtmParams(prev =>
        prev.map(param => (param.id === id ? { ...param, [field]: newValue } : param))
      );
    },
    [setUtmParams]
  );

  const addNewTrackingParameter = useCallback(() => {
    setUtmParams(prev => [...prev, { id: uuid(), key: '', value: '' }]);
  }, [setUtmParams]);

  const removeTrackingParameter = useCallback(
    (id: string) => {
      const updatedParams = utmParams.filter(param => param.id !== id);

      setUtmParams(updatedParams);

      const updatedTrackingParams = updatedParams.reduce((acc, param) => {
        acc[param.key] = param.value;
        return acc;
      }, {});

      onChange({
        widgetMaker: {
          ...widgetMaker,
          UTMParameters: updatedTrackingParams,
        },
      });
    },
    [utmParams, onChange]
  );

  useEffect(() => {
    const additionalOptions = utmParams
      .filter(param => !allTrackingParameters.some(option => option.value === param.key))
      .map(param => ({
        value: param.key,
        title: param.key,
      }));

    const newOptions = [...allTrackingParameters, ...additionalOptions];

    setParametersOptions(newOptions);
  }, [utmParams]);

  const getErrorMessage = (key: string) => {
    const errorKey = `widgetMaker.UTMParameters.${key}`;
    return errors?.[errorKey]?.message || '';
  };
  const errorKeyMessage = errors?.['widgetMaker.UTMParameters']?.message || '';

  return (
    <TrackingParametersSectionStyled>
      <TextStyled>
        Append static or dynamic tracking parameters to the URL when a user clicks a product link.
      </TextStyled>
      {utmParams.map(({ id, key, value }) => {
        const errorValueMessage = getErrorMessage(key);
        return (
          <TrackingParametersWrapperStyled key={id}>
            <InputsRowStyled>
              <InputStyled>
                <AutoCompleteSingleValue
                  options={parametersOptions}
                  selectedValue={key}
                  onSelectOption={newKey => handleParamChange(id, 'key', newKey as any)}
                  onChange={newKey => handleParamChange(id, 'key', newKey)}
                  placeholder='ut'
                  freeSolo
                  onFocus={onFocus}
                  onBlur={onBlur}
                  openPopUp
                  errored={!!errorKeyMessage}
                />
              </InputStyled>
              <OperatorText>Is</OperatorText>
              <InputStyled>
                <TextBox
                  placeholder='Select Value'
                  value={value}
                  onChange={newValue => handleParamChange(id, 'value', newValue)}
                  onBlur={onBlur}
                  error={!!errorValueMessage}
                />
              </InputStyled>
              <IconWrapperStyled>
                <IconStyled
                  onClick={() => removeTrackingParameter(id)}
                  name={AvailableIcons.TrashCan}
                />
              </IconWrapperStyled>
            </InputsRowStyled>
            <ErrorLabelStyled>{errorValueMessage}</ErrorLabelStyled>
          </TrackingParametersWrapperStyled>
        );
      })}
      <LinkButtonStyled onClick={addNewTrackingParameter}>
        <Icon name={AvailableIcons.Plus} /> Add
      </LinkButtonStyled>
    </TrackingParametersSectionStyled>
  );
};
