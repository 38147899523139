import { v4 as uuid } from 'uuid';

export enum TrackingParameterKeys {
  utm_source = 'utm_source',
  utm_medium = 'utm_medium',
  utm_campaign = 'utm_campaign',
}

export const defaultTrackingParameters = [
  {
    id: uuid(),
    key: '',
    value: '',
  },
];
