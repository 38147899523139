import styled from '@emotion/styled';
import LinkMui from '@mui/material/Link';

export const LinkButtonStyled = styled(LinkMui)<{ disabled?: boolean }>`
  display: flex;
  align-items: center;

  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.custom['gray-20'] : theme.palette.custom['primary-main']};

  text-decoration: none;
  vertical-align: unset; /* override MUI internal css MuiTypography */

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  :hover {
    text-decoration: ${({ disabled }) => (disabled ? 'none' : 'underline')};
  }

  svg {
    margin-right: 4px;

    path {
      stroke: ${({ theme, disabled }) =>
        disabled ? theme.palette.custom['gray-20'] : theme.palette.custom['primary-main']};
    }
  }
` as typeof LinkMui;
