import { CampaignStrategy } from '../../../types/campaign-strategy';
import { CampaignsTableHeadRow } from '../../types';

export const tableColumns: Record<string, CampaignsTableHeadRow> = {
  campaignName: {
    header: 'Campaign Name',
    accessor: 'campaignName',
    minWidth: 30,
  },
  campaignStrategy: {
    header: 'Campaign Strategy',
    accessor: 'strategy',
    minWidth: 30,
  },
  lastEdit: {
    header: 'Last Edit',
    accessor: 'updatedAt',
    minWidth: 30,
  },
  rowActions: {
    header: '',
    accessor: 'rowActions',
    minWidth: 10,
  },
};

export const columns = Object.values(tableColumns);

export const displayNameMap: Record<CampaignStrategy, string> = {
  [CampaignStrategy.Similars]: 'Similar Items',
  [CampaignStrategy.Collections]: 'Collections',
  [CampaignStrategy.CompleteTheLook]: 'Complete The Look',
  [CampaignStrategy.ShopTheLook]: 'Shop The Look',
  [CampaignStrategy.FrequentlyBoughtTogether]: 'Frequently Bought Together',
  [CampaignStrategy.RecentlyViewed]: 'Recently Viewed',
};
