import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { emailMarketingCampaignsService } from 'src/services/src/service/email-marketing-campaigns';
import {
  GetCampaignsArguments,
  GetCampaignArguments,
} from 'src/services/src/service/email-marketing-campaigns/types';
import { Campaign } from './types/campaign';

export const emailMarketingCampaignsActions = {
  navigateTo: createAction<{ navigateTo: string }>('EmailMarketingCampaigns/NavigateTo'),
  notifyIsDirty: createAction<{ isDirty: boolean }>('EmailMarketingCampaigns/NotifyIsDirty'),
  resetCampaigns: createAction('EmailMarketingCampaigns/ResetCampaigns'),
  getCampaigns: createAsyncThunk(
    'EmailMarketingCampaigns/GetCampaigns',
    async (params: GetCampaignsArguments, { rejectWithValue }) => {
      try {
        const response = await emailMarketingCampaignsService.getCampaigns(params);
        return response;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  deleteCampaign: createAsyncThunk(
    'EmailMarketingCampaigns/DeleteCampaign',
    async (params: GetCampaignArguments, { rejectWithValue }) => {
      try {
        await emailMarketingCampaignsService.deleteCampaign(params);
        return { deletedCampaignId: params.campaignId };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  setDuplicateCampaignDraft: createAction<{ campaign: Partial<Campaign> }>(
    'EmailMarketingCampaigns/SetDuplicateCampaignDraft'
  ),
};
