import React from 'react';
import { SelectChangeEvent } from '@mui/material';
import { RuleFormFieldStyled } from '../../CollectionDetails/RuleFormField.styles';
import { MenuItem, Select, SelectType } from 'src/components-dummy';
import { RuleFormField } from 'src/components-bl/MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormField/RuleFormField';

interface SelectFieldProps {
  value: string;
  options: { value: string; text: string }[];
  onChange: (event: SelectChangeEvent<string>) => void;
  autoFocus?: boolean;
  labelText?: string;
  placeholder?: string;
  disabled?: boolean;
}

export const SelectField = ({
  value,
  options,
  onChange,
  autoFocus = false,
  labelText,
  placeholder,
  disabled = false,
}: SelectFieldProps) => {
  return (
    <RuleFormFieldStyled>
      <RuleFormField.LabelText>{labelText}</RuleFormField.LabelText>
      <Select
        type={SelectType.Primary}
        value={value}
        onChange={onChange}
        autoFocus={autoFocus}
        placeholder={placeholder}
        disabled={disabled}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </RuleFormFieldStyled>
  );
};
