import styled from '@emotion/styled';
import { Button, Typography } from 'src/components-dummy';

export const CampaignsEmptyTableStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const CampaignsEmptyTableContentStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  padding: 32px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-80']};
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const WelcomeImageStyled = styled.img`
  width: 100%;
  max-width: 690px;
`;

export const BlankImageContainer = styled.div`
  width: 690px;
  height: 400px;
  background-color: white;
`;

export const CreateCampaignsWrapperStyled = styled.div`
  justify-content: center;
  align-items: center;
  max-width: 350px;

  margin-right: 35px;
`;

export const CreateCampaignsSubTitleStyled = styled(Typography)`
  margin: 16px 0;
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

export const CreateCampaignsActionButtonsWrapperStyled = styled.div`
  display: flex;
  gap: 12px;
  justify-content: right;
`;

export const TableWrapperStyled = styled.div`
  min-width: 560px;
`;

export const CampaignNameStyled = styled(Button)`
  width: 80%;
  text-align: start;
  color: ${({ theme }) => theme.palette.custom['primary-main']};
  background-color: transparent;

  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }
  & > span {
    width: 100%;
  }
`;
