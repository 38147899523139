import { AvailableIcons } from 'src/components-dummy';
import { DomainEntityPath } from 'src/services';
import { DomainGroup, SubDomainGroupBase } from '../../types';

export const emailMarketingCampaignsDomainGroup: DomainGroup & SubDomainGroupBase = {
  domainKey: DomainEntityPath.EmailMarketingCampaigns,
  title: 'Email Marketing Campaigns',
  icon: AvailableIcons.Star,
  getCardTitle: ({ newEntity, oldEntity }) => {
    const campaign = newEntity ?? oldEntity;
    return `Email Marketing Campaigns > “${campaign?.campaignName}”`;
  },
  fieldsSchema: {},
};
