import { useEffect, useState, useMemo, useCallback } from 'react';
import { generatePath } from 'react-router';
import { isEqual } from 'lodash';

import {
  Collection,
  collectionNavigationActionMethods,
  collectionRulesActionMethods,
  collectionSettingsMethods,
  Rule,
  RuleGroup,
} from 'src/components-bl/Collections';
import { CreateCollectionActionMethods } from '../../CreateCollectionButton';
import { calculateDiff } from './helpers/calculate-diff';
import { buildDefaultRuleGroup, defaultRule } from './helpers/defaults';
import { convertRuleGroupsToRuleGroupsWithShopId } from './helpers/convertRuleGroupsToRuleGroupsWithShopId';
import { useAppSelector, useValidateSchema } from '../../../../hooks';
import { collectionsRoutes } from '../../../../app-routes';
import { validationSchema } from './helpers/validationSchema';

import {
  OnCreateCollectionCallback,
  UseCollectionArguments,
  UseCollectionReturn,
  ValidateCollectionSchema,
} from './types';
import { CollectionRulesDiffWithShopId } from './helpers/types';
import { Dispatch } from '../../../types';
import { Fallback, FallbackType } from '../CollectionFallback/enums';

export const useCollection = ({
  shopId,
  currentCollection,
  dispatch,
  isCreateMode,
  variantId,
}: UseCollectionArguments): UseCollectionReturn => {
  const [draftCollection, setDraftCollection] = useState({
    ...currentCollection,
    ruleGroups: [...(currentCollection?.ruleGroups || [])],
  });
  const dataFields = useAppSelector(state => state.dataFields.dataFields);

  useEffect(() => {
    setDraftCollection({
      ...currentCollection,
      ruleGroups: [...(currentCollection?.ruleGroups || [])],
    });
  }, [currentCollection]);

  const isValidFallback = useCallback((fallback: Fallback): boolean => {
    switch (fallback?.method) {
      case FallbackType.AlternativeCollection:
        return Boolean(fallback.collectionId);
      case FallbackType.CustomFallback:
        return Boolean(fallback.targetField) && Boolean(fallback.sortingOrder);
      default:
        return true;
    }
  }, []);

  const normalizeFallback = useCallback(
    (fallback: Partial<Fallback>): Fallback => ({
      method: fallback?.method ?? FallbackType.None,
      collectionId: fallback?.collectionId,
      targetField: fallback?.targetField,
      sortingOrder: fallback?.sortingOrder,
    }),
    []
  );

  const isDirty = useMemo(() => {
    const normalizedDraftCollection = {
      ...draftCollection,
      fallback: normalizeFallback(draftCollection.fallback),
    };
    const normalizedCurrentCollection = {
      ...currentCollection,
      fallback: normalizeFallback(currentCollection.fallback),
    };
    const hasChanges = !isEqual(normalizedDraftCollection, normalizedCurrentCollection);
    const fallbackValid = isValidFallback(draftCollection.fallback);

    return hasChanges && fallbackValid;
  }, [currentCollection, draftCollection, isValidFallback]);

  useEffect(() => {
    dispatch(collectionNavigationActionMethods.notifyIsDirty({ isDirty }));
  }, [dispatch, isDirty]);

  useEffect(() => {
    if (shopId && currentCollection?.id && !isCreateMode) {
      dispatch(
        collectionRulesActionMethods.getRuleGroups({
          shopId,
          collectionId: currentCollection.id,
          variantId,
        })
      );
    }
  }, [shopId, currentCollection?.id, isCreateMode, variantId]);

  const { errors, validate, isValid } = useValidateSchema<ValidateCollectionSchema>({
    schema: validationSchema,
  });

  const onAddRuleGroup = () => {
    const defaultCollection = {
      ...draftCollection,
      ruleGroups: [
        ...draftCollection?.ruleGroups,
        buildDefaultRuleGroup(currentCollection.id, currentCollection.name),
      ],
    };
    setDraftCollection(defaultCollection);
  };

  const onDeleteRuleGroup = (ruleGroupId: string) => {
    const updatedRuleGroups = draftCollection.ruleGroups.filter(
      (ruleGroup: RuleGroup) => ruleGroup.id !== ruleGroupId
    );
    setDraftCollection({ ...draftCollection, ruleGroups: updatedRuleGroups });
  };

  const onAddRule = (ruleGroupId: string) => {
    const ruleGroupsToSave = draftCollection.ruleGroups.map((ruleGroup: RuleGroup) => {
      if (ruleGroup.id === ruleGroupId) {
        return {
          ...ruleGroup,
          rules: [...(ruleGroup.rules || []), defaultRule],
          isModified: true,
        };
      }
      return { ...ruleGroup };
    });
    setDraftCollection({ ...draftCollection, ruleGroups: ruleGroupsToSave });
  };

  const onDeleteRule = (ruleGroupId: string, ruleIndex: number) => {
    const ruleGroupsToSave = draftCollection.ruleGroups.map(ruleGroup => {
      if (ruleGroup.id === ruleGroupId) {
        return {
          ...ruleGroup,
          rules: ruleGroup.rules.filter((_, idx) => idx !== ruleIndex),
          isModified: true,
        };
      }
      return { ...ruleGroup };
    });
    setDraftCollection({ ...draftCollection, ruleGroups: ruleGroupsToSave });
  };

  const onEditRule = (ruleGroupId: string, ruleIndex: number, newRule: Rule) => {
    const ruleGroupsToSave = draftCollection.ruleGroups.map(ruleGroup => {
      if (ruleGroup.id === ruleGroupId) {
        return {
          ...ruleGroup,
          rules: ruleGroup.rules.map((rule, index) =>
            ruleIndex === index ? { ...newRule } : { ...rule }
          ),
          isModified: true,
        };
      }
      return { ...ruleGroup };
    });
    validate({
      dataToValidate: { name: draftCollection.name, ruleGroups: ruleGroupsToSave },
    });
    setDraftCollection({ ...draftCollection, ruleGroups: ruleGroupsToSave });
  };

  const onSaveChanges = async () => {
    await dispatch(
      collectionSettingsMethods.save({
        shopId,
        collectionId: currentCollection.id,
        name: draftCollection.name,
        placement: draftCollection.placement,
        uniqueByField: draftCollection.uniqueByField,
        variantId,
        fallback: draftCollection.fallback,
      })
    );
    if (currentCollection && currentCollection.ruleGroups) {
      const diff = calculateDiff(currentCollection.ruleGroups, draftCollection.ruleGroups);
      const diffWithShopId: CollectionRulesDiffWithShopId = {
        removedRuleGroupsIds: diff.removedRuleGroupsIds,
        addedRuleGroups: convertRuleGroupsToRuleGroupsWithShopId(diff.addedRuleGroups, shopId),
        modifiedRuleGroups: convertRuleGroupsToRuleGroupsWithShopId(
          diff.modifiedRuleGroups,
          shopId
        ),
      };
      await dispatch(
        collectionRulesActionMethods.saveCollectionRulesDiff({
          shopId,
          collectionId: currentCollection.id,
          collectionName: currentCollection.name,
          diff: diffWithShopId,
          variantId,
        })
      );
    }
  };

  const onDiscardChanges = useCallback(() => {
    setDraftCollection({
      ...currentCollection,
      ruleGroups: [...(currentCollection?.ruleGroups || [])],
    });
  }, [currentCollection]);

  useEffect(() => {
    validate({
      dataToValidate: { name: draftCollection.name, ruleGroups: draftCollection.ruleGroups },
    });
  }, [validate, draftCollection]);

  const navigateToList = (cbDispatch: Dispatch) => {
    cbDispatch(
      collectionNavigationActionMethods.navigateTo({
        navigateTo: generatePath(collectionsRoutes.collections?.path, {
          shopId,
        }),
      })
    );
  };

  const onCancel = (cb: OnCreateCollectionCallback) => {
    cb(dispatch);
  };

  const onCreateCollection = (cb: (dispatch: Dispatch, collection?: Collection) => void) => {
    if (isValid) {
      dispatch(collectionNavigationActionMethods.notifyIsDirty({ isDirty: false }));
      dispatch(
        CreateCollectionActionMethods.CreateCollection(
          {
            shopId,
            name: draftCollection.name,
            placement: draftCollection.placement,
            ruleGroups: draftCollection.ruleGroups,
            variantId,
            fallback: draftCollection.fallback,
          },
          (cbDispatch, collection) => cb(cbDispatch, collection)
        )
      );
    }
  };

  const handleFieldOnChange = useCallback(
    (fieldName, value) => {
      validate({
        dataToValidate: { name: value },
      });
      setDraftCollection({ ...draftCollection, [fieldName]: value });
    },
    [draftCollection, setDraftCollection, validate]
  );

  const canSubmit = isDirty && isValid;

  return {
    draftCollection,
    canSubmit,
    errors,
    onAddRuleGroup,
    onDeleteRuleGroup,
    onAddRule,
    onDeleteRule,
    onEditRule,
    onSaveChanges,
    onDiscardChanges,
    onCreateCollection,
    onCancel,
    handleFieldOnChange,
    navigateToList,
    dataFields,
  };
};
