import styled from '@emotion/styled';

export const CollectionsSelectContainer = styled.div`
  position: relative;

  .ellipsis-with-tooltip {
    max-width: 60%;
  }
`;

export const CollectionLink = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  position: absolute;
  transform: translateY(-50%);
  line-height: 20px;
  top: 70%;
  right: 30px;
  height: 100%;
  font-size: 12px;
  color: #0073ff;
  padding: 10px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  svg {
    height: 12px;
    width: 12px;
  }
`;
