import { SyteProductType } from '../syte-products';

export enum DomainEntityPath {
  Account = 'account',
  AIModels = 'ai_models',
  AugmentedSearch = 'augmented_search',
  AugmentedSearchFilterSet = 'augmented_search.filter_set',
  AugmentedSearchRanking = 'augmented_search.ranking_strategy',
  AugmentedSearchGeneralSettings = 'augmented_search.general_settings',
  AugmentedSearchTuning = 'augmented_search.tuning',
  BoughtTogether = 'bought_together',
  BoughtTogetherSettings = 'bought_together.settings',
  CollectionGlobalRanking = 'collection_settings.ranking_strategy',
  CollectionRules = 'collection.rules',
  Collections = 'collections',
  CollectionSettings = 'collection_settings',
  CollectionRanking = 'collection.ranking_strategy',
  DataFields = 'data_fields',
  DeepTagReport = 'deep_tag_report',
  Dictionaries = 'dictionaries',
  DiscoveryBanner = 'discovery_banner',
  DiscoveryBannerSettings = 'discovery_banner.settings',
  DiscoveryButton = 'discovery_button',
  DiscoveryButtonRanking = 'discovery_button.ranking_strategy',
  EnableUiTest = 'enable_ui_test',
  Experiment = 'experiment',
  ExperimentVariant = 'experiment.variant',
  FeatureToggles = 'feature_toggles',
  FiltersSets = 'filtersSets',
  FiltersSetsFilter = 'filtersSets.filter',
  General = 'general',
  Lexicon = 'lexicon',
  MerchandisingRules = 'merchandising_rules',
  Personalization = 'personalization',
  PersonalizationRanking = 'personalization.ranking_strategy',
  PersonalizationSettings = 'personalization.settings',
  ProductTranslationsCollectionsTitles = 'product_translations.collections_titles',
  RecEnginesGeneralSettings = 'recommendation_engines.general_settings',
  RecentlyViewed = 'recently_viewed',
  RecentlyViewedRanking = 'recently_viewed.ranking_strategy',
  RecentlyViewedSettings = 'recently_viewed.settings',
  Shop = 'shop',
  ShopCatalogs = 'shop_catalogs',
  ShopTheLook = 'shop_the_look',
  ShopTheLookRanking = 'shop_the_look.ranking_strategy',
  ShopTheLookSettings = 'shop_the_look.settings',
  SimilarItems = 'similar_items',
  SimilarItemsRanking = 'similar_items.ranking_strategy',
  SimilarItemsSettings = 'similar_items.settings',
  Stories = 'stories',
  StoriesGeneralSettings = 'stories.general_settings',
  UiTemplates = 'ui_templates',
  UseKnn = 'use_knn',
  User = 'user',
  UserLocation = 'user_location',
  Versions = 'versions',
  VisualSearch = 'visual_search',
  VisualSearchRanking = 'visual_search.ranking_strategy',
  ResultsModal = 'results_modal',
  CustomInspirationsGallery = 'custom-inspirations-gallery',
  EmailMarketingCampaigns = 'email_marketing_campaigns',
}

export const syteProductToDomainEntityMap: Record<SyteProductType, DomainEntityPath> = {
  [SyteProductType.AugmentedSearch]: DomainEntityPath.AugmentedSearch,
  [SyteProductType.SimilarItems]: DomainEntityPath.SimilarItems,
  [SyteProductType.ShopTheLook]: DomainEntityPath.ShopTheLook,
  [SyteProductType.VisualSearch]: DomainEntityPath.VisualSearch,
  [SyteProductType.DiscoveryButton]: DomainEntityPath.DiscoveryButton,
  [SyteProductType.Personalization]: DomainEntityPath.Personalization,
  [SyteProductType.Collections]: DomainEntityPath.Collections,
  [SyteProductType.UiTemplates]: DomainEntityPath.UiTemplates,
  [SyteProductType.EmailMarketing]: DomainEntityPath.EmailMarketingCampaigns,
};
