import styled from '@emotion/styled';
import { Icon } from 'src/components-dummy';
import { DialogModalWithPagination } from 'src/components-dummy/DialogModalWithPagination/DialogModalWithPagination';

export const DialogModalWithPaginationStyled = styled(DialogModalWithPagination)`
  .MuiDialog-container {
    .MuiPaper-root {
      width: 85%;
    }
  }

  .syte-modal-content-wrapper {
    padding: 0;
    width: 100%;
  }
`;

export const DialogModalWithPaginationContentStyled = styled.div`
  display: grid;
  justify-items: center;
  max-height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const TagImageModalHeaderStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
`;

export const CloseIconStyled = styled(Icon)`
  position: absolute;
  right: 30px;
  width: 12px;
  height: 12px;
  cursor: pointer;
`;

export const TagImageModalHeaderDividerStyled = styled.div`
  width: 94%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
`;

export const TagImageModalContentStyled = styled.div`
  width: 100%;
  display: flex;
  padding-left: 24px;
`;
