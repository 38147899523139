import React from 'react';
import { ButtonStyled } from './AISuggestedTagsActionButtons.styles';
import { AvailableIcons } from 'src/components-dummy';

interface AISuggestedTagsActionButtonsProps {
  removeAllSuggestedTags: () => void;
  applyAllSuggestedTags: () => void;
  startTagsDetection: () => void;
  isCurrentlyDetectionTags: boolean;
  isGalleryLoading: boolean;
  hasAiSuggestedTags: boolean;
  isDefaultImages?: boolean;
}

export const AISuggestedTagsActionButtons = ({
  isGalleryLoading,
  hasAiSuggestedTags,
  applyAllSuggestedTags,
  removeAllSuggestedTags,
  startTagsDetection,
  isCurrentlyDetectionTags,
  isDefaultImages,
}: AISuggestedTagsActionButtonsProps): JSX.Element | null => {
  const disableGenerateTagsButton = isDefaultImages || isCurrentlyDetectionTags;

  if (isGalleryLoading) {
    return null;
  }

  if (hasAiSuggestedTags) {
    return (
      <>
        <ButtonStyled
          variant='secondary'
          startIcon={AvailableIcons.Check}
          onClick={applyAllSuggestedTags}
        >
          Accept Suggestions
        </ButtonStyled>
        <ButtonStyled
          variant='secondary'
          startIcon={AvailableIcons.Close}
          onClick={removeAllSuggestedTags}
        >
          Reject Suggestions
        </ButtonStyled>
      </>
    );
  }

  return (
    <ButtonStyled
      variant='secondary'
      startIcon={AvailableIcons.Stars}
      onClick={startTagsDetection}
      disabled={disableGenerateTagsButton}
    >
      Generate Tags
    </ButtonStyled>
  );
};
