import React, { ReactNode, useCallback } from 'react';
import { generatePath } from 'react-router';
import { RoutedComponentProps } from 'src/app-routes';
import { Dispatch } from 'src/components-bl/types';
import { Breadcrumbs } from 'src/components-dummy';
import { campaignBreadCrumbsActions } from './EmailMarketingCampaignsBreadCrumbs.actions';

interface EmailMarketingCampaignsBreadCrumbsProps extends RoutedComponentProps {
  children: JSX.Element | JSX.Element[] | ReactNode | ReactNode[];
  dispatch: Dispatch;
  shopId: number;
  showCampaignsLink?: boolean;
}

export const EmailMarketingCampaignsBreadCrumbs = ({
  children,
  dispatch,
  permittedRouteMap,
  shopId,
  showCampaignsLink = false,
}: EmailMarketingCampaignsBreadCrumbsProps): JSX.Element => {
  const shopperExperiencesLink = permittedRouteMap.shopperExperiences
    ? generatePath(permittedRouteMap.shopperExperiences.path, { shopId })
    : '';

  const campaignsTableLink = permittedRouteMap.emailMarketing
    ? generatePath(permittedRouteMap.emailMarketing.path, { shopId })
    : '';

  const onLinkClick = useCallback(
    (link: string) => {
      dispatch(campaignBreadCrumbsActions.navigateTo({ navigateTo: link }));
    },
    [dispatch]
  );

  return (
    <Breadcrumbs>
      <Breadcrumbs.Link
        displayName='Shopper experience'
        href={shopperExperiencesLink}
        onNavigate={onLinkClick}
      />
      {showCampaignsLink ? (
        <Breadcrumbs.Link
          displayName='Email Marketing'
          href={campaignsTableLink}
          onNavigate={onLinkClick}
        />
      ) : (
        <></>
      )}
      <Breadcrumbs.Current>{children}</Breadcrumbs.Current>
    </Breadcrumbs>
  );
};
