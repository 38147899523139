import { ExperimentButtonsActionTypes } from 'src/components-bl/Experiment/ExperimentActionButtons/Actions';
import {
  RankingStrategyActionTypes,
  AccountCardActionTypes,
  UserListActionTypes,
  ShopCardActionTypes,
  ShopGeneralSettingsActionTypes,
  PublishStatusIndicationActionTypes,
  CreateAccountFormActionTypes,
  ExperimentListActionTypes,
  ExperimentCardActionTypes,
  ExperimentFormActionTypes,
  CreateExperimentFormActionTypes,
  ShopFeatureTogglesFormActionTypes,
  ShopTheLookActionTypes,
  BoughtTogetherActionTypes,
  RecentlyViewedActionTypes,
  PersonalizationActionTypes,
  RecEnginesActionTypes,
  SimilarItemsActionTypes,
  DiscoveryBannerActionTypes,
  userFormActions,
  deepTagsReportProductPageHeaderActions,
  deepTagReportsListItemActions,
  deepTagReportSFTPSettingsModalActions,
  lexiconPageHeaderActions,
  lexiconTableActions,
  shopFeatureTogglesDeepTagsToggleActions,
  editExistingCatalogFormActions,
  catalogsListPageActions,
  account2FAActions,
  dataFieldsTableFormActions,
  shopCreationFormActions,
  versionsTableActions,
  exportLexiconModalActions,
  CollectionSettingsActionTypes,
  augmentedSearchSettingsActions,
  visualEditorActions,
  relevancyTuningActions,
  synonymsActions,
} from 'src/components-bl';
import { editThematicTagContainerActions } from 'src/containers/Lexicon';
import { merchandisingRulesListContainerActions } from 'src/containers/MerchandisingRules/MerchandisingRulesListContainer';
import { editMerchandisingRuleContainerActions } from 'src/containers/MerchandisingRules/EditMerchandisingRuleContainer';
import { createMerchandisingRuleContainerActions } from 'src/containers/MerchandisingRules/CreateMerchandisingRuleContainer';
import { deepTagReportFileUploadModalActions } from 'src/components-bl/DeepTagReports/components/DeepTagReportFileUploadModal';
import { rootContainerActions } from 'src/root-container/Actions/RootContainerActions';
import { deepTagsProductContainerActions } from 'src/containers/DeepTagReports/components/DeepTagsProductContainer/Actions/DeepTagsProductContainerActions';
import {
  deepTagReportSFTPSettingsModalContainerActions,
  useDeepTagReportsWSActions,
} from 'src/containers/DeepTagReports';
import { useShopVersionsWSActions } from 'src/containers/AppHeader';
import { shopRoutesActions } from 'src/containers/ShopRoutes';
import { lexiconBulkActions } from 'src/components-bl/Lexicon/components/LexiconTable/Components/BulkActions/LexiconBulkAction.actions';
import { editExistingCatalogModalContainerActions } from 'src/containers/CatalogManager';
import { MerchandisingRulesListActions } from 'src/components-bl/MerchandisingRules/components/MerchandisingRulesListPage/components/MerchandisingRulesList/Actions/MerchandisingRulesList.actions';
import { merchandisingRulesListPageActions } from 'src/components-bl/MerchandisingRules/components/MerchandisingRulesListPage/MerchandisingRulesListPage.actions';
import { merchandisingRulesFormActions } from 'src/components-bl/MerchandisingRules/components/MerchandisingRuleForm/MerchandisingRuleForm.actions';
import { merchandisingRulesManagementActions } from 'src/components-bl/MerchandisingRules/components/MerchandisingRulesManagement/MerchandisingRulesManagement.actions';
import { catalogFormActions } from 'src/components-bl/CatalogManager/CatalogForm';
import { catalogFormContainerActions } from 'src/containers/CatalogManager/CatalogFormContainer';

import { thematicTagFormActions } from 'src/components-bl/Lexicon/components/ThematicTags/ThematicTagForm/ThematicTagForm.actions';
import { shopLexiconSettingsActions } from 'src/components-bl/ShopLexiconSettings/Actions/ShopLexiconSettings.actions';
import { useValidateLocaleActions } from 'src/containers/Lexicon/hooks/useValidateLocale/Actions/actions';
import { CatalogUploadErrorReportListContainerActions } from 'src/containers/CatalogManager/CatalogUploadErrorReportListContainer';

import { shopApiKeysSettingsActions } from 'src/containers/ShopApiKeysSettings/Actions/ShopApiKeysSettingsActions';
import { shopAiModelSettingsActions } from 'src/containers/ShopSettings/Actions/ShopSettingsContainerActions';
import {
  editFilterContainerActions,
  filtersContainerActions,
  MAX_ALLOWED_ACTIVE_FILTERS,
} from 'src/containers/Filters';
import {
  filtersListPageActions,
  filtersFormActions,
  createFilterPageActions,
  MAX_ALLOWED_ACTIVE_CUSTOM_ORDER_FILTERS,
} from 'src/components-bl/Filters';
import {
  addImagesModalFormActions,
  editGalleryPageActions,
  galleriesTablePageActions,
  galleryFormActions,
  tagImageModalActions,
} from 'src/components-bl/Galleries';
import { dataFieldAutoSuggestionActions } from 'src/components-bl/Galleries/CustomInspirations/components';
import { deepTagsReportProductsBulkLabelActions } from 'src/components-bl/DeepTagReports/components/DeepTagReportProductsBulkLabelModal/DeepTagsReportProductsBulkLabelModal.actions';
import { DeepTagReportsValidationErrorKey } from 'src/services';
import { reviewVersionActions } from 'src/components-bl/Versioning/ReviewVersion';
import { deepTagsProductCommentsActions } from 'src/components-bl/DeepTagReports/components/DeepTagsProductForm/components/DeepTagProductComments/DeepTagsProductComments.actions';
import { lexiconRuleFormActions } from 'src/components-bl/Lexicon/components/LexiconRules/LexiconRuleForm';
import { editLexiconRuleContainerActions } from 'src/containers/Lexicon/components/EditLexiconRuleContainer';
import { catalogFeedUploadLogActions } from 'src/components-bl/CatalogManager/CatalogFeedUploadLog/CatalogFeedUploadLog.actions';
import { loginActions } from '../../Login';
import { resetPasswordActions } from '../../ResetPassword';
import { TranslationMap } from '../types';
import { CollectionsFallbackValidationErrorKey, ValidationErrorKey } from './validation-error-key';
import { EditExperimentContainerActionTypes } from '../../EditExperimentContainer';
import { FeedsMetadataActionTypes } from '../../../components-bl/DeepTagsFilters/feed-metadata-actions';
import { socialDiscoveryToasts } from '../../../services/src/service/social-discovery/types';
import { storiesActionToasts } from '../../../components-bl/Stories/storiesPage.types';
import { ResultsModalActions } from '../../ResultsModalSettings/components/Actions';
import { createGalleryFormActions } from '../../../components-bl/Galleries/CreateGalleryModalForm/Actions';
import { CreateCollectionButtonActionTypes } from '../../../components-bl/Collections/CreateCollectionButton';
import { CatalogExplorerPageActions } from 'src/components-bl/CatalogManager/CatalogExplorer/CatalogExplorerPage/CatalogExplorerPage.actions';
import { useGalleriesWSActions } from 'src/containers/Galleries/components/CustomInspirations/useGalleriesWS';
import { toastMiddlewareActions } from 'src/middleware-actions';
import { verifyOneTimeLinkActions } from 'src/containers/VerifyOneTimeLink';
import { collectionsTranslationsActions } from '../../../components-bl/Collections/CollectionsTranslationFileUploadModal/CollectionsTranslationsActions';
import { CatalogManagerActions } from '../../../components-bl/CatalogManager/CatalogManager.actions';
import { emailMarketingCampaignsActions } from 'src/components-bl/EmailMarketing/EmailMarketingCampaignsActions';
import { campaignModalPageActions } from 'src/components-bl/EmailMarketing/CampaignModalPage/Actions';

export const appToastsMap = {
  [loginActions.login.rejected.type]: {
    [ValidationErrorKey.AuthUserMissingPassword]:
      'Password was not created yet. Check your email in order to create it',
    [ValidationErrorKey.AuthUserLocked]: 'User is locked, please check your email to unlock it',
    default: 'Please, re-check the credentials you’ve entered. They seem to be invalid',
  },
  [verifyOneTimeLinkActions.verifyToken.fulfilled.type]:
    'The verification process successfully completed',
  [loginActions.resendTwoFactorAuthLink.fulfilled.type]:
    'Verification link sent successfully. Please check your email.',
  [loginActions.resendTwoFactorAuthLink.rejected.type]: {
    [ValidationErrorKey.AuthResendLinkRequestCoolDownPeriod]:
      'You’ve requested a link recently. Please wait before requesting another.',
    default: 'Could not resend verification link, please login again',
  },
  [verifyOneTimeLinkActions.verifyToken.rejected.type]: 'The verification process failed',
  [toastMiddlewareActions.sessionExpired.type]: 'Session has expired',
  [toastMiddlewareActions.userLocked.type]:
    'Incorrect password was provided too many times. Please, check your email to unlock the user',
  [toastMiddlewareActions.forceLogout.rejected.type]: 'Session is invalid or expired',
  [loginActions.forgotPassword.fulfilled.type]: 'Your recovery email is on its way',
  [loginActions.forgotPassword.rejected.type]:
    `Couldn't allocate an account associated with this email`,
  [rootContainerActions.checkExperimentRunning.rejected.type]:
    'An error ocurred, could not determine if any experiments are running',
};

export const rankingToastsMap = {
  [RankingStrategyActionTypes.GetWeightsError]: 'Failed to get Ranking Strategy',
  [RankingStrategyActionTypes.UpdateRankingWeightsError]: {
    [ValidationErrorKey.RankingWeightsMinSum]:
      'Please insert a weight to at least one of the parameters. ',
    default: 'Something went wrong. Ranking strategy was not updated',
  },

  [RankingStrategyActionTypes.UpdateRankingWeightsSuccess]: 'Ranking strategy updated successfully',
};

export const merchandisingToasts = {
  [MerchandisingRulesListActions.deleteRule.fulfilled.type]: 'Rule deleted successfully',
  [MerchandisingRulesListActions.deleteRule.rejected.type]:
    'Something went wrong. The rule was not deleted',

  [MerchandisingRulesListActions.createRule.fulfilled.type]:
    'Created merchandising rule successfully',
  [MerchandisingRulesListActions.createRule.rejected.type]: {
    default: 'Something went wrong. The rule was not created',
    [ValidationErrorKey.MerchandisingRuleNameAlreadyExists]: 'Rule by this name already exists',
    [ValidationErrorKey.MerchandisingRuleSubRulesLimit]:
      'Failed to update rule, as conditions limit reached',
  },
  [MerchandisingRulesListActions.updateRule.fulfilled.type]: 'Rule updated successfully',
  [MerchandisingRulesListActions.updateRule.rejected.type]: {
    default: 'Something went wrong. The rule was not updated',
    [ValidationErrorKey.MerchandisingRuleNameAlreadyExists]: 'Rule by this name already exists',
    [ValidationErrorKey.MerchandisingRuleSubRulesLimit]:
      'Failed to update rule, as conditions limit reached',
  },

  [merchandisingRulesFormActions.updateMerchandisingRule.fulfilled.type]:
    'Rule updated successfully',
  [merchandisingRulesFormActions.updateMerchandisingRule.rejected.type]: {
    default: 'Something went wrong. The rule was not updated',
    [ValidationErrorKey.MerchandisingRuleNameAlreadyExists]: 'Rule by this name already exists',
    [ValidationErrorKey.MerchandisingRuleSubRulesLimit]:
      'Failed to update rule, as conditions limit reached',
  },
  [merchandisingRulesFormActions.createMerchandisingRule.fulfilled.type]:
    'Rule created successfully',
  [merchandisingRulesFormActions.createMerchandisingRule.rejected.type]: {
    default: 'Something went wrong. The rule was not created',
    [ValidationErrorKey.MerchandisingRuleNameAlreadyExists]: 'Rule by this name already exists',
    [ValidationErrorKey.MerchandisingRuleSubRulesLimit]:
      'Failed to update rule, as conditions limit reached',
  },

  [merchandisingRulesManagementActions.getRule.rejected.type]: 'Merchandising rule was not found',
  [editMerchandisingRuleContainerActions.getRule.rejected.type]: 'Merchandising rule was not found',
  [merchandisingRulesListPageActions.getRules.rejected.type]: 'Failed to load rules',
};
export const personalizationSettingsToasts = {
  [PersonalizationActionTypes.GetPersonalizationSettingsError]:
    'Failed to get Personalization Settings',
  [PersonalizationActionTypes.UpdatePersonalizationSettingsError]: {
    default: 'Something went wrong. Personalization Settings was not updated',
  },
  [PersonalizationActionTypes.UpdatePersonalizationSettingsSuccess]:
    'Personalization Settings updated successfully',
};

export const shopTheLookSettingsToasts = {
  [ShopTheLookActionTypes.GetShopTheLookSettingsError]: 'Failed to get Shop The Look Settings',
  [ShopTheLookActionTypes.UpdateShopTheLookSettingsError]: {
    default: 'Something went wrong. Shop The Look Settings was not updated',
  },
  [ShopTheLookActionTypes.UpdateShopTheLookSettingsSuccess]:
    'Shop The Look Settings updated successfully',
};

export const boughtTogetherSettingsToasts = {
  [BoughtTogetherActionTypes.GetBoughtTogetherSettingsError]:
    'Failed to get Bought Together Settings',
  [BoughtTogetherActionTypes.UpdateBoughtTogetherSettingsError]: {
    default: 'Something went wrong. Bought Together Settings was not updated',
  },
  [BoughtTogetherActionTypes.UpdateBoughtTogetherSettingsSuccess]:
    'Bought Together Settings updated successfully',
};

export const recentlyViewedSettingsToasts = {
  [RecentlyViewedActionTypes.GetRecentlyViewedSettingsError]:
    'Failed to get Recently Viewed Settings',
  [RecentlyViewedActionTypes.UpdateRecentlyViewedSettingsError]: {
    default: 'Something went wrong. Recently Viewed Settings was not updated',
  },
  [RecentlyViewedActionTypes.UpdateRecentlyViewedSettingsSuccess]:
    'Recently Viewed Settings updated successfully',
};

export const similarItemsSettingsToasts = {
  [SimilarItemsActionTypes.GetSimilarItemsSettingsError]: 'Failed to get Similar Items Settings',
  [SimilarItemsActionTypes.UpdateSimilarItemsSettingsError]: {
    default: 'Something went wrong. Similar Items Settings was not updated',
  },
  [SimilarItemsActionTypes.UpdateSimilarItemsSettingsSuccess]:
    'Similar Items Settings updated successfully',
};

export const discoveryBannerSettingsToasts = {
  [DiscoveryBannerActionTypes.GetDiscoveryBannerSettingsError]:
    'Failed to get Discovery Banner Settings',
  [DiscoveryBannerActionTypes.UpdateDiscoveryBannerSettingsError]: {
    default: 'Something went wrong. Discovery Banner Settings was not updated',
  },
  [DiscoveryBannerActionTypes.UpdateDiscoveryBannerSettingsSuccess]:
    'Discovery BannerSettings updated successfully',
};

export const shopToasts = {
  [shopCreationFormActions.createShop.fulfilled.type]: 'Your shop has been successfully created',
  [shopCreationFormActions.createShop.rejected.type]: {
    [ValidationErrorKey.ShopNameDuplicateName]: 'Another shop with this name already exists',
    default: 'Your shop has not been created',
  },
  [ShopCardActionTypes.DeleteShopSuccess]: 'Successfully deleted shop',
  [ShopCardActionTypes.DeleteShopError]: 'Something went wrong. Shop was not deleted.',
  [shopRoutesActions.loadShop.rejected.type]: `Failed to load shop`,
};

export const dataFieldsToasts = {
  [dataFieldsTableFormActions.updateDataFields.fulfilled.type]: 'Successfully updated Data Fields',
  [dataFieldsTableFormActions.updateDataFields.rejected.type]: {
    [ValidationErrorKey.ShopDataFieldsReadOnly]: '"Original Name" is read-only',
    [ValidationErrorKey.ShopDataFieldsLengthReadOnly]:
      'Adding or removing data fields is not allowed',
    [ValidationErrorKey.ShopDataFieldsFeatureIncompatible]:
      'Incompatible supported feature for some of the data fields',
    default: 'Failed to updated Data Fields',
  },

  [merchandisingRulesListContainerActions.getDataFields.rejected.type]:
    'Failed to load data fields',

  [createMerchandisingRuleContainerActions.getDataFields.rejected.type]:
    'Failed to load data fields',

  [merchandisingRulesManagementActions.getDataFields.rejected.type]: 'Failed to load data fields',
};

export const resetPasswordToastsMap = {
  [resetPasswordActions.submit.rejected.type]: "Couldn't reset your password",
  [resetPasswordActions.submit.fulfilled.type]: 'Your new password is now updated',
  [resetPasswordActions.invalidToken.type]: 'The url is invalid. Try to reset your password again',
};

export const shopGeneralSettingsToastsMap = {
  [ShopGeneralSettingsActionTypes.SubmitError]: 'Something went wrong. Your changes were not saved',
  [ShopGeneralSettingsActionTypes.SubmitSuccess]: 'Shop setting have been updated',
};

export const accountsToasts = {
  [AccountCardActionTypes.UpdateAccountStatusSuccess]: 'Account was updated successfully',
  [AccountCardActionTypes.UpdateAccountStatusError]:
    'Something went wrong. Account was not updated',
  [account2FAActions.updateAccount2Fa.fulfilled.type]:
    '2 Step Verification has been updated successfully',
  [CreateAccountFormActionTypes.CreateAccountSuccess]: 'Account has been created successfully',
  [CreateAccountFormActionTypes.CreateAccountError]: 'Unable to create an account',
};

export const usersToasts = {
  [UserListActionTypes.DeleteUserError]: 'Something went wrong. The user was not deleted',
  [UserListActionTypes.DeleteUserSuccess]: 'User deleted successfully',
  [userFormActions.createUser.rejected.type]: {
    [ValidationErrorKey.UserEmailAlreadyExists]: 'The email already exists!',
    default: 'Something went wrong. The user was not created',
  },
  [userFormActions.createUser.fulfilled.type]: 'Created a user successfully',
  [userFormActions.updateUser.rejected.type]: 'Something went wrong. The user was not updated',
  [userFormActions.updateUser.fulfilled.type]: 'User updated successfully',
};

export const versioningToasts = {
  [PublishStatusIndicationActionTypes.GetDraftError]: 'Failed to get shop draft',
  [useShopVersionsWSActions.getDraft.rejected.type]: 'Failed to get shop draft',
  [PublishStatusIndicationActionTypes.PublishDraftError]:
    'The version has failed to publish. Please contact support',
  [PublishStatusIndicationActionTypes.PublishDraftSuccess]: 'Successfully published changes',
  [PublishStatusIndicationActionTypes.DiscardDraftError]: 'Failed to discard the draft',
  [PublishStatusIndicationActionTypes.DiscardDraftSuccess]: 'Successfully discarded the draft',
  [versionsTableActions.restoreFromVersion.fulfilled.type]: 'Successfully restored version',
  [versionsTableActions.restoreFromVersion.rejected.type]: 'Failed to restore version',
  [reviewVersionActions.getVersion.rejected.type]: 'Failed to fetch version',
};

export const experimentsToasts = {
  [ExperimentListActionTypes.GetExperimentsError]:
    'Something went wrong. Could not fetch experiments.',
  [ExperimentCardActionTypes.DeleteExperimentSuccess]: 'Experiment deleted successfully.',
  [ExperimentCardActionTypes.DeleteExperimentError]:
    'Something went wrong. The experiment was not deleted.',
  [EditExperimentContainerActionTypes.GetExperimentError]: "Couldn't get experiment",
  [ExperimentFormActionTypes.UpdateExperimentSuccess]: 'Experiment updated successfully',
  [ExperimentFormActionTypes.UpdateExperimentError]: {
    default: 'Failed to update the experiment',
    [ValidationErrorKey.VariantsIdsNotUnique]: 'Failed to update the experiment',
    [ValidationErrorKey.VariantsNameNotUnique]: 'Variant names must be unique',
    [ValidationErrorKey.VariantsTrafficAllocationNotEqual]:
      'Variant must have equal traffic allocations',
    [ValidationErrorKey.VariantsTrafficAllocationSum]: 'Traffic allocations must sum to 100',
    [ValidationErrorKey.VariantsTypeConstraintViolation]:
      'There must be exactly one control variant and at least one test variant',
  },
  [CreateExperimentFormActionTypes.CreateExperimentError]: {
    default: 'Experiment could not be created',
    [ValidationErrorKey.ExperimentNameAlreadyExists]:
      'There is already an experiment with this name',
  },
  [CreateExperimentFormActionTypes.CreateExperimentSuccess]: 'Experiment created successfully',
  [ExperimentButtonsActionTypes.RunExperimentSuccess]: 'The experiment is now running.',
  [ExperimentButtonsActionTypes.RunExperimentError]: {
    default: 'Something went wrong. The experiment is not running.',
    [ValidationErrorKey.ExperimentRunningExists]: 'Only one experiment per shop can run at a time.',
  },
  [ExperimentButtonsActionTypes.StopExperimentSuccess]: 'The experiment has been ended.',
  [ExperimentButtonsActionTypes.StopExperimentError]:
    'Something went wrong. The experiment could not be ended.',
};

const featureToggles = {
  [ShopFeatureTogglesFormActionTypes.GetFeatureTogglesError]:
    'Something went wrong. Could not fetch shop permissions',
  [ShopFeatureTogglesFormActionTypes.UpdateFeatureTogglesSuccess]:
    'Shop permissions were successfully updated',
  [ShopFeatureTogglesFormActionTypes.UpdateFeatureTogglesError]: {
    [ValidationErrorKey.ShopFeatureTogglesExperimentRunning]:
      'Cannot disable experiments when there is a running experiment',
    default: 'Failed to update Shop Permissions',
  },
  [shopFeatureTogglesDeepTagsToggleActions.updateShopProductsDeepTagsPermission.fulfilled.type]:
    'Shop products deep tags settings updated successfully',
  [shopFeatureTogglesDeepTagsToggleActions.getShopProductsDeepTagsPermission.rejected.type]:
    'Something went wrong. Shop products deep tags settings is not available for shop',
  [shopFeatureTogglesDeepTagsToggleActions.updateShopProductsDeepTagsPermission.rejected.type]:
    'Something went wrong. Could not update shop deep tags settings for products API v2',
};

export const recEngeingsGeneralSettingsToasts = {
  [RecEnginesActionTypes.GetRecEnginesGeneralSettingsError]:
    'Failed to get Recommendation Engines Settings',
  [RecEnginesActionTypes.UpdateRecEnginesGeneralSettingsError]: {
    default: 'Something went wrong. Recommendation Engines Settings was not updated',
  },
  [RecEnginesActionTypes.UpdateRecEnginesGeneralSettingsSuccess]:
    'Recommendation Engines Settings updated successfully',
};

export const resultModalGeneralSettingsToasts = {
  [ResultsModalActions.getResultsModalGeneralSettings.rejected.type]:
    'Failed to get Results Modal Settings',
  [ResultsModalActions.updateResultsModalGeneralSettings.rejected.type]: {
    default: 'Something went wrong. Results Modal Settings was not updated',
  },
  [ResultsModalActions.updateResultsModalGeneralSettings.fulfilled.type]:
    'Results Modal Settings updated successfully',
};

export const augSearchFiltersToasts = {
  [FeedsMetadataActionTypes.DeepTagsNotFoundError]:
    'Deep Tags were not configured on this account, please contact your technical account manager',
};

export const augmentedSearchToasts = {
  [augmentedSearchSettingsActions.updateGeneralSettings.fulfilled.type]:
    'Text search settings updated successfully',
  [augmentedSearchSettingsActions.updateGeneralSettings.rejected.type]:
    'Failed to updated text search settings',
  [relevancyTuningActions.updateRelevancyTuning.fulfilled.type]:
    'Text search relevancy tuning updated successfully',
  [relevancyTuningActions.updateRelevancyTuning.rejected.type]:
    'Failed to updated text search relevancy tuning',
  [synonymsActions.getSynonyms.rejected.type]: {
    [ValidationErrorKey.SynonymsLanguageNotEnabledInCatalog]: 'Language is invalid',
  },
  [synonymsActions.createSynonym.fulfilled.type]: 'Your synonyms list was updated successfully',
  [synonymsActions.editSynonym.fulfilled.type]: 'Your synonyms list was updated successfully',
  [synonymsActions.deleteSynonym.fulfilled.type]: 'Synonym deleted successfully',
  [synonymsActions.exportSynonyms.fulfilled.type]: 'Synonyms file exported successfully',
  [synonymsActions.exportSynonyms.rejected.type]: 'Failed to export synonyms file',
  [synonymsActions.deleteSynonym.rejected.type]: {
    [ValidationErrorKey.GlobalSynonymAlreadyDeleted]: 'Global synonym is already marked as deleted',
    [ValidationErrorKey.CustomSynonymAlreadyDeleted]: 'Custom synonym is already marked as deleted',
    [ValidationErrorKey.CustomOrGlobalSynonymNotFound]: 'Synonym not found',
    default: 'Failed to delete synonym',
  },
};

export const lexiconsToasts = {
  [lexiconPageHeaderActions.publishLexicon.fulfilled.type]: 'Published lexicon successfully',
  [lexiconPageHeaderActions.publishLexicon.rejected.type]: 'Failed to published lexicon',
  [lexiconTableActions.updateLexiconTag.fulfilled.type]: 'Updated lexicon tag successfully',
  [lexiconTableActions.updateLexiconTag.rejected.type]: 'Updating lexicon tag failed',
  [lexiconTableActions.restoreLexiconTag.fulfilled.type]: 'Tag restored successfully',
  [lexiconTableActions.restoreLexiconTag.rejected.type]: 'Failed to restore tag',
  [lexiconTableActions.toggleLexiconTag.fulfilled.type]: 'Toggled lexicon tag successfully',
  [lexiconTableActions.toggleLexiconTag.rejected.type]: 'Failed to toggle lexicon tag',

  [lexiconTableActions.toggleThematicTag.fulfilled.type]: 'Toggled thematic tag successfully',
  [lexiconTableActions.toggleThematicTag.rejected.type]: 'Failed to toggle thematic tag',

  [lexiconTableActions.deleteThematicTag.fulfilled.type]: 'Thematic tag deleted successfully',
  [lexiconBulkActions.deleteAllTags.fulfilled.type]: 'Tags deleted successfully',
  [lexiconBulkActions.deleteTags.fulfilled.type]: 'Tags deleted successfully',

  [lexiconBulkActions.include.fulfilled.type]: 'Updated lexicon successfully',
  [lexiconBulkActions.includeAll.fulfilled.type]: 'Updated lexicon successfully',
  [lexiconBulkActions.include.rejected.type]: 'Failed to update lexicon',
  [lexiconBulkActions.includeAll.rejected.type]: 'Failed to update lexicon',

  [lexiconBulkActions.rename.fulfilled.type]: 'Updated lexicon successfully',
  [lexiconBulkActions.renameAll.fulfilled.type]: 'Updated lexicon successfully',
  [lexiconBulkActions.rename.rejected.type]: 'Failed to update lexicon',
  [lexiconBulkActions.renameAll.rejected.type]: 'Failed to update lexicon',

  [lexiconBulkActions.restore.fulfilled.type]: 'Updated lexicon successfully',
  [lexiconBulkActions.restoreAll.fulfilled.type]: 'Updated lexicon successfully',
  [lexiconBulkActions.restore.rejected.type]: 'Failed to update lexicon',
  [lexiconBulkActions.restoreAll.rejected.type]: 'Failed to update lexicon',

  [thematicTagFormActions.getAvailableKeys.fulfilled.type]: 'Failed to get available keys',
  [thematicTagFormActions.getAvailableTranslations.fulfilled.type]:
    'Failed to get available translations',

  [lexiconRuleFormActions.getAvailableKeys.fulfilled.type]: 'Failed to get available keys',

  [thematicTagFormActions.createThematicTag.fulfilled.type]: 'Created thematic tag',
  [thematicTagFormActions.createThematicTag.rejected.type]: {
    [ValidationErrorKey.ThematicTagAlreadyExists]:
      'Thematic tag already exists for this set of category, attribute, and value',
    default: 'Failed to create thematic tag',
  },
  [thematicTagFormActions.updateThematicTag.fulfilled.type]: 'Updated thematic tag',
  [thematicTagFormActions.updateThematicTag.rejected.type]: 'Failed to update thematic tag',

  [editThematicTagContainerActions.getThematicTag.rejected.type]: 'Failed to fetch thematic tag',
  [useValidateLocaleActions.localeDisabledErrorNotification.type]: 'Selected locale is disabled',
  [exportLexiconModalActions.exportLexicon.rejected.type]:
    'Failed to export lexicon. Please, try again',
  [lexiconRuleFormActions.createLexiconRule.fulfilled.type]: 'Lexicon rule created successfully',
  [lexiconRuleFormActions.createLexiconRule.rejected.type]: 'Failed to create lexicon rule',
  [editLexiconRuleContainerActions.getLexiconRule.rejected.type]: 'Failed to fetch lexicon rule',
  [lexiconRuleFormActions.updateLexiconRule.fulfilled.type]: 'Lexicon rule updated successfully',
  [lexiconRuleFormActions.updateLexiconRule.rejected.type]: 'Failed to update lexicon rule',

  [lexiconTableActions.toggleLexiconRule.fulfilled.type]: 'Updated lexicon rule successfully',
  [lexiconTableActions.toggleLexiconRule.rejected.type]: 'Failed to update lexicon rule',

  [lexiconTableActions.deleteLexiconRule.fulfilled.type]: 'Lexicon rule was deleted successfully',
  [lexiconTableActions.deleteLexiconRule.rejected.type]: 'Failed to delete lexicon rule',

  [lexiconTableActions.updateLexiconRule.fulfilled.type]: 'Lexicon rule updated successfully',
  [lexiconTableActions.updateLexiconRule.rejected.type]: 'Failed to update lexicon rule',
};

export const generalSettingsAIModelToasts = {
  [shopAiModelSettingsActions.updateAiModel.fulfilled.type]: 'Updated shop model successfully',
  [shopAiModelSettingsActions.updateAiModel.rejected.type]: 'Failed to update shop model',
};

const lexiconSettingsToasts = {
  [shopLexiconSettingsActions.updateLexiconSettings.fulfilled.type]:
    'Shop lexicon languages have been updated.',
  [shopLexiconSettingsActions.updateLexiconSettings.rejected.type]:
    `Something went wrong. Shop lexicon languages weren't updated.`,
};

const deepTagReports = {
  [deepTagsReportProductPageHeaderActions.updateReport.fulfilled.type]:
    'Report updated successfully',
  [deepTagsReportProductPageHeaderActions.updateReport.rejected.type]: 'Failed to updated report',
  [deepTagsProductContainerActions.resetProductChanges.fulfilled.type]:
    'Product changes has been reset successfully ',
  [deepTagsProductContainerActions.resetProductChanges.rejected.type]:
    'Failed to reset changes for product',
  [deepTagsProductContainerActions.updateProduct.fulfilled.type]:
    'Product changes has been updated successfully ',
  [deepTagsProductContainerActions.updateProduct.rejected.type]: 'Failed to update product',
  [deepTagsProductContainerActions.updateGeneratedDataSettings.fulfilled.type]:
    'Shop generated data settings has been updated successfully ',
  [deepTagsProductContainerActions.updateGeneratedDataSettings.rejected.type]:
    'Failed to update shop generated data settings',
  [deepTagsProductContainerActions.resetProductChanges.rejected.type]:
    'Failed to update changes for product',
  [deepTagsReportProductPageHeaderActions.exportReportToFile.rejected.type]:
    'Failed to export to download report',
  [deepTagsReportProductPageHeaderActions.exportReportToSFTP.fulfilled.type]:
    'Report file exported to SFTP Successfully',
  [deepTagReportsListItemActions.exportReportToSFTP.rejected.type]: 'Failed to export file to SFTP',
  [deepTagReportsListItemActions.exportReportToFile.rejected.type]:
    'Failed to export to download report',
  [deepTagReportsListItemActions.exportReportToSFTP.fulfilled.type]:
    'Report file exported to SFTP Successfully',
  [deepTagReportsListItemActions.exportReportToSFTP.rejected.type]: 'Failed to export file to SFTP',
  [deepTagReportsListItemActions.updateReport.fulfilled.type]: 'Report updated successfully',
  [deepTagReportsListItemActions.updateReport.rejected.type]: 'Failed to update the report',
  [deepTagReportSFTPSettingsModalActions.updateConfiguration.fulfilled.type]:
    'Configuration updated successfully',
  [deepTagReportSFTPSettingsModalActions.updateConfiguration.rejected.type]:
    'Failed to update configuration',
  [deepTagReportFileUploadModalActions.createReport.fulfilled.type]: 'File Uploaded successfully',
  [deepTagReportFileUploadModalActions.createReport.rejected.type]: 'Failed to upload file',
  [deepTagReportSFTPSettingsModalContainerActions.getShopSFTPSettings.rejected.type]:
    'Failed to get Shop SFTP Settings',
  [deepTagReportsListItemActions.deleteReport.fulfilled.type]: 'Report successfully deleted',
  [deepTagReportsListItemActions.deleteReport.rejected.type]: 'Failed to delete report',
  [useDeepTagReportsWSActions.newReport.type]: 'New deep tag report is available',
  [deepTagsProductContainerActions.generateText.rejected.type]:
    "Couldn't generate description or title for product, please try to regenerate",
  [deepTagsReportProductsBulkLabelActions.createLabelsGeneralConfiguration.fulfilled.type]:
    'Label was created successfully',
  [deepTagsReportProductsBulkLabelActions.bulkCustomAssignProductLabels.fulfilled.type]:
    'Custom bulk label products completed successfully',
  [deepTagsReportProductsBulkLabelActions.bulkCustomAssignProductLabels.rejected.type]: {
    [DeepTagReportsValidationErrorKey.DeepTagReportsCustomBulkNumberMismatch]:
      "Couldn't apply custom bulk. there are more items assigned than there are available free ones",
    [DeepTagReportsValidationErrorKey.DeepTagReportsBulkSomeGeneralLabelsNotExisted]:
      "Couldn't apply even bulk. some labels assigned labels are not exists anymore",
    default: 'Failed to label products in bulk process.',
  },
  [deepTagsReportProductsBulkLabelActions.bulkEvenAssignProductLabels.fulfilled.type]:
    'Even bulk label products completed successfully',
  [deepTagsReportProductsBulkLabelActions.bulkEvenAssignProductLabels.rejected.type]: {
    [DeepTagReportsValidationErrorKey.DeepTagReportsBulkSomeGeneralLabelsNotExisted]:
      "Couldn't apply even bulk. some labels assigned labels are not exists anymore",
    [DeepTagReportsValidationErrorKey.DeepTagReportsBulkNoUnlabeledProductsToAssign]:
      "Couldn't apply even bulk. there are no unlabeled products to assign",
    default: 'Failed to label products in bulk process.',
  },
  [deepTagsProductCommentsActions.createProductComment.fulfilled.type]:
    'Successfully added a new product comment',
  [deepTagsProductCommentsActions.createProductComment.rejected.type]:
    'Failed to add product comment',
  [deepTagsProductCommentsActions.updateProductComment.fulfilled.type]:
    'Successfully updated product comment',
  [deepTagsProductCommentsActions.updateProductComment.rejected.type]:
    'Failed to update product comment',
  [deepTagsProductCommentsActions.deleteProductComment.fulfilled.type]:
    'Successfully deleted product comment',
  [deepTagsProductCommentsActions.deleteProductComment.rejected.type]:
    'Failed to delete product comment',
  [deepTagsReportProductPageHeaderActions.regenerateProductsTags.fulfilled.type]:
    'Regenerating tags for products was completed',
  [deepTagsReportProductPageHeaderActions.regenerateProductsTags.rejected.type]:
    'Failed to regenerate tags for products',
  [deepTagsReportProductPageHeaderActions.updateReport.rejected.type]:
    'Failed to regenerate tags for products',
};

export const catalogMangerToastsMap = {
  [editExistingCatalogFormActions.connectCatalog.rejected.type]: {
    [ValidationErrorKey.ShopCatalogIsAlreadyExists]: 'This catalogue name is already taken',
    [ValidationErrorKey.ShopCatalogIsAlreadyConnected]:
      'This catalogue name is already connected to other production shop',
    [ValidationErrorKey.ShopCatalogIsNotIndexed]: 'This catalogue does not exist',
    [ValidationErrorKey.ShopCatalogIsPrimaryCanNotBeSet]:
      'This catalogue can not be set as primary for these verticals since this vertical already has primary catalogue',
    [ValidationErrorKey.ShopCatalogIsAugmentedSearchCanNotBeSet]:
      'Augmented Search supports a single catalogue, please review your catalogue list',
    [ValidationErrorKey.ShopCatalogMultipleEsHosts]:
      'Multiple elastic search clusters are not supported',
    default: 'Failed to connect shop catalogue',
  },
  [editExistingCatalogFormActions.connectCatalog.fulfilled.type]: 'Catalogue connected',
  [editExistingCatalogFormActions.updateCatalog.fulfilled.type]: 'Catalogue updated',
  [editExistingCatalogFormActions.updateCatalog.rejected.type]: {
    [ValidationErrorKey.ShopCatalogIsAugmentedSearchCanNotBeSet]:
      'Augmented Search supports a single catalogue, please review your catalogue list',
    default: 'Failed to update a catalogue',
  },
  [catalogsListPageActions.setCatalogAsPrimary.fulfilled.type]: 'Catalogue set as Primary',
  [catalogsListPageActions.setCatalogAsPrimary.rejected.type]: 'Failed to set Catalogue as Primary',
  [catalogsListPageActions.deleteCatalog.fulfilled.type]: 'Catalogue deleted',
  [catalogsListPageActions.deleteCatalog.rejected.type]: 'Failed to delete Catalogue',
  [catalogFeedUploadLogActions.downloadUploadLogFeedFile.fulfilled.type]:
    'Catalogue downloaded successfully',
  [catalogFeedUploadLogActions.downloadUploadLogFeedFile.rejected.type]: {
    [ValidationErrorKey.CatalogManagerFeedFileNotExists]: 'Catalogue file does not exists',
    default: 'Failed to download catalogue file',
  },
  [editExistingCatalogModalContainerActions.getCatalog.rejected.type]: 'Failed to fetch catalogue',
  [catalogFormActions.createCatalogPlan.fulfilled.type]: 'Catalogue created successfully',
  [catalogFormActions.updateCatalogPlan.fulfilled.type]: 'Catalogue updated successfully',
  [catalogFormActions.createCatalogPlan.rejected.type]: {
    [ValidationErrorKey.FeedNameAlreadyOccupied]:
      'Catalogue name already taken, please choose another catalogue name',
    default: 'Failed to create a catalogue',
  },
  [catalogFormContainerActions.getSingleCatalog.rejected.type]:
    'Catalogue configuration was not found',

  [CatalogUploadErrorReportListContainerActions.getCatalogUploadErrorReport.rejected.type]:
    'Failed to get error report for log. report might be unavailable',

  [CatalogExplorerPageActions.getProducts.rejected.type]: 'Failed to fetch catalog products',
  [CatalogExplorerPageActions.downloadIndexedFeedFile.fulfilled.type]:
    'Catalogue downloaded successfully',
  [CatalogExplorerPageActions.downloadIndexedFeedFile.rejected.type]: {
    [ValidationErrorKey.CatalogManagerFeedFileNotExists]: 'Catalogue file does not exists',
    default: 'Failed to download catalogue file',
  },
  [CatalogManagerActions.reconnectCatalog.fulfilled.type]: 'Catalogue reconnected',
};

export const apiKeysToastsMap = {
  [shopApiKeysSettingsActions.createApiKey.fulfilled.type]: 'API key created successfully',
  [shopApiKeysSettingsActions.createApiKey.rejected.type]: 'Failed to create API key',
  [shopApiKeysSettingsActions.updateApiKey.fulfilled.type]: 'API key updated successfully',
  [shopApiKeysSettingsActions.updateApiKey.rejected.type]: 'Failed to update API key',
  [shopApiKeysSettingsActions.deleteApiKey.fulfilled.type]: 'API key deleted successfully',
  [shopApiKeysSettingsActions.deleteApiKey.rejected.type]: 'Failed to delete API key',
  [shopApiKeysSettingsActions.copyToClipboard.fulfilled.type]: 'API key copied successfully',
};

export const filtersToastsMap = {
  [filtersListPageActions.updateFilter.fulfilled.type]: 'Updated filter successfully',
  [filtersListPageActions.updateFilter.rejected.type]: {
    [ValidationErrorKey.MaxActiveFiltersExceeded]: `Maximum active filters is ${MAX_ALLOWED_ACTIVE_FILTERS}`,
    [ValidationErrorKey.MaxActiveCustomOrderFiltersExceeded]: `The maximum limit for filters with custom arrangement is ${MAX_ALLOWED_ACTIVE_CUSTOM_ORDER_FILTERS}`,
    default: 'Failed to update filter',
  },
  [filtersListPageActions.getFilterSet.rejected.type]: 'Failed to fetch filters',
  [filtersListPageActions.rearrangeFiltersOrder.rejected.type]:
    'Failed to rearrange filters, please retry',
  [filtersFormActions.createFilter.fulfilled.type]: 'Created filter successfully',
  [filtersFormActions.createFilter.rejected.type]: {
    [ValidationErrorKey.FilterSourceFieldAlreadyTaken]:
      'Source field is already used in another filter',
    default: 'Failed to create filter',
  },
  [filtersContainerActions.getDataFields.rejected.type]: 'Failed to fetch data fields',
  [editFilterContainerActions.getFilter.rejected.type]: 'Failed to fetch filter',
  [createFilterPageActions.getFilterSet.rejected.type]: 'Failed to fetch filter set',
  [editFilterContainerActions.getFilterSet.rejected.type]: 'Failed to fetch filter set',
  [filtersFormActions.updateFilter.fulfilled.type]: 'Updated filter successfully',
  [filtersFormActions.updateFilter.rejected.type]: {
    [ValidationErrorKey.FilterSourceFieldAlreadyTaken]:
      'Source field is already used in another filter',
    default: 'Failed to update filter',
  },
  [filtersListPageActions.deleteFilter.fulfilled.type]: 'Successfully deleted filter',
  [filtersListPageActions.deleteFilter.rejected.type]: 'Failed to delete filter',
};

export const galleriesToastsMap = {
  [galleriesTablePageActions.getGalleries.rejected.type]: 'Failed to fetch galleries',
  [galleriesTablePageActions.deleteGallery.fulfilled.type]:
    'Your gallery was deleted successfully ',
  [galleriesTablePageActions.deleteGallery.rejected.type]: 'Failed to delete gallery',
  [createGalleryFormActions.createGallery.fulfilled.type]: 'New gallery created successfully',
  [createGalleryFormActions.createGallery.rejected.type]: {
    [ValidationErrorKey.InspirationalImagesDuplicateName]:
      'Failed to create gallery, duplicate gallery name',
    [ValidationErrorKey.CustomInspirationGalleryDuplicateName]:
      'Failed to create gallery, duplicate gallery name',
    [ValidationErrorKey.InspirationalImagesInvalidFileStructure]:
      'Failed to create gallery, invalid file structure',
    default: 'Failed to create gallery',
  },
  [galleryFormActions.updateInspirationalImagesGallery.fulfilled.type]:
    'Your gallery was updated successfully',
  [galleryFormActions.updateInspirationalImagesGallery.rejected.type]: {
    [ValidationErrorKey.InspirationalImagesInvalidFileStructure]:
      'Failed to update gallery, invalid file structure',
    default: 'Failed to update gallery',
  },
  [editGalleryPageActions.getGallery.rejected.type]: 'Failed to fetch gallery',
  [addImagesModalFormActions.updateGallery.rejected.type]: 'Failed to update gallery',
  [addImagesModalFormActions.updateGallery.fulfilled.type]: 'Successfully updated gallery',
  [editGalleryPageActions.updateGallery.rejected.type]: 'Failed to update gallery',
  [editGalleryPageActions.updateGallery.fulfilled.type]: 'Successfully updated gallery',
  [tagImageModalActions.updateGallery.rejected.type]: 'Failed to update gallery',
  [tagImageModalActions.updateGallery.fulfilled.type]: 'Successfully updated gallery',
  [dataFieldAutoSuggestionActions.getValues.rejected.type]: 'Failed to fetch values',
  [useGalleriesWSActions.errorNotification.type]: {
    [ValidationErrorKey.CustomInspirationGalleryDetectionFailed]:
      'We were unable to generate suggestions, please try again later.',
    [ValidationErrorKey.CustomInspirationGalleryDetectionFailedPartially]:
      'We were unable to generate suggestions for some images, please try again later.',
    default: 'We were unable to generate suggestions, please try again later.',
  },
};

const commonCollectionErrors = {
  default: 'Something went wrong. The collection was not created.',
  [CollectionsFallbackValidationErrorKey.CollectionNameAlreadyExistsForShop]:
    'This name is already taken by another collection.',
  [CollectionsFallbackValidationErrorKey.CollectionIdAlreadyExistsForShop]:
    'This collection id is already taken by another collection.',
  [CollectionsFallbackValidationErrorKey.InvalidFallbackMethod]:
    'The provided fallback method is invalid. Valid methods are: None, BestSellers, TrendingItems, Custom Fallback, Alternative Collection.',
  [CollectionsFallbackValidationErrorKey.MissingShopId]:
    'shopId is required for fallback validation.',
  [CollectionsFallbackValidationErrorKey.MissingCollectionId]:
    'Fallback type "Alternative Collection" requires a valid collection id.',
  [CollectionsFallbackValidationErrorKey.CollectionNotFoundForShop]:
    'The specified collection was not found for this shop.',
  [CollectionsFallbackValidationErrorKey.MissingCustomFallbackFields]:
    'For "Custom Fallback", both Fallback Field and Fallback Direction are required.',
  [CollectionsFallbackValidationErrorKey.InvalidSortingOrderForCustomFallback]:
    'Invalid sorting order for "Custom Fallback". Must be one of: "asc" or "desc".',
};

export const collectionsToasts = {
  [CreateCollectionButtonActionTypes.CreateCollectionError]: commonCollectionErrors,
  [CollectionSettingsActionTypes.SaveError]: commonCollectionErrors,
  [collectionsTranslationsActions.import.fulfilled.type]: 'Translations successfully updated',
};

export const visualEditorToasts = {
  [visualEditorActions.updateRule.type]: 'Pinned product to position',
};

export const emailMarketingCampaignsToastsMap = {
  [emailMarketingCampaignsActions.getCampaigns.rejected.type]: 'Failed to fetch campaigns',
  [emailMarketingCampaignsActions.deleteCampaign.fulfilled.type]:
    'Campaign was deleted successfully',
  [emailMarketingCampaignsActions.deleteCampaign.rejected.type]: 'Failed to delete campaign',
  [campaignModalPageActions.createCampaign.fulfilled.type]: 'New campaign created successfully',
  [campaignModalPageActions.createCampaign.rejected.type]: {
    [ValidationErrorKey.EmailMarketingCampaignNameAlreadyExistsForShop]:
      'Failed to create campaign, duplicate campaign name',
  },
  [campaignModalPageActions.updateCampaign.fulfilled.type]: 'Successfully updated campaign',
  [campaignModalPageActions.updateCampaign.rejected.type]: 'Failed to update campaign',
};

export const actionsToNotificationsMap: TranslationMap = {
  ...appToastsMap,
  ...rankingToastsMap,
  ...merchandisingToasts,
  ...resetPasswordToastsMap,
  ...accountsToasts,
  ...usersToasts,
  ...shopGeneralSettingsToastsMap,
  ...versioningToasts,
  ...dataFieldsToasts,
  ...shopToasts,
  ...experimentsToasts,
  ...featureToggles,
  ...shopTheLookSettingsToasts,
  ...boughtTogetherSettingsToasts,
  ...recentlyViewedSettingsToasts,
  ...personalizationSettingsToasts,
  ...recEngeingsGeneralSettingsToasts,
  ...similarItemsSettingsToasts,
  ...discoveryBannerSettingsToasts,
  ...augSearchFiltersToasts,
  ...augmentedSearchToasts,
  ...lexiconsToasts,
  ...lexiconSettingsToasts,
  ...deepTagReports,
  ...socialDiscoveryToasts,
  ...generalSettingsAIModelToasts,
  ...catalogMangerToastsMap,
  ...storiesActionToasts,
  ...apiKeysToastsMap,
  ...filtersToastsMap,
  ...resultModalGeneralSettingsToasts,
  ...galleriesToastsMap,
  ...collectionsToasts,
  ...visualEditorToasts,
  ...emailMarketingCampaignsToastsMap,
};
