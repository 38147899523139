import React from 'react';
import { IShopCatalog } from 'src/services';
import { CatalogsList } from '../CatalogsList';
import { EmptyStateCard } from '../EmptyStateCard';
import { StyledSkeleton } from './CatalogsListPage.styles';

export interface CatalogsListPageContentProps {
  catalogs: IShopCatalog[] | undefined;
  isUserPermittedForUploadLogs: boolean;
  fetchCatalogsInProgress: boolean;
  navigateToConnectCatalog: () => void;
  navigateToCreateNewCatalog: () => void;
  onSetCatalogAsPrimary: (catalogId: string) => void;
  onCatalogDelete: (catalogId: string) => void;
  navigateToEditCatalog: (catalogId: string) => void;
  navigateToFeedUploadLog: (catalogId: string) => void;
  navigateToViewCatalog: (catalogId: string) => void;
  onCatalogReconnect: (catalogId: string) => void;
  readOnly: boolean;
}

export const CatalogsListPageContent = ({
  catalogs,
  isUserPermittedForUploadLogs,
  fetchCatalogsInProgress,
  navigateToConnectCatalog,
  navigateToCreateNewCatalog,
  onSetCatalogAsPrimary,
  onCatalogDelete,
  navigateToEditCatalog,
  navigateToFeedUploadLog,
  navigateToViewCatalog,
  onCatalogReconnect,
  readOnly,
}: CatalogsListPageContentProps): JSX.Element => {
  if (fetchCatalogsInProgress) {
    return (
      <>
        <StyledSkeleton height={40} variant='rounded' />
        <StyledSkeleton height={20} variant='rounded' />
        <StyledSkeleton height={20} variant='rounded' />
      </>
    );
  }
  return catalogs?.length ? (
    <CatalogsList
      catalogs={catalogs}
      onSetCatalogAsPrimary={onSetCatalogAsPrimary}
      onCatalogDelete={onCatalogDelete}
      navigateToEditCatalog={navigateToEditCatalog}
      navigateToFeedUploadLog={navigateToFeedUploadLog}
      navigateToViewCatalog={navigateToViewCatalog}
      onCatalogReconnect={onCatalogReconnect}
      isUserPermittedForUploadLogs={isUserPermittedForUploadLogs}
      readOnly={readOnly}
    />
  ) : (
    <EmptyStateCard
      navigateToConnectCatalog={navigateToConnectCatalog}
      navigateToCreateNewCatalog={navigateToCreateNewCatalog}
      readOnly={readOnly}
    />
  );
};
