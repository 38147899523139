import React, { useCallback, useEffect, useState } from 'react';
import { CampaignNameSection } from 'src/components-bl/EmailMarketing/CampaignModalPage/components/CampaignSideBar/components/CampaignNameSection';
import { RecommendationTypeSection } from './components/RecommendationTypeSection';
import { TrackingParametersSection } from './components/TrackingParametersSection/TrackingParametersSection';
import { EmailVariablesSection } from './components/EmailVariablesSection/EmailVariablesSection';
import { AccordionSideBar } from 'src/components-dummy/AccordionSideBar';
import { useAccordionExpansion } from 'src/components-dummy/AccordionSideBar/hooks/useAccordionExpansion';
import { useValidateSchema } from 'src/hooks';
import { campaignValidationSchema } from '../../campaign-validation-schema';
import { CampaignDraft } from '../../../types/campaign-draft';
import { Dispatch } from 'src/components-bl';
import { StyledTab, TabWrapper } from './CampaignSideBar.styles';
import { LayoutSection } from './components/LayoutSection';

export enum ExpendableSection {
  CampaignName = 'campaign-name',
  SelectRecommendationType = 'select-recommendation-type',
  EmailVariables = 'email-variables',
  TrackingParameters = 'tracking-parameters',
  EmailDisplaySettings = 'email-display-settings',
  DataToDisplay = 'data-to-display',
  Layout = 'layout',
}

interface CampaignSideBarProps {
  shopId: number;
  dispatch: Dispatch;
  navigateToCampaigns: () => void;
  onFormValidationChange: (isValid: boolean) => void;
  campaignDraft: CampaignDraft;
  setCampaignDraft: React.Dispatch<React.SetStateAction<CampaignDraft>>;
  isLoading: boolean;
}

export const CampaignSideBar = ({
  shopId,
  dispatch,
  navigateToCampaigns,
  onFormValidationChange,
  campaignDraft,
  setCampaignDraft,
  isLoading,
}: CampaignSideBarProps): JSX.Element => {
  const { expandedSection, handleSectionToggle } = useAccordionExpansion(null);
  const isEditEnabled = !isLoading;
  const [tabs, setTab] = useState<'Setup' | 'Design'>('Setup');

  const { errors, validate, isValid } = useValidateSchema<CampaignDraft>({
    schema: campaignValidationSchema,
    validateOnStart: false,
    initialData: {
      campaignName: campaignDraft.campaignName,
      strategy: campaignDraft.strategy,
      widgetMaker: campaignDraft.widgetMaker,
    },
  });

  useEffect(() => {
    const validationData = {
      dataToValidate: {
        campaignName: campaignDraft.campaignName || '',
        strategy: campaignDraft.strategy,
        collectionId: campaignDraft.collectionId || undefined,
        widgetMaker: campaignDraft.widgetMaker || undefined,
      },
    };

    validate(validationData);
    onFormValidationChange(isValid);
  }, [campaignDraft, validate, onFormValidationChange, isValid]);

  const onChange = useCallback(
    (partialCampaign: Partial<CampaignDraft>) => {
      setCampaignDraft(prevCampaign => ({ ...prevCampaign, ...partialCampaign }));
    },
    [setCampaignDraft]
  );

  const setupSections = [
    {
      id: ExpendableSection.CampaignName,
      title: 'Campaign Name',
      content: (
        <CampaignNameSection
          value={campaignDraft.campaignName || ''}
          onChange={onChange}
          errors={errors}
        />
      ),
      isExpanded: expandedSection === ExpendableSection.CampaignName,
    },
    {
      id: ExpendableSection.SelectRecommendationType,
      title: 'Recommendation Type ',
      content: (
        <RecommendationTypeSection
          shopId={shopId}
          dispatch={dispatch}
          campaignDraft={{
            strategy: campaignDraft.strategy,
            collectionId: campaignDraft.collectionId,
          }}
          onChange={onChange}
          errors={errors}
        />
      ),
      isExpanded: expandedSection === ExpendableSection.SelectRecommendationType,
    },
    {
      id: ExpendableSection.EmailVariables,
      title: 'Email Variables',
      content: (
        <EmailVariablesSection widgetMaker={campaignDraft.widgetMaker} onChange={onChange} />
      ),
      isExpanded: expandedSection === ExpendableSection.EmailVariables,
    },
    {
      id: ExpendableSection.TrackingParameters,
      title: 'Tracking Parameters',
      content: (
        <TrackingParametersSection
          widgetMaker={campaignDraft.widgetMaker}
          onChange={onChange}
          errors={errors}
        />
      ),
      isExpanded: expandedSection === ExpendableSection.TrackingParameters,
    },
  ];

  const designSections = [
    {
      id: ExpendableSection.Layout,
      title: 'Choose Layout',
      content: <LayoutSection onChange={onChange} widgetMaker={campaignDraft.widgetMaker} />,
      isExpanded: expandedSection === ExpendableSection.Layout,
    },
    {
      id: ExpendableSection.EmailDisplaySettings,
      title: 'Email Setup',
      isExpanded: false,
      isDisabled: true,
    },
    {
      id: ExpendableSection.DataToDisplay,
      title: 'Data to Display',
      isExpanded: false,
      isDisabled: true,
    },
  ];

  const filteredSections = tabs === 'Setup' ? setupSections : designSections;

  return (
    <AccordionSideBar
      header='Create Campaign'
      isLoading={isLoading}
      navigateToList={navigateToCampaigns}
      setExpendedSection={handleSectionToggle}
      disabled={!isEditEnabled}
      sections={filteredSections}
    >
      <TabWrapper>
        <StyledTab label='Setup' onClick={() => setTab('Setup')} isActive={tabs === 'Setup'} />
        <StyledTab label='Design' onClick={() => setTab('Design')} isActive={tabs === 'Design'} />
      </TabWrapper>
    </AccordionSideBar>
  );
};
