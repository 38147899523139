import React, { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'src/hooks';
import { useGetCollectionsList } from 'src/components-bl/Collections/CollectionsList/useGetCollectionsList';
import { AvailableIcons, Icon, MenuItem, Select, SelectType } from 'src/components-dummy';
import { recommendationTypeToDisplayName } from '../../../../../constants/recommendation-type-to-display-name';
import { CampaignStrategy } from '../../../../../types/campaign-strategy';
import { Campaign } from '../../../../../types/campaign';
import { ParseJoiValidateResponse } from 'src/utils';
import { Collection, Dispatch } from 'src/components-bl';
import { CampaignDraft } from '../../../../../types/campaign-draft';
import { CollectionLink, CollectionsSelectContainer } from './RecommendationTypeSection.styles';
import { Section } from '../../CampaignSideBar.styles';

interface RecommendationTypeSectionProps {
  shopId: number;
  dispatch: Dispatch;
  campaignDraft: Partial<Pick<Campaign, 'strategy' | 'collectionId'>>;
  onChange: (campaign: CampaignDraft) => void;
  errors: ParseJoiValidateResponse<Pick<Campaign, 'strategy'>>;
}

function NavigateToCollection({ onClick }: { onClick: VoidFunction }) {
  return (
    <CollectionLink onClick={onClick}>
      <span>Edit Collection</span>
      <Icon name={AvailableIcons.ExternalLink} />
    </CollectionLink>
  );
}

export const RecommendationTypeSection = ({
  shopId,
  dispatch,
  campaignDraft,
  onChange,
  errors,
}: RecommendationTypeSectionProps): JSX.Element => {
  const { collections } = useAppSelector(state => state.collections);
  const [collection, setCollection] = useState<Collection | null>(null);

  useGetCollectionsList({ shopId, dispatch });

  useEffect(() => {
    if (campaignDraft.collectionId) {
      const selectedCollection = collections.find(
        coll => coll.dataCollectionId === campaignDraft.collectionId
      );
      setCollection(selectedCollection);
    } else {
      setCollection(undefined);
    }
  }, [campaignDraft.collectionId, collections]);

  const handleStrategyChange = useCallback(
    (newStrategy: CampaignStrategy) => {
      onChange({ strategy: newStrategy });
      if (newStrategy !== CampaignStrategy.Collections) {
        onChange({ collectionId: null });
        setCollection(undefined);
      }
    },
    [onChange]
  );

  const handleCollectionSelectChange = useCallback(
    e => {
      const newSelectedCollectionName = e.target.value;
      setCollection(newSelectedCollectionName);

      const selectedCollection = collections.find(coll => coll.name === newSelectedCollectionName);

      if (selectedCollection) {
        setCollection(selectedCollection);
        onChange({ collectionId: selectedCollection.dataCollectionId });
      }
    },
    [collections, onChange]
  );

  const navigateToCollection = useCallback(() => {
    if (!collection) return;
    const collectionLink = `/shops/${shopId}/collections/edit/${collection.id}/collection-rules`;
    window.open(collectionLink, '_blank');
  }, [shopId, collection]);

  return (
    <Section>
      <Select
        type={SelectType.Primary}
        value={campaignDraft.strategy}
        onChange={e => handleStrategyChange(e.target.value as CampaignStrategy)}
        errorMessage={errors?.strategy?.message}
        label='Select Recommendation Type'
      >
        {Object.values(CampaignStrategy).map(key => (
          <MenuItem key={key} value={key}>
            {recommendationTypeToDisplayName[key]}
          </MenuItem>
        ))}
      </Select>

      {campaignDraft.strategy === CampaignStrategy.Collections && (
        <CollectionsSelectContainer>
          <Select
            type={SelectType.Primary}
            value={collection?.name}
            onChange={handleCollectionSelectChange}
            placeholder={collections.length ? 'Select Collection' : 'No active collection'}
            disabled={!collections.length}
            label='Select Collection'
          >
            {collections.map(item => (
              <MenuItem key={item.id} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          {!!collection && <NavigateToCollection onClick={navigateToCollection} />}
        </CollectionsSelectContainer>
      )}
    </Section>
  );
};
