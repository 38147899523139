import React, { ReactNode } from 'react';
import { LinkProps as MuiLinkProps } from '@mui/material/Link';
import { LinkButtonStyled } from './LinkButton.styles';

export interface LinkButtonProps extends Pick<MuiLinkProps, 'type' | 'onClick'> {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
}

/** Renders a button that looks like a link. */
export const LinkButton = ({
  className,
  onClick,
  children,
  type,
  disabled = false,
}: LinkButtonProps): JSX.Element => {
  return (
    <LinkButtonStyled
      onClick={onClick}
      component='button'
      className={className}
      type={type || 'button'}
      disabled={disabled}
    >
      {children}
    </LinkButtonStyled>
  );
};
