import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RoutedComponentProps } from 'src/app-routes';
import { Dispatch } from 'src/components-dummy/types';
import { Campaign } from '../types/campaign';
import { CampaignDraft } from '../types/campaign-draft';
import {
  CreateCampaignPageStyled,
  SideBarLayout,
  PreviewLayout,
  OverlayModalStyled,
  CampaignPreview,
} from './CampaignModalPage.styles';
import { defaultWidgetMakerConfig } from '../constants/default-widget-maker-config';
import { isEqual } from 'lodash';
import { campaignModalPageActions } from './Actions';
import { CampaignSideBar } from './components/CampaignSideBar/CampaignSideBar';
import { RuleFormFooter } from 'src/components-bl/MerchandisingRules/components/MerchandisingRuleForm/RuleFormFooter';
import { Button } from 'src/components-dummy';

interface CampaignModalPageProps extends RoutedComponentProps {
  shopId: number;
  dispatch: Dispatch;
  navigateToCampaigns?: () => void;
  currentCampaign?: Campaign;
  hasDraft: boolean;
  isCreateMode?: boolean;
}

export const CampaignModalPage = ({
  shopId,
  dispatch,
  navigateToCampaigns,
  currentCampaign,
  hasDraft,
  isCreateMode,
}: CampaignModalPageProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateValid, setIsCreateValid] = useState(false);
  const isDuplicateMode = isCreateMode && currentCampaign !== undefined;

  const getInitialDraft = (): CampaignDraft => {
    if (currentCampaign) {
      return { ...currentCampaign };
    }

    return {
      campaignName: '',
      strategy: undefined,
      collectionId: undefined,
      widgetMaker: { ...defaultWidgetMakerConfig },
    };
  };

  const initialCampaignDraft = useMemo(() => getInitialDraft(), [currentCampaign, isCreateMode]);
  const [campaignDraft, setCampaignDraft] = useState<CampaignDraft>(initialCampaignDraft);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  useEffect(() => {
    setCampaignDraft(initialCampaignDraft);
  }, [initialCampaignDraft]);

  useEffect(() => {
    setIsDirty(!isEqual(initialCampaignDraft, campaignDraft) || isDuplicateMode);
  }, [campaignDraft, initialCampaignDraft, isDuplicateMode]);

  const handleFormValidationChange = (isValid: boolean) => {
    setIsCreateValid(isValid);
  };

  const onSave = useCallback(async () => {
    try {
      setIsLoading(true);

      const campaignPayload = {
        shopId,
        campaignName: campaignDraft.campaignName,
        strategy: campaignDraft.strategy,
        collectionId: campaignDraft.collectionId,
        widgetMaker: {
          ...defaultWidgetMakerConfig,
          ...campaignDraft.widgetMaker,
        },
      };

      const action = isCreateMode
        ? campaignModalPageActions.createCampaign(campaignPayload)
        : campaignModalPageActions.updateCampaign({
            shopId,
            campaignId: currentCampaign.id,
            payload: campaignPayload,
          });

      await (dispatch(action) as any).unwrap();
      navigateToCampaigns();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [shopId, campaignDraft, dispatch, isCreateMode, currentCampaign]);

  const onCancel = () => {
    setCampaignDraft(initialCampaignDraft);
    navigateToCampaigns();
  };

  const canSave = !isCreateValid || !isDirty;

  return shopId ? (
    <OverlayModalStyled show fullscreen={!hasDraft}>
      <CreateCampaignPageStyled>
        <SideBarLayout>
          <CampaignSideBar
            shopId={shopId}
            dispatch={dispatch}
            navigateToCampaigns={navigateToCampaigns}
            onFormValidationChange={handleFormValidationChange}
            campaignDraft={campaignDraft}
            setCampaignDraft={setCampaignDraft}
            isLoading={isLoading}
          />
        </SideBarLayout>
        <PreviewLayout>
          <CampaignPreview />
        </PreviewLayout>
        <RuleFormFooter>
          <Button variant='tertiary' onClick={onCancel}>
            Cancel
          </Button>
          <Button variant='primary' disabled={canSave} onClick={onSave}>
            Save & Generate Snippet
          </Button>
        </RuleFormFooter>
      </CreateCampaignPageStyled>
    </OverlayModalStyled>
  ) : null;
};
