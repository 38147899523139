import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { RoutedComponentProps } from 'src/app-routes';
import { Dispatch } from 'src/components-bl';
import { campaignModalPageActions } from 'src/components-bl/EmailMarketing/CampaignModalPage/Actions';
import { CampaignModalPage } from 'src/components-bl/EmailMarketing/CampaignModalPage/CampaignModalPage';
import { useHasDraft } from 'src/components-bl/hooks';
import { useAppSelector } from 'src/hooks';

interface EditCampaignContainerProps extends RoutedComponentProps {
  shopId: number;
  dispatch: Dispatch;
  navigateToCampaigns: () => void;
}

export const EditCampaignContainer = ({
  shopId,
  permittedRouteMap,
  dispatch,
  navigateToCampaigns,
}: EditCampaignContainerProps): JSX.Element => {
  const { campaignId } = useParams<{ campaignId: string }>();
  const currentCampaign = useAppSelector(state => state.campaigns.currentCampaign);
  const hasDraft = useHasDraft();

  useEffect(() => {
    if (campaignId) {
      dispatch(campaignModalPageActions.getCampaign({ shopId, campaignId }));
    }
  }, [dispatch, shopId, campaignId]);

  return shopId && currentCampaign ? (
    <CampaignModalPage
      dispatch={dispatch}
      shopId={shopId}
      permittedRouteMap={permittedRouteMap}
      currentCampaign={currentCampaign}
      navigateToCampaigns={navigateToCampaigns}
      hasDraft={hasDraft}
    />
  ) : null;
};
