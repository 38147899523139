import Joi, { AnySchema } from 'joi';
import { ShoppableBanner } from 'src/services';

const MIN_CHARACTERS = 1;
const MAX_CHARACTERS = 150;

export const bannerValidationSchema: Record<
  keyof Pick<ShoppableBanner, 'bannerLink' | 'bannerTextLink'>,
  AnySchema
> = {
  bannerLink: Joi.string().uri().allow('').optional().messages({
    'string.uri': 'Invalid URL',
  }),
  bannerTextLink: Joi.string()
    .allow('')
    .optional()
    .min(MIN_CHARACTERS)
    .max(MAX_CHARACTERS)
    .messages({
      'string.min': `Banner Link Text should be more than ${MIN_CHARACTERS} characters`,
      'string.max': `Banner Link Text should be less than or equal to ${MAX_CHARACTERS} characters`,
    }),
};
