import React, { useCallback, useMemo } from 'react';
import { TextBox } from '../../../../../../../components-dummy';
import { defaultEmailVariables } from './types';
import { CampaignDraft } from '../../../../../types/campaign-draft';
import { WidgetMaker } from '../../../../../types/widget-maker';
import { Section } from '../../CampaignSideBar.styles';

interface EmailVariablesSectionProps {
  widgetMaker: Partial<WidgetMaker>;
  onChange: (campaign: CampaignDraft) => void;
}

export const EmailVariablesSection = ({ widgetMaker, onChange }: EmailVariablesSectionProps) => {
  const { variables } = widgetMaker;
  const emailVariables = useMemo(
    () => (variables && Object.keys(variables).length > 0 ? variables : defaultEmailVariables),
    [variables]
  );

  const onVariablesChange = useCallback(
    (key, value) => {
      onChange({
        widgetMaker: {
          ...widgetMaker,
          variables: { ...emailVariables, [key]: value },
        },
      });
    },
    [onChange, emailVariables]
  );

  return (
    <Section>
      {Object.entries(emailVariables).map(([key, value]) => {
        return (
          <TextBox
            key={key}
            label={`${key} Variable`}
            placeholder='{{productId.key}}'
            value={value}
            onChange={newValue => onVariablesChange(key, newValue)}
          />
        );
      })}
    </Section>
  );
};
